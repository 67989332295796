export const AppEnvConfigAction = {
    SetAppEnvConfigData: "appenvconfig:set-app-env-config-data",
}

export const setAppConfigData = payload => {
    return {
        type: AppEnvConfigAction.SetAppEnvConfigData,
        payload,
    }
}
