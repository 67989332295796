import React from "react"
import Loadable from "react-loadable"

export const Checkout = Loadable({
    loader: () => import( /* webpackChunkName: "Checkout" */ "./Checkout"),
    loading: () => <div />,
})

export const OrderConfirmation = Loadable({
    loader: () => import( /* webpackChunkName: "Checkout" */ "./OrderConfirmation"),
    loading: () => <div />,
})

export const HandoverPicker = Loadable({
    loader: () => import( /* webpackChunkName: "Checkout" */ "./HandoverPicker"),
    loading: () => <div />,
})
