import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideDietEnergyPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import ScrollView from "@modules/shared/ScrollView"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { InsightPopupHeader } from "../../common/loaders"
import ChatBubble from "@ng-mw/shared-meny-components/src/modules/insight/ChatBubble"
import { DietEnergyInfo } from "../loaders"

const DietEnergyPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.dietEnergyPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideDietEnergyPopup())}
            isOpen={isOpen}
        >
            <ScrollView transparent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <InsightPopupHeader />
                    <PopupContent>
                        <DietEnergyInfo
                            CardComponent={ChatBubble}
                        />
                    </PopupContent>
                </LazyRender>
            </ScrollView>
        </Popup>
    )
}
export default DietEnergyPopup
