import { DebugLogger } from "@ng-mw/shared-react-components"
import { SearchAction } from "@ng-mw/shared-react-components/search"
import { AppAction } from "@app/store/app/appCreator"
import * as tracking from "@app/tracking"
import { ProductViewAction } from "@ng-mw/shared-react-components/product-view"

const resolveProductViews = (id, state) => {
    if (!state[id]) {
        return
    }

    switch (id) {
        case "popular":
            return tracking.productsView(state[id].data, state[id].filter, "mest solgte")
        case "bargains":
            return tracking.productsView(state[id].data, state[id].filter, "knallkjøp")
        case "genericOffers":
            return tracking.productsView(state[id].data, state[id].filter, "knallkjøp")
    }

    if (id.includes("campaign-deets-")) {
        tracking.productsView(state[id].data, state[id].filter, "kampanje")
    }
}

export default store => {
    return next => {
        return action => {
            const result = next(action)
            const state = store.getState()

            const userIsOrganization = state.user.data.household.isOrganization
            const loginLevel = state.user.loginLevel

            const userStatus = loginLevel > 0 ? "in" : "out"
            let customerType = userIsOrganization ? "corporate" : "private"

            if (userStatus === "out") {
                customerType = ""
            }

            setTimeout(() => {
                try {

                    switch (action.type) {
                        // filtering
                        case ProductViewAction.SetFilterMultiple:
                        case ProductViewAction.SetFilter:
                            break
                        case SearchAction.SetFilter:
                        case SearchAction.SetFilterMultiple:
                        case SearchAction.RemoveFilterMultiple:
                        case SearchAction.SetSorting:
                            break

                        // manual trigger for search view route
                        case SearchAction.SetQuery:
                            if (state.search.meta.query) {
                                DebugLogger.log("making VirtualPageview: tracking-middleware: default case:search.query")
                                tracking.search(state.search.meta.query, userStatus, customerType)
                            }
                            break

                        //products views
                        case ProductViewAction.SetData:
                            resolveProductViews(action.payload.id, state.productView)
                            break
                        case SearchAction.FullSuccess:
                            resolveProductViews(state.search.data.full, state.search.meta.filter)
                            break

                        //product details
                        case AppAction.ShowProductPopup:
                            tracking.productView(state.product.data)
                            break
                    }
                } catch (e) {
                    DebugLogger.log("ERROR IN TRACKING - tracking aborted this tick", e)
                }
            })

            return result
        }
    }
}
