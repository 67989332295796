import { GenericAction } from "@app/types"
import { SavingsTipsAction } from "../actions/creators/savingsTipsCreator"
import { SavingsTips } from "@app/types/root-state/savingsTips"

const init: SavingsTips = {
    loading: false,
    error: null,
    data: null,
    loaded: false,
}

export default function (state = init, action: GenericAction) {
    switch (action.type) {
        // Get all
        case SavingsTipsAction.Loading:
            return { ...state, loading: true }
        case SavingsTipsAction.Loaded:
            return { ...state, loading: false, loaded: true }
        case SavingsTipsAction.Success:
            return { ...state, data: action.payload, error: null }
        case SavingsTipsAction.Error:
            return { ...state, error: action.payload }
        // Update store
        case SavingsTipsAction.FromCache: {
            return { ...state, data: action.payload, error: null }
        }
        default:
            return state
    }
}
