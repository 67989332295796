import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, matchPath } from "react-router-dom"
import { hideInsightAboutPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import PopupContent from "@modules/popup/PopupContent"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import PathNames from "@app/const/PathNames"
import { InsightAbout } from "../loaders"

const InsightAboutPopup = () => {
    const location = useLocation()
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.insightAboutPopup.visible)

    const isClimate = !!matchPath(location.pathname, {
        path: PathNames.ClimateHome,
        exact: false,
    })

    return (
        <Popup
            onClose={() => dispatch(hideInsightAboutPopup())}
            isOpen={isOpen}
            title="Om innsiktstjenesten"
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <InsightAbout isClimate={isClimate} />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default InsightAboutPopup
