import { core } from "@ng-mw/shared-react-components/framework"

const decodeJwt = (token: string) => {
    const cleanToken = token.replace(/^Bearer |^Simplified /, "")
    try {
        return core.Utils.parseJwt(cleanToken)
    } catch (error: any) {
        return { error: error?.message, token }
    }
}

export default decodeJwt
