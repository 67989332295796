import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { hideRegistrationPopup as hideRegistrationPopupAction } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { StretchBox } from "@modules/shared/Grid"
import Popup from "@modules/popup/Popup"
import { Registration, RegistrationCorporate } from "../loaders"

const scrollContainerId = "ngc-simplified-registration-container"

const RegistrationPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.registrationPopup.visible)
    const data = useSelector((state: RootState) => state.app.registrationPopup.data)

    const isCorporateRegistration = data?.registrationType === "corporate"

    const handleClose = () => dispatch(hideRegistrationPopupAction())

    return (
        <Popup
            isForcedToFront
            title={isCorporateRegistration ? "Bli bedriftskunde" : "Bli medlem"}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <StretchBox id={scrollContainerId}>
                <LazyRender
                    isVisible={isOpen && !isCorporateRegistration}
                    hiddenClassName=""
                >
                    <Registration
                        scrollContainerId={scrollContainerId}
                        phoneNumber={data?.phoneNumber}
                    />
                </LazyRender>
                <LazyRender
                    isVisible={isOpen && isCorporateRegistration}
                    hiddenClassName=""
                >
                    <RegistrationCorporate
                        scrollContainerId={scrollContainerId}
                        phoneNumber={data?.phoneNumber}
                    />
                </LazyRender>
            </StretchBox>
        </Popup>
    )
}

export default RegistrationPopup
