import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideInsightKeyFiguresPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { InsightKeyFiguresInfo } from "../../diet/loaders"

const InsightKeyFiguresPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible: isOpen, data } = useSelector((state: RootState) => state.app.insightKeyFiguresPopup)

    return (
        <Popup
            onClose={() => dispatch(hideInsightKeyFiguresPopup())}
            isOpen={isOpen}
            title={data?.title || "Nøkkeltall og beregninger"}
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
            >
                <PopupContent>
                    <InsightKeyFiguresInfo
                        {...data}
                    />
                </PopupContent>
            </LazyRender>
        </Popup>
    )
}

export default InsightKeyFiguresPopup
