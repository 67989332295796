import "./style/welcome-box.scss"

import React, { useEffect, useState } from "react"
import { LocalStorage } from "@ng-mw/shared-react-components"
import MenyLocalStorage from "@app/MenyLocalStorage"

import menyMerCoupons from "@resources/icons/menyMerCoupons"
import closeIcon from "@ng-mw/reol/icons/close"

import Tips from "@modules/shared/Tips"
import Icon from "@ng-mw/reol/components/Icon"
import PathNames from "@app/const/PathNames"
import AppLink from "@modules/shared/AppLink"
import Container from "@modules/shared/Container"
import { useLoyaltyClubStatus } from "./helpers"

const WelcomeBox = () => {
    const [hasSeenWelcomeBox, setHasSeenWelcomeBox] = useState(() => LocalStorage.get(MenyLocalStorage.UserSeenLoyaltyClubWelcomeBox))
    const { isLoyaltyClubMember } = useLoyaltyClubStatus()

    useEffect(() => {
        return () => {
            LocalStorage.set(MenyLocalStorage.UserSeenLoyaltyClubWelcomeBox, true)
        }
    }, [])

    const handleOnClose = () => {
        setHasSeenWelcomeBox(true)
        LocalStorage.set(MenyLocalStorage.UserSeenLoyaltyClubWelcomeBox, true)
    }

    if (hasSeenWelcomeBox || !isLoyaltyClubMember) {
        return null
    }

    return (
        <Container>
            <Tips
                size={{ width: "104px", height: "104px" }}
                text="Velkommen som MENY MER-kunde i appen!"
                illustration={menyMerCoupons}
                illustrationLeftOffset="-35px"
                className="welcome-box"
                padding="none"
            >
                <AppLink
                    className="welcome-box__link"
                    to={PathNames.Vouchers}
                    skin="link"
                >
                    Se dine fordeler
                </AppLink>
                <button
                    className="welcome-box__close-button"
                    type="button"
                    onClick={handleOnClose}
                >
                    <Icon
                        icon={closeIcon}
                        size={{ width: "12px", height: "12px" }}
                    />
                    <span className="ws-visually-hidden">
                        Lukk
                    </span>
                </button>
            </Tips>
        </Container>
    )
}

export default WelcomeBox
