import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MaintenanceMessageState } from "@app/store/settings/types"
import { actions } from "@app/store/settings/settingsActions"

import MaintenanceMessage from "@modules/shared/MaintenanceMessage"

const GlobalMaintenanceMessage = () => {
    const dispatch = useDispatch()
    const maintenanceMessage = useSelector<any, MaintenanceMessageState>(state => state.settings.maintenanceMessage)

    const handleHideMaintenanceMessage = useCallback(() => {
        dispatch(actions.hideMaintenanceMessage())
    }, [dispatch])

    return (
        <MaintenanceMessage
            title={maintenanceMessage.operationsTitle}
            body={maintenanceMessage.operationsBody}
            operationsIsActive={maintenanceMessage.operationsIsActive}
            isUserHidden={maintenanceMessage.userHidden}
            onHide={handleHideMaintenanceMessage}
        />
    )
}

export default GlobalMaintenanceMessage
