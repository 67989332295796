import "./style/app-navigation.scss"
import "./style/app-navigation-item.scss"

import { productViewActions } from "@ng-mw/shared-react-components/product-view"
import { searchActions } from "@ng-mw/shared-react-components/search"
import classNames from "classnames"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useHistory } from "react-router"
import PathNames from "@app/const/PathNames"
import { getDateParts, useSafeLayoutSettings } from "@app/helpers"
import { IconResource, IconSize, RootState } from "@app/types"

// Icons
import navCartIcon from "@resources/icons/navCart"
import navCartFilledIcon from "@resources/icons/navCartFilled"
import navCartQuantityIcon from "@resources/icons/navCartQuantity"
import navCartQuantityFilledIcon from "@resources/icons/navCartQuantityFilled"
import navHomeIcon from "@resources/icons/navHome"
import navHomeFilledIcon from "@resources/icons/navHomeFilled"
import navSavingsIcon from "@resources/icons/navSavings"
import navSavingsFilledIcon from "@resources/icons/navSavingsFilled"
import plusIcon from "@ng-mw/reol/icons/plus"
import searchIcon from "@ng-mw/reol/icons/search"
import navSearchFilledIcon from "@resources/icons/navSearchFilled"
import navHeartIcon from "@resources/icons/navHeart"
import navHeartFilledIcon from "@resources/icons/navHeartFilled"
import navRecipesIcon from "@resources/icons/navRecipes"
import navRecipesFilledIcon from "@resources/icons/navRecipesFilled"

// Components
import Icon from "@ng-mw/reol/components/Icon"
import AppLink from "@modules/shared/AppLink"
import AppNetworkStatus from "./AppNetworkStatus"
import AppVersionStatus from "@modules/framework/AppVersionStatus"
import isDebugMode from "@app/helpers/isDebugMode"

interface AppNavigationItemProps {
    to: string
    icon: IconResource
    text: string
    badge?: string | number
    isActive?: boolean
    iconSize?: IconSize
    onClick?: () => void
    onTouchStart?: () => void
    onTouchEnd?: (e: any) => void
}

const AppNavigationItem = ({
    to,
    icon,
    iconSize = { height: "26px", width: "26px" },
    text,
    isActive: active,
    badge,
    onClick,
    onTouchStart,
    onTouchEnd,
}: AppNavigationItemProps) => {
    const { safeFooterPaddingBottom } = useSafeLayoutSettings()

    return (
        <li
            className={classNames("app-navigation-item", {
                "app-navigation-item--active": active,
            })}
        >
            <AppLink
                className="app-navigation-item__link"
                instant
                to={to}
                onClick={onClick}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                <div className="app-navigation-item__icon-wrapper">
                    <Icon
                        icon={icon}
                        className={classNames("app-navigation-item__icon", {
                            "app-navigation-item__icon--active": active,
                        })}
                        size={iconSize}
                    />
                    {badge ? (
                        <span className="app-navigation-item__badge">
                            {badge}
                        </span>
                    ) : null}
                </div>
                <span
                    className="app-navigation-item__text"
                    style={{ paddingBottom: `${safeFooterPaddingBottom}px` }}
                >
                    {text}
                </span>
            </AppLink>
        </li>
    )
}

const AppNavigation = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { safeFooterHeight } = useSafeLayoutSettings()
    const history = useHistory()
    const productView = useSelector((state: RootState) => state.productView)
    const userIsOrganization = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const totalCartQuantity = useSelector((state: RootState) => state.cart.totals.totalQuantity)
    const path = location.pathname
    const time = useRef(0)
    const { month } = getDateParts(new Date())
    const isDebugModeActive = isDebugMode()
    const isGenericOffersActive = path.startsWith("/savings") && !path.includes("loyalty-club")
    const isPride = (month > 5 && month < 8) || isDebugModeActive && month === 5
    const footerHeight = safeFooterHeight + (isPride ? 8 : 0)

    const handleTouchStart = () => {
        time.current = new Date().getTime()
    }

    const handleTouchEnd = (e: React.TouchEvent) => {
        const timeDiff = new Date().getTime() - time.current
        if (timeDiff > 4500) {
            history.push("/debug")
            e.preventDefault()
        }
    }

    const resetProductsSearch = () => {
        dispatch(searchActions.clearFilter())
        dispatch(searchActions.setQuery(null))

        if (productView.popular) {
            dispatch(productViewActions.clearFilter("popular"))
        }
    }

    const resetGenericOffers = () => {
        if (productView.genericOffers) {
            dispatch(productViewActions.clearFilter("genericOffers"))
        }
    }

    const getCartIcon = () => {
        if (path === PathNames.Cart) {
            return totalCartQuantity ? navCartQuantityFilledIcon : navCartFilledIcon
        } else {
            return totalCartQuantity ? navCartQuantityIcon : navCartIcon
        }
    }

    return (
        <>
            <nav
                className={classNames("app-navigation", {
                    "app-navigation__pride-banner": isPride,
                })}
                role="navigation"
                style={{ height: `${footerHeight}px` }}
            >
                <ul className="app-navigation__menu">
                    <AppNavigationItem
                        to={PathNames.Home}
                        icon={path === PathNames.Home ? navHomeFilledIcon : navHomeIcon}
                        text="Hjem"
                        isActive={path === PathNames.Home}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    />
                    <AppNavigationItem
                        to={PathNames.Search}
                        icon={path === PathNames.Search || path.includes(PathNames.Products) ? navSearchFilledIcon : searchIcon}
                        text="Varer"
                        isActive={path === PathNames.Search || path.includes(PathNames.Products)}
                        onClick={resetProductsSearch}
                    />
                    <AppNavigationItem
                        to={PathNames.Cart}
                        icon={getCartIcon()}
                        text="Handlevogn"
                        isActive={path === PathNames.Cart}
                        badge={totalCartQuantity}
                    />
                    <AppNavigationItem
                        to={PathNames.GenericOffers}
                        icon={isGenericOffersActive ? navSavingsFilledIcon : navSavingsIcon}
                        text="Tilbud"
                        isActive={isGenericOffersActive}
                        onClick={resetGenericOffers}

                    />
                    {userIsOrganization ? (
                        <AppNavigationItem
                            to={PathNames.RecipesHome}
                            icon={path.includes("/recipes") ? navRecipesFilledIcon : navRecipesIcon}
                            text="Oppskrifter"
                            isActive={path.includes("/recipes")}
                        />
                    ) : (
                        <AppNavigationItem
                            to={PathNames.LoyaltyClub}
                            icon={path.includes("loyalty-club") ? navHeartFilledIcon : navHeartIcon}
                            text="Fordeler"
                            isActive={path.includes("loyalty-club")}
                        />
                    )}
                </ul>
                <AppVersionStatus />
                <AppNetworkStatus />
            </nav>
            {isDebugModeActive ? (
                <AppLink
                    className="app-navigation__debug-link"
                    instant
                    to={PathNames.Debug}
                >
                    <Icon icon={plusIcon} />
                    <span className="ws-visually-hidden">
                        Debug
                    </span>
                </AppLink>
            ) : null}
        </>
    )
}

export default AppNavigation
