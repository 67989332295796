import { NativeEvent } from "@ng-mw/shared-react-components"

/**
 * Extended native events.
 */
export default {
    ...NativeEvent,
    ForceLogOut: "native-wrapper:force-log-out",
    ForwardTrackingData: "native-wrapper:tracking-data",
    OpenAddBiometri: "native-wrapper:open-add-biometri",
    OpenEmail: "native-wrapper:open-mail-app",
    OpenRegistration: "native-wrapper:open-registration", // payload: { phoneNumber: "+4798765432", registrationType: "trumf" }
    ReadyForTrackingData: "native-wrapper:ready-for-tracking-data",
    RequestAppRating: "native-wrapper:request-app-rating",
    ScanBarcode: "native-wrapper:scan-barcode",
    ScanBarcodeDone: "native-wrapper:scan-barcode-done",
    ScanBarcodeSuccess: "native-wrapper:scan-barcode-success",
    ActivityStatusChange: "native-wrapper:activity-state-change",
    OpenTrumfCard: "native-wrapper:open-dtk",
}
