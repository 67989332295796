import React from "react"

import AppLayoutTop from "@modules/framework/AppLayoutTop"
import AppLayoutHeader from "@modules/framework/AppLayoutHeader"
import AppLayoutMain from "@modules/framework/AppLayoutMain"
import AppLayoutFooter from "@modules/framework/AppLayoutFooter"
import AppNavigation from "@modules/framework/AppNavigation"
import ScrollToTopLink from "@modules/framework/ScrollToTopLink"
import AppHeader from "@modules/framework/AppHeader"
import Home from "../Home"

const HomePage = () => {
    return (
        <>
            <AppLayoutTop>
                <AppLayoutHeader>
                    <ScrollToTopLink>
                        <AppHeader
                            title="Hjem"
                            isBackButtonHidden
                            isTrumfCardVisible
                        />
                    </ScrollToTopLink>
                </AppLayoutHeader>
                <AppLayoutMain>
                    <Home />
                </AppLayoutMain>
            </AppLayoutTop>
            <AppLayoutFooter>
                <AppNavigation />
            </AppLayoutFooter>
        </>
    )
}

export default HomePage
