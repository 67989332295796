import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import { getLoyaltyClubAgreementItem, useLoyaltyClubStatus } from "./helpers"
import { consentsActions } from "@ng-mw/shared-react-components/consents"

import Button from "@ng-mw/reol/components/Button"

interface ActivateLoyaltyClubButtonProps {
    text?: string
    onActivate?: () => any
}

const ActivateLoyaltyClubButton = ({
    text = "Aktiver MENY MER",
    onActivate,
}: ActivateLoyaltyClubButtonProps) => {
    const dispatch = useDispatch<any>()
    const { data, loading } = useSelector((state: RootState) => state.consents)
    const loyaltyClubAgreement = getLoyaltyClubAgreementItem(data)
    const { isLoyaltyClubMember } = useLoyaltyClubStatus()
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)

    const handleChangeAgreement = () => {
        if (loyaltyClubAgreement) {
            dispatch(consentsActions.updateAgreementStatus(loyaltyClubAgreement, "true"))
        }

        if (onActivate) {
            onActivate()
        }
    }

    return (
        <Button
            onClick={handleChangeAgreement}
            text={text}
            isDisabled={isLoyaltyClubMember || loyaltyClubAgreement?.updating || userIsCorporate}
            isLoading={loyaltyClubAgreement?.updating || loading}
        />
    )
}

export default ActivateLoyaltyClubButton
