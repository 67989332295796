import { DebugLogger } from "@ng-mw/shared-react-components"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { Workbox } from "workbox-window"
import { StaleWhileRevalidateStateServiceWorkerConfig } from "./stateServiceWorkerConfig"
import { FallbackHtmlUrl, HtmlCache, StaleWhileRevalidateApiCache } from "./constants"
import { Store } from "redux"
import { RootState } from "@app/types"
import { deleteOldServiceWorkerCache } from "@app/helpers"

const registerServiceWorker = async (store: Store<RootState, any>): Promise<void> => {

    if (!("serviceWorker" in navigator)) return

    if (AppGlobalSettings.serviceWorkerUrl) {
        const registration = await navigator.serviceWorker.register(AppGlobalSettings.serviceWorkerUrl, {
            scope: "/",
        })

        try {
            caches.open(HtmlCache)
                .then(cache => {
                    if (cache) return (
                        cache.add(FallbackHtmlUrl)
                    )
                })
        }
        catch {
            DebugLogger.log("error occured while caching...")
        }

        deleteOldServiceWorkerCache()

        const urls: string[] = []
        const loadData = (records: any, url: string) => {
            if (records) {
                StaleWhileRevalidateStateServiceWorkerConfig.forEach(api => {
                    if (url.includes(api.apiUrl)) {
                        DebugLogger.log(`New data, for url: ${url}. records:`, records)
                        store.dispatch(api.action(records))
                    }
                })
            }
        }

        if ("serviceWorker" in navigator) {
            window.addEventListener("load", () => {
                const wb = new Workbox("/serviceWorker.js")
                wb.register()
            })

            navigator.serviceWorker.addEventListener("message", async event => {
                // Optional: ensure the message came from workbox-broadcast-update
                if (event.data.meta === "workbox-broadcast-update") {
                    const { cacheName, updatedURL } = event.data.payload

                    DebugLogger.log(`cacheName '${cacheName}', updatedURL: '${updatedURL}'`)

                    if (event.data.payload.cacheName === "401") {
                        DebugLogger.log("Log out req 401")
                        return
                    }

                    urls.push(updatedURL)

                    const cache = await caches.open(StaleWhileRevalidateApiCache)
                    const cachedRespsonse = await cache.match(updatedURL) as Response

                    if (!cachedRespsonse?.ok) {
                        const response = await cache.delete(updatedURL)
                        DebugLogger.log(`Deleted cache '${updatedURL}', Deleted response: '${response}'`)
                    }

                    const records = await cachedRespsonse.json()
                    loadData(records, updatedURL)
                }
            })
        }

        if (AppGlobalSettings.isDebug()) {
            DebugLogger.log("Service worker registered for scope %s", registration.scope)
        }
    } else {
        const registration = await navigator.serviceWorker.ready
        const didUnregister = await registration.unregister()

        if (didUnregister && AppGlobalSettings.isDebug()) {
            DebugLogger.log("Service worker unregistered for scope %s", registration.scope)
        }
    }
}

export default registerServiceWorker
