import {
    SettingsActionTypes,
    SettingsLoadingAction,
    SettingsLoadedAction,
    SettingsSuccessAction,
    SettingsHideMaintenanceMessageAction,
    SettingsHideCustomerServiceMessageAction,
    SettingsApiResponse,
    SettingsErrorAction,
} from "./types"

export const actions = {
    /**
     * Initiates loading of the app settings.
     */
    loading: (): SettingsLoadingAction => ({ type: SettingsActionTypes.Loading }),
    loaded: (): SettingsLoadedAction => ({ type: SettingsActionTypes.Loaded }),
    success: (payload: SettingsApiResponse): SettingsSuccessAction => ({ type: SettingsActionTypes.Success, payload }),
    error: (error: string | Error): SettingsErrorAction => ({ type: SettingsActionTypes.Error, error }),
    hideMaintenanceMessage: (): SettingsHideMaintenanceMessageAction => ({ type: SettingsActionTypes.HideMaintenanceMessage }),
    hideCustomerServiceMessage: (): SettingsHideCustomerServiceMessageAction => ({ type: SettingsActionTypes.HideCustomerServiceMessage }),
}
