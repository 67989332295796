import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import triggerBonusReducers from "@ng-mw/shared-meny-components/src/modules/trigger-bonus/store/reducers"
import favoriteRecipesReducers from "@ng-mw/shared-meny-components/src/modules/recipes/store/reducers"
import weekmenuReducers from "@ng-mw/shared-meny-components/src/modules/WeekMenu/store/reducers"
import recipeRatingReducers from "@ng-mw/shared-meny-components/src/modules/recipes/store/reducers/recipeRatingReducer"  // For DinnerAssistant
import { reducers as accountVerificationReducer } from "@ng-mw/shared-react-components/account-verification"
import { reducers as corporateRegistrationReducers } from "@ng-mw/shared-react-components/corporate-registration"
import { reducers as nextOpenOrderReducers } from "@ng-mw/shared-react-components/handover-picker"
import { insightReducer, keyfiguresReducer } from "@ng-mw/shared-react-components/insight"
import appEnvConfigReducer from "@app/store/appEnvConfig/appEnvConfigReducer"
import appRatingReducer from "@app/store/appRating/appRatingReducer"
import appReducer from "@app/store/app/appReducer"
import nativeReducer from "@app/store/native/nativeReducer"
import settingsReducer from "@app/store/settings/settingsReducer"
import categoriesReducers from "@modules/products/store/reducers/categories"
import editorialContentReducer from "@modules/editorial-content/store/reducers/editorialContentReducer"
import historyReducer from "@modules/orders/store/reducers/historyReducer"
import offerCampaignsReducers from "@modules/savings/store/reducers/offerCampaigns"
import savingsTipsReducers from "@modules/loyalty-club/store/reducers/savingTipsReducer"
import aboutLoyaltyClubReducers from "@modules/loyalty-club/store/reducers/aboutLoyaltyClubReducer"
import qrReducer from "@modules/user/store/reducers/qrReducer"
import { reducers as transferBonusToCharityReducer } from "@ng-mw/shared-react-components/transfer-bonus-to-charity"
import { reducers as transferBonusToBankAccountReducer } from "@ng-mw/shared-react-components/transfer-bonus-to-bank-account"

import {
    cartReducers,
    // cartSuggestionsReducers,
    checkoutReducers,
    consentsReducers,
    couponsReducers,
    // debugReducers,
    handoverOptionsReducers,
    handoverPickerReducers,
    handoverWindowsReducers,
    // nextOpenOrderReducers,
    ngcMyPageReducers,
    ngcSimplifiedRegistrationReducers,
    ordersReducers,
    orderStatusReducers,
    productDetailsReducers,
    productsReducers,
    productViewReducers,
    pushNotificationsReducers,
    // ratingReducers,
    recipesReducers,
    relatedProductsReducers,
    savingsReducers,
    searchReducers,
    shoppingListsReducers,
    transactionHistoryReducers,
    useBonusReducers,
    userReducers,
} from "@ng-mw/shared-react-components/reducers"

export default combineReducers({
    ...accountVerificationReducer,
    ...cartReducers,
    // ...cartSuggestionsReducers,
    ...checkoutReducers,
    ...consentsReducers,
    ...corporateRegistrationReducers,
    ...couponsReducers,
    ...favoriteRecipesReducers,
    ...handoverOptionsReducers,
    ...handoverPickerReducers,
    ...handoverWindowsReducers,
    ...nextOpenOrderReducers,
    ...ngcMyPageReducers,
    ...ngcSimplifiedRegistrationReducers,
    ...orderStatusReducers,
    ...ordersReducers,
    ...productDetailsReducers,
    ...productsReducers,
    ...productViewReducers,
    ...pushNotificationsReducers,
    ...recipesReducers,
    ...relatedProductsReducers,
    ...savingsReducers,
    ...searchReducers,
    ...shoppingListsReducers,
    ...transactionHistoryReducers,
    ...triggerBonusReducers,
    ...useBonusReducers,
    ...userReducers,
    ...transferBonusToCharityReducer,
    ...transferBonusToBankAccountReducer,
    ...weekmenuReducers,
    app: appReducer,
    appRating: appRatingReducer,
    native: nativeReducer,
    // diet: dietReducer,
    insight: insightReducer,
    ...keyfiguresReducer,
    offerCampaigns: offerCampaignsReducers,
    savingsTips: savingsTipsReducers,
    aboutLoyaltyClub: aboutLoyaltyClubReducers,
    form: formReducer,
    categories: categoriesReducers,
    // dinnerOfTheWeek: dinnerOfTheWeekReducers,
    settings: settingsReducer,
    history: historyReducer,
    recipeRating: recipeRatingReducers,  // For DinnerAssistant
    qr: qrReducer, // for digital trumf card
    appEnvConfig: appEnvConfigReducer,
    appContent: editorialContentReducer,
})
