import { core } from "@ng-mw/shared-react-components/framework"

// for user without selected store, default store will not do for getting
// campaigns, these are hardcoded proper fallbacks for campaigns
export default async (allowDefaultStore = false) => {
    let gln

    try {
        gln = (await core.getExtendedUser()).store.gln
    } catch (e) {
        // do  nothing
    }

    if (!parseInt(gln)) {
        if (allowDefaultStore) {
            return 0
        }

        switch (core.Settings.env) {
            case core.Environment.PREPRODUCTION:
                return 7080001296346
            case core.Environment.PRODUCTION:
                return 7080000886050
            default:
                return 7080001296346
        }
    } else {
        return gln
    }
}
