import "./style/loyalty-club-bonus-campaign.scss"

import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bonusCampaignActions, getActiveBonus } from "@ng-mw/shared-meny-components/bonus-campaign"
import { RootState } from "@app/types"
import PathNames from "@app/const/PathNames"
import { SapCrmCampaign } from "@app/types/root-state/bonusCampaign"
import { showLoginPopup } from "@app/store/app/appActions"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { useLoyaltyClubStatus } from "./helpers"

// icons
import chevronRight from "@ng-mw/reol/icons/chevronRight"

// Components
import AppLink from "@modules/shared/AppLink"
import Icon from "@ng-mw/reol/components/Icon"
import LoadingSkeleton from "@modules/shared/LoadingSkeleton"

interface LoyaltyClubBonusCampaignProps {
    isOnlyVisibleIfActive?: boolean
}

const LoyaltyClubBonusCampaign = ({
    isOnlyVisibleIfActive = false,
}: LoyaltyClubBonusCampaignProps) => {
    const dispatch = useDispatch<any>()
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const isConsentsInitialized = useLoyaltyClubStatus()
    const userIsLoaded = useSelector((state: RootState) => !!state.user.data.chainId)
    const bonusCampaign = useSelector((state: RootState) => state.bonusCampaign?.data)
    const filteredBonus: SapCrmCampaign[] = useMemo(() => getActiveBonus(bonusCampaign), [bonusCampaign])

    useEffect(() => {
        if (userIsLoggedIn && !userIsCorporate && !bonusCampaign?.length) {
            dispatch(bonusCampaignActions.getRelevantBonuses())
        }

    }, [userIsLoggedIn, dispatch, bonusCampaign, userIsCorporate])

    const loyaltyClubBonusItem = filteredBonus?.length ? filteredBonus?.find(item => item.kampanjeId === AppGlobalSettings.loyaltyClubStartCampaignId) : null // Meny Mer start-bonus campaign

    const text = loyaltyClubBonusItem?.erPaameldt && userIsLoggedIn ? loyaltyClubBonusItem?.langBeskrivelse : "Bli MENY MER-kunde og få 10 % Trumf-bonus på din første handel hos MENY"
    const imageUrl = "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_600/nbulyfxvnpvycn7tciby"

    const handlelogin = () => {
        const loginData = {
            title: "Bli MENY MER-kunde og få velkomstbonus",
            text: "Som ny MENY MER-kunde ønsker vi deg velkommen med 10 % Trumf-bonus på din første handel hos MENY. Det er nesten trippel Trippel-Trumf. Bare litt mer.",
            imageUrl: "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_600/zuab9ziw1hokmruzgu1b",
        }
        dispatch(showLoginPopup(loginData))
    }

    if ((isOnlyVisibleIfActive && !loyaltyClubBonusItem?.erPaameldt && userIsLoggedIn) || (userIsLoggedIn && !loyaltyClubBonusItem?.erPaameldt) || userIsCorporate) {
        return null
    }

    return (
        <LoadingSkeleton
            isLoading={userIsLoggedIn ? !isConsentsInitialized : !userIsLoaded}
            margin="medium"
        >
            <AppLink
                to={!loyaltyClubBonusItem?.erPaameldt && !userIsLoggedIn ? "" : PathNames.Search}
                skin="link"
                onClick={!loyaltyClubBonusItem?.erPaameldt && !userIsLoggedIn ? handlelogin : () => undefined}
            >
                <div className="loyalty-club-bonus-campaign">
                    <img
                        loading="lazy"
                        className="loyalty-club-bonus-campaign__image"
                        src={imageUrl}
                        aria-hidden="true"
                        alt=""
                    />
                    <div className="loyalty-club-bonus-campaign__info-container">
                        <span className="loyalty-club-bonus-campaign__info-text">
                            {text}
                        </span>
                        <Icon
                            icon={chevronRight}
                            size={{ width: "24px", height: "24px" }}
                        />
                    </div>
                </div>
            </AppLink>
        </LoadingSkeleton>
    )
}
export default LoyaltyClubBonusCampaign
