import "./style/popup-scroll-view.scss"

import classNames from "classnames"
import React, { LegacyRef, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { popupScrollPositionChange } from "@app/store/app/appActions"
import { RootState } from "@app/types"

export interface PopupScrollViewProps {
    isScrollBarHidden?: boolean
    children?: React.ReactNode
}

const PopupScrollView = ({
    isScrollBarHidden = false,
    children,
}: PopupScrollViewProps, ref: LegacyRef<HTMLDivElement>) => {
    const dispatch = useDispatch<any>()
    const popupScrollPosition = useSelector((state: RootState) => state.app.popupScrollPosition)
    const innerRef = useRef<HTMLDivElement>({} as any)
    const [y, setY] = useState(0)

    const handleTouchStart = (e: React.TouchEvent) => {
        setY(e.touches[0].clientY)
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        const { scrollTop } = innerRef.current

        const dy = e.touches[0].clientY - y
        const goingDown = dy < 15
        const atTop = scrollTop <= 15

        if (atTop && !popupScrollPosition.top) {
            dispatch(popupScrollPositionChange(true, false))
        }

        if (goingDown && !popupScrollPosition.goingDown && !atTop) {
            dispatch(popupScrollPositionChange(false, true))
        }
    }

    const handleTouchEnd = () => {
        setY(y)
    }

    const handleScroll = () => {
        const { scrollTop } = innerRef.current as unknown as any
        const goingDown = scrollTop > 10
        const atTop = scrollTop <= 15

        if (atTop && !popupScrollPosition.top) {
            dispatch(popupScrollPositionChange(true, false))
        }

        if (goingDown && !popupScrollPosition.goingDown && !atTop) {
            dispatch(popupScrollPositionChange(false, true))
        }
    }

    return (
        <div
            className="app-popup-scroll-view"
            ref={ref}
        >
            <div
                className={classNames("app-popup-scroll-view__inner", {
                    "app-popup-scroll-view__inner--scrollbar": !isScrollBarHidden,
                })}
                ref={innerRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onScroll={handleScroll}
            >
                {children}
            </div>
        </div>
    )
}

export default React.forwardRef(PopupScrollView)
