import "./style/choppy.scss"

import React from "react"

interface ChoppyProps {
    className?: string
}

const Choppy = ({
    className = "choppy",
}: ChoppyProps) => {
    return (
        <picture>
            <source
                srcSet="/images/choppy_q50.webp"
                type="image/webp"
            />
            <source
                srcSet="/images/choppy.png"
                type="image/png"
            />
            <img
                src="/images/choppy.png"
                alt=""
                loading="lazy"
                className={className}
                width="360"
                height="64"
            />
        </picture>
    )
}

export default Choppy
