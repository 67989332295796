import "./style/popup-content.scss"

import classNames from "classnames"
import React from "react"

interface PopupContentProps {
    children: React.ReactNode
    className?: string
    isSimple?: boolean
}

export const PopupContent = ({
    children,
    className,
    isSimple,
}: PopupContentProps) => {
    return (
        <div
            className={classNames("app-popup-content", {
                "app-popup-content--simple": isSimple,
            }, className)}
        >
            {children}
        </div>
    )
}

export default PopupContent
