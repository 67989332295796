import MenyLocalStorage from "@app/MenyLocalStorage"
import { AppNewsData } from "../pages/NewsPopup"
import PathNames from "@app/const/PathNames"

export const NewsConfig: AppNewsData[] = [
    {
        title: 'Gjør mer med Trumf-bonusen din i appen! 💰',
        text: 'Nå kan du enkelt velge om du vil bruke Trumf-bonusen din som betaling på MENY, donere til veldedighet, la den stå og spar til en reise, eller overfør til bankkontoen din og bruk den på det du vil. Ka-ching!',
        linkPath: PathNames.UseBonus,
        cloudinaryId: 'loltoxrbmhqkeph4y17v',
        localStorageKey: MenyLocalStorage.UserSeenAppNewsTrumfBonusPopup,
        validFrom: new Date('2024-09-04')
    }
]
