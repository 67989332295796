import differenceInDays from "date-fns/differenceInDays"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { LocalStorage } from "@ng-mw/shared-react-components"
import MenyLocalStorage from "@app/MenyLocalStorage"
import { useNativeBuildNumber } from "@app/helpers"
import { hideRatingPopup } from "@app/store/appRating/appRatingActions"
import { RootState } from "@app/types"

import Popup from "@modules/popup/Popup"
import { AppRating } from "../loaders"

const daysToHide = 120 // ca 1/3 of a year

const AppRatingPopup = () => {
    const dispatch = useDispatch<any>()
    const { supportsRating } = useNativeBuildNumber()

    const { visible: isVisible, force } = useSelector((state: RootState) => state.appRating.ratingPopup)
    const lastAsked = LocalStorage.get(MenyLocalStorage.UserAskedToRateApp)
    const recentlyAsked = lastAsked && (differenceInDays(new Date(), new Date(lastAsked)) < daysToHide)

    const isOpen = supportsRating && isVisible && (force || !recentlyAsked)

    const handleClose = () => dispatch(hideRatingPopup())

    return (
        <Popup
            isOpen={isOpen}
            onClose={handleClose}
            isSimple
            title="Liker du appen vår?"
            isTitleHidden
            isHeaderActionsOverflow
        >
            <AppRating />
        </Popup>
    )
}

export default AppRatingPopup
