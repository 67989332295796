import "./style/editorial-content.scss"

import React from "react"
import classNames from "classnames"

// Icons
import arrowExternalIcon from "@ng-mw/reol/icons/arrowExternal"

// Components
import Icon from "@ng-mw/reol/components/Icon"
import { AppSafeLink } from "@ng-mw/shared-react-components/shared"

interface InspirationContentBlockProps {
    id: string
    content: any
}

const ContentBlock = ({
    id,
    content,
}: InspirationContentBlockProps) => {

    const renderedContent = (
        <>
            {content.Image ? (
                <img
                    className="editorial-content__image"
                    src={content.Image}
                    alt={content.Title}
                    width="400px"
                    height="110px"
                />
            ) : null}
            <div className="editorial-content__content">
                <h3 className="editorial-content__title">
                    {content.Title}
                </h3>
                {content.Link ? (
                    <span className="editorial-content__link">
                        <Icon
                            icon={arrowExternalIcon}
                            isInline
                        />
                    </span>
                ) : null}
            </div>
        </>
    )

    if (content.Link) {
        return (
            <AppSafeLink
                id={id}
                appHref={content.Link}
                className={classNames("editorial-content__item", `editorial-content__item--style-${content.Style}`)}
                usePlainElement
            >
                {renderedContent}
            </AppSafeLink>
        )
    }

    return (
        <div
            className={classNames("editorial-content__item", `editorial-content__item--style-${content.Style}`)}
            id={id}
        >
            {renderedContent}
        </div>
    )
}

export default ContentBlock
