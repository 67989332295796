import "./style/popup-header.scss"

import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

// Icons
import chevronLeftIcon from "@ng-mw/reol/icons/chevronLeft"
import closeIcon from "@ng-mw/reol/icons/close"

// Components
import Icon from "@ng-mw/reol/components/Icon"
import classNames from "classnames"

interface PopupHeaderProps {
    ariaControls?: string
    goBackTitle?: string
    isActionsOverflow?: boolean
    onClose?: any
    onGoBack?: any
    title?: string | React.ReactNode
}

const PopupHeader = ({
    ariaControls,
    goBackTitle = "Tilbake",
    isActionsOverflow,
    onClose,
    onGoBack,
    title,
}: PopupHeaderProps) => {
    const popupScrollPosition = useSelector((state: RootState) => state.app.popupScrollPosition)

    const headerClass = classNames(
        "app-popup-header",
        {
            "app-popup-header--is-top": popupScrollPosition.top && !isActionsOverflow,
            "app-popup-header--is-going-down": popupScrollPosition.goingDown && !isActionsOverflow,
            "app-popup-header--overflow": isActionsOverflow,
        },
    )

    if (!onGoBack && !title && !onClose) return null

    return (
        <header
            className={headerClass}
            role="banner"
        >
            {onGoBack ? (
                <button
                    className="app-popup-header__back-button"
                    type="button"
                    onClick={onGoBack}
                    aria-label={goBackTitle}
                >
                    <Icon
                        icon={chevronLeftIcon}
                        size={{ width: "28px", height: "28px" }}
                    />
                </button>
            ) : null}
            {title ? (
                <h1
                    className={classNames(
                        "app-popup-header__title",
                        {
                            "app-popup-header__title--is-going-down": popupScrollPosition.goingDown,
                            "app-popup-header__title--is-top": popupScrollPosition.top,
                        },
                    )}
                    aria-live="polite"
                >
                    {title}
                </h1>
            ) : null}
            <div />
            {onClose ? (
                <button
                    className="app-popup-header__close-button"
                    type="button"
                    onClick={onClose}
                    aria-controls={ariaControls}
                >
                    <Icon
                        icon={closeIcon}
                        size={{ width: "12px", height: "12px" }}
                    />
                    <span className="ws-visually-hidden">
                        Lukk
                    </span>
                </button>
            ) : null}
        </header>
    )
}

export default PopupHeader
