import { GenericAction } from "@app/types"
import { AboutLoyaltyClubAction } from "../actions/creators/aboutLoyaltyClubCreator"
import { AboutLoyaltyClub } from "@app/types/root-state/aboutLoyaltyClub"

const init: AboutLoyaltyClub = {
    loading: false,
    error: null,
    data: [],
    loaded: false,
}

export default function (state = init, action: GenericAction) {
    switch (action.type) {
        // Get all
        case AboutLoyaltyClubAction.Loading:
            return { ...state, loading: true }
        case AboutLoyaltyClubAction.Loaded:
            return { ...state, loading: false, loaded: true }
        case AboutLoyaltyClubAction.Success:
            return { ...state, data: action.payload, error: null }
        case AboutLoyaltyClubAction.Error:
            return { ...state, error: action.payload }
        // Update store
        case AboutLoyaltyClubAction.FromCache: {
            return { ...state, data: action.payload, error: null, loaded: true }
        }
        default:
            return state
    }
}
