import "../style/user-qr.scss"

import { NativeEventBridge } from "@ng-mw/shared-react-components"
import { consentsActions, consentsCreator } from "@ng-mw/shared-react-components/consents"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import { usePrevious } from "@app/helpers"
import { hideAgreementDeactivationPopup } from "@app/store/app/appActions"
import { logOut } from "@modules/user/store/actions/user"
import { RootState } from "@app/types"
import { useLoyaltyClubStatus } from "@modules/loyalty-club/helpers"

// Components
import Alert from "@ng-mw/reol/components/Alert"
import Button from "@ng-mw/reol/components/Button"
import Popup, { PopupButtons, PopupContent, PopupText } from "@modules/popup/Popup"

export const AgreementDeactivationPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.agreementDeactivationPopup.visible)
    const { error } = useSelector((state: RootState) => state.consents)
    const { isLoyaltyClubMember, loyaltyClubAgreement } = useLoyaltyClubStatus()
    const prevIsLoyaltyClubMember = usePrevious(isLoyaltyClubMember)

    const handleClose = () => {
        dispatch(consentsCreator.updateStatusError(null))
        dispatch(hideAgreementDeactivationPopup())
    }

    const handleDeactivate = () => {
        dispatch(consentsCreator.updateStatusError(null))
        dispatch(consentsActions.updateAgreementStatus(loyaltyClubAgreement, "false"))
    }

    useEffect(() => {
        // If isLoyaltyClubMember change from true to false, the user just deactivated the agreement
        if (prevIsLoyaltyClubMember && !isLoyaltyClubMember) {
            // Force log out
            NativeEventBridge.broadcastNative(MenyNativeEvent.ForceLogOut)
            dispatch(logOut())
            // Close popup
            dispatch(consentsCreator.updateStatusError(null))
            dispatch(hideAgreementDeactivationPopup())
        }
    }, [dispatch, isLoyaltyClubMember, prevIsLoyaltyClubMember])

    return (
        <Popup
            onClose={handleClose}
            isOpen={isOpen}
            title={`Bekreft deaktivering av ${loyaltyClubAgreement?.viewText}`}
        >
            <PopupContent isSimple>
                <PopupText>
                    {loyaltyClubAgreement?.subscriptionCancelText} Du vil også bli logget ut av appen.
                </PopupText>
            </PopupContent>
            <PopupButtons isSimple>
                <Alert
                    show={!!error}
                    error={error}
                />
                <Button
                    text="Deaktiver og logg ut"
                    onClick={handleDeactivate}
                    isDisabled={loyaltyClubAgreement?.updating}
                    isLoading={loyaltyClubAgreement?.updating}
                />
                <Button
                    text="Avbryt"
                    isCancel
                    onClick={handleClose}
                />
            </PopupButtons>
        </Popup>
    )
}

export default AgreementDeactivationPopup
