import React from "react"

import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"
import { BecomeMemberButton, LoginButton } from "@ng-mw/shared-react-components/shared"

interface InlineLoginButtonsProps {
    caption?: string
    className?: string
}

const InlineLoginButtons = ({
    caption,
    className,
}: InlineLoginButtonsProps) => {
    return (
        <ButtonGroup
            className={className}
            direction="vertical"
            spacing="tight"
        >
            <LoginButton caption={caption} />
            <BecomeMemberButton
                caption="Bli medlem"
                isCancel
            />
        </ButtonGroup>
    )

}

export default InlineLoginButtons
