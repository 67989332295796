import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

// Components
import { Onboarding } from "../loaders"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { PopupTransitions } from "@modules/popup/Popup"

const AppOnboardingPoster = () => {
    const isOpen = useSelector((state: RootState) => state.app.appOnboardingPoster.visible)
    return (
        <PopupTransitions
            isOpen={isOpen}
            popupAnimation="slide-left"
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className="app-onboarding__wrapper"
            >
                <Onboarding />
            </LazyRender>
        </PopupTransitions>
    )
}

export default AppOnboardingPoster
