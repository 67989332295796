import { CampaignTypeEnum } from "@app/types"

export const CampaignsAction = {
    Loading: "campaigns:loading",
    Loaded: "campaigns:loaded",
    Success: "campaigns:success",
    FromCache: "campaigns:from-cache",
    Error: "campaigns:error",
}

export const success = (campaignType: CampaignTypeEnum, data: any) => {
    return {
        type: CampaignsAction.Success,
        payload: { campaignType, data },
    }
}
export const fromCache = (campaignType: CampaignTypeEnum, data: any) => {
    return {
        type: CampaignsAction.FromCache,
        payload: { campaignType, data },
    }
}

export const error = (error: any) => {
    return {
        type: CampaignsAction.Error,
        payload: error,
    }
}

export const loading = () => {
    return {
        type: CampaignsAction.Loading,
    }
}

export const loaded = () => {
    return {
        type: CampaignsAction.Loaded,
    }
}
