import "./style/campaign-item.scss"

import React from "react"
import { core } from "@ng-mw/shared-react-components/framework"
import { CampaignType } from "@app/types"
import classNames from "classnames"
import { OfferCampaign } from "@app/types/root-state/offerCampaigns"

// Icons
import arrowRight from "@ng-mw/reol/icons/arrowRight"
import MenyLoyaltyClubFlagIcon from "@ng-mw/shared-react-components/icons/MenyLoyaltyClubFlag"
import heart from "@resources/icons/heart"

// Components
import Icon from "@ng-mw/reol/components/Icon"
import AppLink from "@modules/shared/AppLink"

interface CampaignItem {
    item: OfferCampaign
    index: number
}

const CampaignItem = ({
    item,
    index,
}: CampaignItem) => {

    const getUrl = (imageId: string) => {
        if (!imageId) {
            return null
        }
        return core.CloudinaryImages.getImageUrl({ width: 600, filename: imageId })
    }

    const getCampaignTags = (item: OfferCampaign) => {
        if (item.tags.includes(CampaignType.Bargain)) {
            return (
                <p className="campaign-item__tag">
                    FASTE KNALLKJØP
                </p>
            )
        }
        if (item.tags.includes(CampaignType.Offer)) {
            return (
                <p className="campaign-item__tag">
                    TILBUD
                </p>
            )
        }
        if (item.tags.includes(CampaignType.LoyaltyClub)) {
            return (
                <Icon
                    icon={MenyLoyaltyClubFlagIcon}
                    size={{ width: "78px", height: "32px" }}
                />
            )
        } else {
            return null
        }
    }

    return (
        <AppLink
            state={{ campaignName: item.marketTextLong, campaignDescription: item.marketText, id: item.campaignId, campaignTags: item.tags, order: index + 1 }}
            to={`/savings/campaigns/${item.campaignId}?${item.tags.includes(CampaignType.LoyaltyClub) ? "loyalty-club" : null}`}
            className="campaign-item"
        >
            <img
                className="campaign-item__image"
                src={getUrl(item.imageId)}
                alt={item.marketTextLong ? item.marketTextLong : item.marketText}
                width="600px"
                height="auto"
            />

            <div
                className={classNames("campaign-item__content", {
                    "campaign-item__content--loyalty-club": item.tags.includes(CampaignType.LoyaltyClub),
                })}
            >
                {item.tags.includes(CampaignType.LoyaltyClub) ? (
                    <>
                        <Icon
                            icon={heart}
                            className="campaign-item__heart campaign-item__heart--left"
                            size={{ width: "229px", height: "206px" }}
                        />
                        <Icon
                            icon={heart}
                            className="campaign-item__heart campaign-item__heart--right"
                            size={{ width: "281px", height: "245px" }}
                        />
                    </>
                ) : null}
                <div className="campaign-item__container">
                    <div>
                        {getCampaignTags(item)}
                    </div>
                    <span className="campaign-item__title">
                        {item.marketTextLong ? item.marketTextLong : item.marketText}
                    </span>
                    <div
                        className={classNames("campaign-item__icon-container", {
                            "campaign-item__icon-container--loyalty-club": item.tags.includes(CampaignType.LoyaltyClub),
                        })}
                    >
                        <Icon
                            className="campaign-item__icon"
                            icon={arrowRight}
                            size={{ height: "20px", width: "20px" }}
                        />
                    </div>
                </div>
            </div>
        </AppLink>
    )
}

export default CampaignItem
