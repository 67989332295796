import React from "react"
import Loadable from "react-loadable"

export const Onboarding = Loadable({
    loader: () => import( /* webpackChunkName: "Onboarding" */ "./AppOnboarding"),
    loading: () => <div />,
})

export const AppRating = Loadable({
    loader: () => import( /* webpackChunkName: "Onboarding" */ "./AppRating"),
    loading: () => <div />,
})

export const AppUpgrade = Loadable({
    loader: () => import( /* webpackChunkName: "Onboarding" */ "./AppUpgrade"),
    loading: () => <div />,
})

export const AppNews = Loadable({
    loader: () => import( /* webpackChunkName: "Registration" */ "./AppNews"),
    loading: () => <div />,
})

export const ForcedUpgrade = Loadable({
    loader: () => import( /* webpackChunkName: "Forced" */ "./ForcedUpgrade"),
    loading: () => <div />,
})

export const Registration = Loadable({
    loader: () => import( /* webpackChunkName: "Registration" */ "./Registration"),
    loading: () => <div />,
})

export const RegistrationCorporate = Loadable({
    loader: () => import( /* webpackChunkName: "RegistrationCorporate" */ "./RegistrationCorporate"),
    loading: () => <div />,
})
