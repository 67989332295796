const PopupNames = {
    activateLoyaltyClubPopup: "activateLoyaltyClubPopup",
    agreementDeactivationPopup: "agreementDeactivationPopup",
    appOnboardingPoster: "appOnboardingPoster",
    appUpgradePopup: "appUpgradePopup",
    barcodePopup: "barcodePopup",
    categoryFilterPopup: "categoryFilterPopup",
    changeNameShoppingListPopup: "changeNameShoppingListPopup",
    checkoutPaymentPopup: "checkoutPaymentPopup",
    checkoutPopup: "checkoutPopup",
    climateComparisonPopup: "climateComparisonPopup",
    climateInformationPopup: "climateInformationPopup",
    climateNumbersPopup: "climateNumbersPopup",
    climateProductGroupsInfoPopup: "climateProductGroupsInfoPopup",
    couponPopup: "couponPopup",
    createShoppingListPopup: "createShoppingListPopup",
    dietEnergyPopup: "dietEnergyPopup",
    dietGroupPopup: "dietGroupPopup",
    dietPropertyPopup: "dietPropertyPopup",
    feedbackPopup: "feedbackPopup",
    forcedUpgradePopup: "forcedUpgradePopup",
    handoverPickerPopup: "handoverPickerPopup",
    insightAboutPopup: "insightAboutPopup",
    insightKeyFiguresPopup: "insightKeyFiguresPopup",
    insightOnboardingPopup: "insightOnboardingPopup",
    loginPopup: "loginPopup",
    logOutPopup: "logOutPopup",
    newsPopup: "newsPopup",
    osUpgradePopup: "osUpgradePopup",
    productPopup: "productPopup",
    pushConsentsPopup: "pushConsentsPopup",
    purchaseComplaintPopup: "purchaseComplaintPopup",
    purchaseDetailsPopup: "purchaseDetailsPopup",
    qrPopup: "qrPopup",
    recipePopup: "recipePopup",
    recipeRatingPopup: "recipeRatingPopup",
    registrationPopup: "registrationPopup",
    removeShoppingListPopup: "removeShoppingListPopup",
    trumfIdModal: "trumfIdModal",
    welcomePopup: "welcomePopup",
    loyaltyClubInfoPopup: "loyaltyClubInfoPopup",
    gamePopup: "gamePopup",
}

export default PopupNames
