import { Consent } from "@app/types"
import AppGlobalSettings from "@app/AppGlobalSettings"

export const getWhitelistByChannel = (consents: Consent[], channels: string[], whiteListedTypes: string[]) => {
    return consents
        .filter((consent: Consent) => consent.itemType === "CONSENT" && consent.chainId === AppGlobalSettings.chainId && channels.includes(consent.channel) && whiteListedTypes.includes(consent.type))
        .map((consent: Consent) => ({
            chainId: consent.chainId,
            type: consent.type,
            channelCode: consent.channelCode,
            channel: consent.channel,
            status: consent.status === "true" ? true : false,
        }))
}
