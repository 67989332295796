import "./style/app-layout-wrapper.scss"

import React, { useEffect } from "react"
import classNames from "classnames"
import { Switch, useHistory, useLocation } from "react-router"
import { History } from "history"
import { LifecycleEmitter, NativeEvent, NativeEventBridge } from "@ng-mw/shared-react-components"
import { animationDuration } from "@app/LayoutSettings"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import MenyWebAppEvent from "@app/events/MenyWebAppEvent"
import { useSelector } from "react-redux"
import routes from "@app/routes"
import { RootState } from "@app/types"

// Components
import AppLayoutFooter from "@modules/framework/AppLayoutFooter"
import MountNotifier from "./MountNotifier"
import { TransitionGroup, CSSTransition } from "react-transition-group"

interface historyProps extends History {
    index: number
}

interface AppLayoutWrapperProps {
    children: React.ReactNode
}

const AppLayoutWrapper = ({
    children,
}: AppLayoutWrapperProps) => {
    const history = useHistory() as historyProps
    const location = useLocation<any>()
    const skipAnimation = !!(location.state && location.state.isImmediate)
    const isBack = !!(history.action === "POP" || (location.state && location.state.isBack))
    const { isFullscreenPopup, activePopups } = useSelector((state: RootState) => state.app)

    const enterAndExit = skipAnimation && !isBack ? false : true

    const rtgClassNames = !isBack ? "slide-forward" : "slide-back"

    useEffect(() => {
        const listener = NativeEventBridge.listen(NativeEvent.AndroidNavigateBack, () => {
            if (activePopups.length) {
                NativeEventBridge.broadcastWeb(MenyWebAppEvent.CloseMostRecentPopup, activePopups[activePopups.length - 1])
            } else {
                if (history.index > 0 || history.length > 1) {
                    history.goBack()
                } else {
                    NativeEventBridge.broadcastNative(NativeEvent.ReachedNavigationEnd)
                }
            }
        }, false)

        return () => {
            NativeEventBridge.unlisten(listener)
        }
    }, [history, activePopups])

    useEffect(() => {
        const { pathname, state } = location

        LifecycleEmitter.broadcast(MenyLifecycleEvent.Navigation, { pathname, state })

        const prevScrollTop = location?.state?.prevScrollTop

        if (prevScrollTop > 0) {
            try {
                const scrollContainer = document.querySelectorAll(".app-scroll-view__inner")[0]

                window.requestAnimationFrame(() => {
                    scrollContainer.scrollTop = prevScrollTop
                })

            } catch (error) {
                // nothing to do here
            }
        }

        // Only broadcast when pathname changes:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <div
            className={classNames(
                "app-layout-wrapper",
                { "app-layout-wrapper--full-screen-popup": isFullscreenPopup },
            )}
            inert={activePopups?.length > 0 ? "" : undefined}
        >
            <TransitionGroup
                component={React.Fragment}
                childFactory={child => React.cloneElement(child, {
                    classNames: rtgClassNames,
                    enter: enterAndExit,
                    exit: enterAndExit,
                })}
            >
                <CSSTransition
                    classNames={rtgClassNames}
                    timeout={{ exit: animationDuration, enter: animationDuration }}
                    key={location.key}
                    enter={enterAndExit}
                    exit={enterAndExit}
                >
                    <>
                        <Switch location={location}>
                            {routes}
                        </Switch>
                        <AppLayoutFooter>
                            {children}
                        </AppLayoutFooter>
                    </>
                </CSSTransition>
            </TransitionGroup>
            <MountNotifier />
        </div>
    )
}

export default AppLayoutWrapper
