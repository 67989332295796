import "./style/recipe-summary.scss"

import React from "react"

// Components
import { AddManyToCart, cartConstants } from "@ng-mw/shared-react-components/cart"
import { RecipeItem } from "@app/types"

export interface RecipeSummaryProps {
    recipeItem: RecipeItem
}

const RecipeSummary = ({
    recipeItem,
}: RecipeSummaryProps) => {

    let hasShoppingItems: any = null

    const { derivedData } = recipeItem
    const { totalQuantity } = derivedData

    if (recipeItem && recipeItem.rawData.channels) {
        hasShoppingItems = recipeItem.rawData.channels.find(channel => channel === "clickAndCollect")
    }

    if (!hasShoppingItems) {
        return null
    }

    return (
        <div className="recipe-summary">
            <AddManyToCart
                recipeId={recipeItem.rawData.id}
                items={derivedData.cartItems}
                totalQuantity={totalQuantity}
                isConfirmationDialogEnabled
                source={cartConstants.AddManyToCartSource.Recipe}
                list={`Oppskrift: ${recipeItem.rawData.name}`}
                hideIcon
                enableCheckbox
                enableQuantityStepper
                productCheckboxPosition="LEFT"
                className="recipe-summary__add-to-cart"
            />
        </div>
    )
}

export default RecipeSummary
