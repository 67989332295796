import { Product } from "@app/types"

export const HistoryAction = {
    SetComplaintProduct: "history:set-complaint-product",
}

Object.freeze(HistoryAction)

export const setComplaintProduct = (complaintProduct: Product) => ({
    type: HistoryAction.SetComplaintProduct,
    payload: complaintProduct,
})
