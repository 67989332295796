import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import { hideAppOnboardingPoster, hideLogOutPopup } from "@app/store/app/appActions"
import { NativeEventBridge } from "@ng-mw/shared-react-components"
import MenyNativeEvent from "@app/events/MenyNativeEvent"

// Components
import Popup, { PopupContent, PopupButtons, PopupText } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Button from "@ng-mw/reol/components/Button"

const LogOutPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible, title, text, buttonText } = useSelector((state: RootState) => state.app.logOutPopup)

    const handleClose = () => {
        dispatch(hideLogOutPopup())
    }

    const handleLogOut = () => {
        NativeEventBridge.broadcastNative(MenyNativeEvent.RequestLogOut)
        dispatch(hideLogOutPopup())
        dispatch(hideAppOnboardingPoster())
    }

    return (
        <Popup
            isOpen={visible}
            isForcedToFront
            title={title}
        >
            <LazyRender
                isVisible={visible}
                className="confirm-logout-popup"
            >
                <PopupContent>
                    <PopupText>
                        {text}
                    </PopupText>
                </PopupContent>
                <PopupButtons
                    isSimple
                >
                    <Button
                        onClick={handleLogOut}
                        text={buttonText ? buttonText : "Bekreft og logg ut"}
                    />
                    <Button
                        onClick={handleClose}
                        isCancel
                        text="Tilbake"
                    />
                </PopupButtons>
            </LazyRender>
        </Popup>
    )
}

export default LogOutPopup
