import "./style/app-root.scss"

import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { LocalStorage } from "@ng-mw/shared-react-components"
import MenyLocalStorage from "@app/MenyLocalStorage"
import { RootState } from "@app/types"
import { showAppOnboardingPoster, showNewsPopup } from "@app/store/app/appActions"
import { PushNotificationAndroidDrawer } from "@ng-mw/shared-react-components/push-notifications"

// Components
import AppLayoutWrapper from "./AppLayoutWrapper"
import AppNavigation from "./AppNavigation"
import Popups from "./Popups"
import { NewsConfig } from "@modules/onboarding/const/newsConfig"

const AppRoot = () => {
    const dispatch = useDispatch<any>()
    const safeArea = useSelector((state: RootState) => state.native.safeArea)
    const { osVersion, os } = useSelector((state: RootState) => state.appEnvConfig)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel && state.user.data.chainId)
    const isUserLoaded = useSelector((state: RootState) => !!state.user.data.chainId)

    useEffect(() => {
        const today = new Date()
        if (isUserLoaded && !userIsCorporate) {

            if (!LocalStorage.get(MenyLocalStorage.UserSeenNewOnboarding)) {
                dispatch(showAppOnboardingPoster())
            }

            if (userIsLoggedIn) {
                NewsConfig.forEach(news => {
                    if (!LocalStorage.get(news.localStorageKey)) {
                        if (news.validFrom && today > news.validFrom) {
                            dispatch(showNewsPopup(news))
                        }
                    }
                });
            }
        }

    }, [userIsCorporate, isUserLoaded, userIsLoggedIn, dispatch])

    return (
        <div
            style={{
                "--base-height": "100%",
                "--safe-area-top": `${safeArea.top}px`,
                "--safe-area-bottom": `${safeArea.bottom}px`,
            }}
        >
            <AppLayoutWrapper>
                <AppNavigation />
            </AppLayoutWrapper>
            <Popups />
            <PushNotificationAndroidDrawer
                androidApiLevel={osVersion}
                isAndroid={os === "android"}
            />
        </div>
    )
}

export default AppRoot
