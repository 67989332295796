import "../style/login-popup.scss"

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import { hideLoginPopup } from "@app/store/app/appActions"

import Popup, { PopupButtons, PopupContent, PopupText, PopupTitle } from "@modules/popup/Popup"
import { BecomeMemberButton, LoginButton, TextLink } from "@ng-mw/shared-react-components/shared"
import LazyRender from "@ng-mw/reol/components/LazyRender"

const LoginPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible, title, text, externalLink, externalLinkText, imageUrl } = useSelector((state: RootState) => state.app.loginPopup)
    const userLoginLevel = useSelector((state: RootState) => state.user.loginLevel)

    const handleClose = () => dispatch(hideLoginPopup())

    useEffect(() => {
        if (userLoginLevel > 0 && visible) {
            dispatch(hideLoginPopup())
        }

    }, [userLoginLevel, dispatch, visible])

    return (
        <Popup
            isOpen={visible}
            onClose={handleClose}
            isHeaderActionsOverflow
            isSimple
        >
            <LazyRender
                isVisible={visible}
            >
                <img
                    loading="lazy"
                    className="login-popup__image"
                    src={imageUrl ? imageUrl : "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_600/gowycgiz738obw6tz3lt"}
                    aria-hidden="true"
                    alt=""
                />
                <PopupContent isSimple>
                    <PopupTitle>{title}</PopupTitle>
                    <PopupText>{text}</PopupText>
                </PopupContent>
                <PopupButtons
                    isSimple
                >
                    <LoginButton className="login-popup__button" />
                    <BecomeMemberButton
                        caption="Bli medlem i Trumf"
                        isCancel
                    />
                    {externalLink ?
                        <TextLink
                            text={externalLinkText}
                            href={externalLink}
                            className="login-popup__button"
                            isExternal
                            isBlock
                        />
                        : null
                    }
                </PopupButtons>
            </LazyRender>
        </Popup>
    )
}

export default LoginPopup
