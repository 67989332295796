import { GenericAction } from "@app/types"
import { CategoriesAction } from "../actions/creators/categories"

const init = {
    storeId: null,
    loading: false,
    data: [],
    error: null,
}

export default (state = init, action: GenericAction) => {
    switch (action.type) {
        case CategoriesAction.Success:
            return { ...state, data: action.payload, error: null }
        case CategoriesAction.SetStoreId:
            return { ...state, storeId: action.payload }
        case CategoriesAction.Loading:
            return { ...state, loading: true }
        case CategoriesAction.Loaded:
            return { ...state, loading: false }
        case CategoriesAction.Error:
            return { ...state, error: action.payload }
        default:
            return state
    }
}
