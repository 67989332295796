import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideDietPropertyPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { InsightPopupHeader } from "../../common/loaders"
import ChatBubble from "@ng-mw/shared-meny-components/src/modules/insight/ChatBubble"
import { DietPropertyInfo } from "../loaders"

const DietPropertyPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.dietPropertyPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideDietPropertyPopup())}
            isOpen={isOpen}
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
            >
                <InsightPopupHeader />
                <PopupContent>
                    <DietPropertyInfo
                        CardComponent={ChatBubble}
                    />
                </PopupContent>
            </LazyRender>
        </Popup>
    )
}
export default DietPropertyPopup
