import "./style/popup-transitions.scss"

import classNames from "classnames"
import React from "react"
import { animationDuration } from "@app/LayoutSettings"

// Components
import { CSSTransition } from "react-transition-group"

export const PopupAnimation = {
    SlideLeft: "slide-left",
    SlideUp: "slide-up",
} as const

export type PopupAnimationEnum = typeof PopupAnimation[keyof typeof PopupAnimation];

interface PopupTransitionsProps {
    children: React.ReactNode
    className?: string
    contentRef?: any
    headingId?: string
    id?: string
    isOpen: boolean
    onEnter?: () => void
    onEntered?: () => void
    onEntering?: () => void
    onExit?: () => void
    onExited?: () => void
    onExiting?: () => void
    zIndex?: number
    popupAnimation?: PopupAnimationEnum
}

export const PopupTransitions = ({
    children,
    className,
    contentRef,
    headingId,
    id,
    isOpen,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    zIndex = 1,
    popupAnimation = PopupAnimation.SlideUp,
}: PopupTransitionsProps) => {

    return (
        <>
            <CSSTransition
                in={isOpen}
                unmountOnExit
                classNames="fade"
                timeout={{ exit: animationDuration, enter: animationDuration }}
                enter
                exit
            >
                <div
                    className="app-popup__background"
                    style={{ zIndex }}
                />
            </CSSTransition>
            <CSSTransition
                in={isOpen}
                unmountOnExit
                classNames={popupAnimation}
                timeout={{ exit: animationDuration, enter: animationDuration }}
                enter
                exit
                onEnter={onEnter}
                onEntering={onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={onExited}
            >
                <div
                    ref={contentRef}
                    role="dialog"
                    aria-live="polite"
                    aria-modal="true"
                    tabIndex={-1}
                    id={id}
                    aria-labelledby={headingId}
                    className={classNames("app-popup", className)}
                    style={{ zIndex }}
                >
                    {children}
                </div>
            </CSSTransition>
        </>
    )
}

export default PopupTransitions
