export const CategoriesAction = {
    Success: "categories:success",
    Error: "categories:error",
    Loading: "categories:loading",
    Loaded: "categories:loaded",
    SetStoreId: "categories:set-store-id",
}

export const setStoreId = (storeId: string) => {
    return {
        type: CategoriesAction.SetStoreId,
        payload: storeId,
    }
}

export const success = (data: any) => {
    return {
        type: CategoriesAction.Success,
        payload: data,
    }
}

export const error = (error: any) => {
    return {
        type: CategoriesAction.Error,
        payload: error,
    }
}

export const loaded = () => {
    return {
        type: CategoriesAction.Loaded,
    }
}

export const loading = () => {
    return {
        type: CategoriesAction.Loading,
    }
}
