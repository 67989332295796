import "./style/user-consents.scss"

import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Consent, RootState } from "@app/types"
import { isChristmasCampaignTime } from "@ng-mw/shared-meny-components/src/helpers/datePeriods"
import { usePrevious } from "@app/helpers"

// Icons
import arrowExternal from "@ng-mw/reol/icons/arrowExternal"

// Components
import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"
import { AppSafeLink } from "@ng-mw/shared-react-components/shared"
import { Consents } from "@ng-mw/shared-react-components/consents"
import Container, { Background, Content, Padding } from "@modules/shared/Container"
import PushNotificationsWrapper from "./PushNotificationsWrapper"
import { getWhitelistByChannel } from "./helpers/getWhitelistByChannel"

export interface UserConsentsProps {
    isEmailSmsOnlyVisibleIfStatusFalse: boolean
    isExternalLinkHidden: boolean
}

const UserConsents = ({
    isConsentsOnlyVisibleIfStatusFalse = false,
    isExternalLinkHidden = false,
}) => {
    const consentsData = useSelector((state: RootState) => state.consents.data)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const [isSmsVisible, setIsSmsVisible] = useState(false)
    const [isEmailVisible, setIsEmailVisible] = useState(false)
    const prevIsSmsVisible = usePrevious(isSmsVisible)
    const prevIsEmailVisible = usePrevious(isSmsVisible)

    const {
        consents,
        emailConsentsWhitelist,
        smsConsentsWhitelist,
    } = useMemo(() => {
        const consents = consentsData.filter((consent: Consent) => consent.itemType === "CONSENT")

        const whiteListedTypes = isChristmasCampaignTime(new Date()) ? ["JUL", "FORDEL"] : ["FORDEL"]
        const whiteListedEmailChannels = ["E-Mail"]
        const whiteListedSMSChannels = ["SMS"]

        const emailConsentsWhitelist = getWhitelistByChannel(consents, whiteListedEmailChannels, whiteListedTypes)
        const smsConsentsWhitelist = getWhitelistByChannel(consents, whiteListedSMSChannels, whiteListedTypes)

        const emailConsent = emailConsentsWhitelist[0] ? emailConsentsWhitelist[0] : null
        const smsConsent = smsConsentsWhitelist[0] ? smsConsentsWhitelist[0] : null

        const isSmsVisible = isConsentsOnlyVisibleIfStatusFalse ? (smsConsent?.status === false ? true : false) : true
        const isEmailVisible = isConsentsOnlyVisibleIfStatusFalse ? (emailConsent?.status === false ? true : false) : true

        if (!prevIsEmailVisible && isEmailVisible) {
            setIsEmailVisible(isEmailVisible)
        }

        if (!prevIsSmsVisible && isSmsVisible) {
            setIsSmsVisible(isSmsVisible)
        }

        return {
            consents,
            emailConsentsWhitelist,
            smsConsentsWhitelist,
        }
    }, [consentsData, isConsentsOnlyVisibleIfStatusFalse, prevIsSmsVisible, prevIsEmailVisible])

    return (
        <>
            {!userIsCorporate ? (
                <PushNotificationsWrapper />
            ) : null}
            {!userIsCorporate && isEmailVisible ? (
                <Container
                    background={Background.Gray}
                    padding={Padding.Reduced}
                    content={Content.Padding}
                >
                    <h2 className="user-consents__section-title">
                        E-post
                    </h2>
                    <Consents
                        items={consents}
                        consentsWhitelist={emailConsentsWhitelist}
                    />
                </Container>
            ) : null}
            {!userIsCorporate && isSmsVisible ? (
                <Container
                    background={Background.Gray}
                    padding={Padding.Reduced}
                    content={Content.Padding}
                >
                    <h2 className="user-consents__section-title">
                        SMS
                    </h2>
                    <Consents
                        items={consents}
                        consentsWhitelist={smsConsentsWhitelist}
                    />
                </Container>
            ) : null}
            {!isExternalLinkHidden ? (
                <Container>
                    <ButtonGroup
                        direction="vertical"
                        alignItems="stretch"
                    >
                        <AppSafeLink
                            text="Se alle kommunikasjonsvalg på trumf.no"
                            appHref="https://www.trumf.no/profil/"
                            isCancel
                            iconRight={arrowExternal}
                        />
                    </ButtonGroup>
                </Container>
            ) : null}
        </>
    )
}

export default UserConsents
