import "./style/container.scss"

import classNames from "classnames"
import React from "react"

import AppLink from "./AppLink"

export const Padding = {
    None: "none",
    Normal: "normal",
    Reduced: "reduced",
} as const

export type PaddingEnum = typeof Padding[keyof typeof Padding];

export const Content = {
    FullWidth: "full-width",
    Normal: "normal",
    Padding: "padding",
} as const

export type ContentEnum = typeof Content[keyof typeof Content];

export const Background = {
    None: "none",
    Normal: "normal",
    Gray: "gray",
} as const

export type BackgroundEnum = typeof Background[keyof typeof Background];

interface ContainerProps {
    className?: string
    background?: BackgroundEnum
    padding?: PaddingEnum
    content?: ContentEnum
    isKeyboard?: boolean
    isInverse?: boolean
    style?: React.CSSProperties
    children?: React.ReactNode
    title?: string
    linkText?: string
    headerIconButton?: React.ReactNode
    isLoading?: boolean
    to?: string | null
    from?: string
    state?: object
}

const Container = ({
    className,
    title = "",
    to = null,
    linkText,
    headerIconButton,
    isInverse = false,
    background = Background.Normal,
    padding = Padding.Normal,
    content = Content.Normal,
    isKeyboard = false,
    style,
    children = null,
    isLoading = false,
    from,
    state,
}: ContainerProps) => {
    const elementClassNames = classNames(
        className,
        "container",
        {
            "container--inverse": isInverse,
            "container--no-padding": padding === Padding.None,
            "container--reduced-padding": padding === Padding.Reduced,
            "container--keyboard": isKeyboard,
            "container--is-loading": isLoading,
        },
    )

    const contentClassNames = classNames(
        "container__content",
        `container__content--background-${background}`,
        {
            "container__content--normal": content === Content.Normal,
            "container__content--full-width": content === Content.FullWidth,
            "container__content--padding": content === Content.Padding,
        },
    )

    return (
        <div
            className={elementClassNames}
            style={style}
        >
            {(title || (to && linkText) || headerIconButton) ? (
                <div className="container__header">
                    {title ? (
                        <h3 className="container__title">
                            {title}
                        </h3>
                    ) : null}
                    {(to && linkText) ? (
                        <AppLink
                            className="container__more-link"
                            to={to}
                            from={from}
                            skin="link"
                            state={state}
                        >
                            {linkText}
                        </AppLink>
                    ) : null}
                    {headerIconButton ? (
                        <div>
                            {headerIconButton}
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className={contentClassNames}>
                {children}
            </div>
        </div>
    )
}

export default Container
