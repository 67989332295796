import "./style/horizontal-scroller.scss"

import React from "react"
import classNames from "classnames"

interface HorizontalScrollerElementProps {
    single?: boolean
    small?: boolean
    children?: React.ReactNode
}

const HorizontalScrollerElement = ({
    children,
    single,
    small,
}: HorizontalScrollerElementProps) => {
    return (
        <li
            className={classNames("horizontal-scroller__element ", {
                "horizontal-scroller__element--single": single,
                "horizontal-scroller__element--small": small,
            })}
        >
            {children}
        </li>
    )
}

interface HorizontalScrollerProps {
    singleItemFillter?: boolean
    children?: any
    small?: boolean
    height?: number
}

const HorizontalScroller = ({
    singleItemFillter,
    children,
    small,
    height = 380,
}: HorizontalScrollerProps) => {
    const elements = React.Children.map(
        children,
        (i, index) => {
            return (
                <HorizontalScrollerElement
                    single={singleItemFillter && children.length === 1}
                    small={small}
                    key={index}
                >
                    {React.cloneElement(i)}
                </HorizontalScrollerElement>
            )
        },
    )

    if (!children.length) {
        //return null
    }

    return (
        <div
            className="horizontal-scroller"
            style={{ height }}
            onScroll={e => e.stopPropagation()}
        >
            <ul className="horizontal-scroller__wrapper">
                {children && children.length ? elements : null}
            </ul>
        </div>
    )
}

export default HorizontalScroller
