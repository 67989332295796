import "./style/home-footer.scss"

import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import flashIcon from "@resources/icons/flash"
import menyMerLogo from "@resources/icons/menyMerLogo"

// Components
import InlineLoginButtons from "@modules/auth/InlineLoginButtons"
import Icon from "@ng-mw/reol/components/Icon"

const HomeFooter = () => {
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)

    return (
        <div className="app-home-footer">
            {!userIsLoggedIn ? (
                <>
                    <Icon
                        className="app-home-footer__illustration"
                        icon={flashIcon}
                        size={{ width: "100px", height: "30px" }}
                    />
                    <p className="app-home-footer__text">Oj! Du er ikke logget inn.</p>
                    <InlineLoginButtons />
                </>
            ) :
                (
                    <>
                        {!userIsCorporate ?
                            <Icon
                                className="app-home-footer__illustration"
                                icon={menyMerLogo}
                                size={{ width: "84px", height: "78px" }}
                            /> : null
                        }
                        <p className="app-home-footer__text">Takk for at du handler hos oss!</p>
                    </>
                )}
        </div>
    )
}

export default HomeFooter
