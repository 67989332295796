export default {
    Home: "/",

    // User
    UserProfile: "/user",
    PurchaseHistory: "/user/purchases",
    PurchaseComplaint: "/user/purchases/complaint",
    Help: "/user/help",
    ContactDetails: "/user/contact-details",
    TrumfProfile: "/user/settings/trumf-profile",
    TrumfMember: "/user/settings/trumf-profile/personopplysninger",
    TrumfBankCards: "/user/settings/trumf-profile/kort-konto",
    TrumfTrumfCards: "/user/settings/trumf-profile/trumf-kort",
    TrumfChainConsents: "/user/settings/trumf-profile/samtykker",
    TrumfAssociates: "/user/settings/trumf-profile/felles-medlemskap",
    UserManageBiometrics: "/user/settings/manage-biometrics",
    NotificationSettings: "/user/settings/notifications",
    OrderItem: "/user/orders/:id",
    OrderHistory: "/user/orders",
    CustomerServicePage: "/user/customer-service",
    UseBonus: "/user/use-bonus",
    UseBonusToCharity: "/user/use-bonus-charity",
    UseBonusToBank: "/user/use-bonus-bank",

    // Products
    Search: "/search",
    Products: "/products",
    ProductPage: "/product",
    Category: "/products/:category1/:category2?/:category3?/:category4?",

    // Cart and Checkout
    Cart: "/cart",
    Checkout: "/cart/checkout",

    // Savings
    BargainOffers: "/savings/bargain-offers",
    GenericOffers: "/savings/generic-offers",
    Campaign: "/savings/campaigns/:id",

    // Recipes
    RecipesHome: "/recipes",
    // RecipePage: "/recipes/recipe/:recipeId",
    // StoredRecipes: "/recipes/stored-recipes",

    // Shopping Lists
    ShoppingListsHome: "/shopping-lists",
    ShoppingListPage: "/shopping-lists/:id",

    // Diet Insight
    DietHome: "/insight",
    HealthInspiration: "/insight/healthy",
    DietOverview: "/insight/diet/overview",
    DietMacroNutrients: "/insight/diet/macro",
    DietLevelOne: "/insight/diet/level-one",
    DietLevelTwo: "/insight/diet/level-two",
    DietLevelThree: "/insight/diet/level-three",
    DietLevelFour: "/insight/diet/level-four",

    // Climate Insight
    ClimateHome: "/insight/climate",
    ClimateInspiration: "/insight/climate/inspire",
    ClimateProductGroups: "/insight/climate/product-groups",
    ClimateLevelTwo: "/insight/climate/level-two",
    ClimateLevelThree: "/insight/climate/level-three",

    // Debug
    Debug: "/debug",
    AccessibilityPlayground: "/accessibility-playground",
    ComponentPlayground: "/component-playground",
    ChatPage: "/chat-page",

    // Loyalty Club
    LoyaltyClub: "/loyalty-club",
    Vouchers: "/loyalty-club/vouchers",
    Savings: "/loyalty-club/savings",

}
