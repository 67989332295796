import "./style/shopping-list-link.scss"

import React from "react"
import chevronRightIcon from "@ng-mw/reol/icons/chevronRight"
import { shoppingListsHelpers } from "@ng-mw/shared-react-components/shopping-lists"
import { CartItem } from "@app/types"

// Components
import NgrIcon from "@ng-mw/reol/components/Icon"
import { ProductImage } from "@ng-mw/shared-react-components/products"
import AppLink from "@modules/shared/AppLink"

export interface ShoppingListProps {
    from?: string
    shoppingList?: {
        id: string
        name: string
        items: any[]
    }
}

const ShoppingListLink = ({
    shoppingList,
    from,
}: ShoppingListProps) => {
    if (!shoppingList) return

    const { cartItems, totalQuantity } = shoppingListsHelpers.getItemsToAddInfo(shoppingList.items, true)

    return (
        <AppLink
            state={{ id: shoppingList.id, shoppingList }}
            to={`/shopping-lists/${shoppingList.id}`}
            className="app-shopping-list-link"
            from={from}
        >
            <div>
                <div className="app-shopping-list-link__name">
                    {shoppingList.name}
                </div>
                <div className="app-shopping-list-link__quantity">
                    {totalQuantity} varer
                </div>
            </div>
            {cartItems ? (
                <div className="app-shopping-list-link__product-images">
                    {cartItems.slice(0, 2).map((item: CartItem) => (
                        <div
                            key={item.product.ean}
                            className="app-shopping-list-link__product-image"
                        >
                            <ProductImage
                                product={item.product}
                                size="small"
                            />
                        </div>
                    ))}
                </div>
            ) : null}
            <NgrIcon
                className="app-shopping-list-link__arrow"
                icon={chevronRightIcon}
            />
        </AppLink>
    )
}

export default ShoppingListLink
