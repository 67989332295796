import "./style/first-trumf-id.scss"
import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import Button from "@ng-mw/reol/components/Button"
import { LoginButton } from "@ng-mw/shared-react-components/shared"
import Icon from "@ng-mw/reol/components/Icon"
import trumfIcon from "@ng-mw/reol/icons/trumf"
import flashIcon from "@resources/icons/flash"

import PopupContent from "@modules/popup/PopupContent"
import PopupText from "@modules/popup/PopupText"
import PopupButtons from "@modules/popup/PopupButtons"
import PopupTitle from "@modules/popup/PopupTitle"

interface FirstTrumfIdProps {
    onClose: () => void
}

const FirstTrumfId = ({ onClose }: FirstTrumfIdProps) => {
    const userLoginLevel = useSelector((state: RootState) => state.user.loginLevel)

    const illustration = (
        <div className="first-trumf-id__iconbox">

            <Icon
                icon={flashIcon}
                className="first-trumf-id__flash"
                size={{ height: "26px", width: "100%" }}
            />
            <Icon
                icon={trumfIcon}
                className="first-trumf-id__trumflogo"
                size={{ height: "40px", width: "100%" }}
            />
        </div>
    )

    return (
        <>
            <PopupContent>

                <PopupTitle illustration={illustration}>
                    Nå har innlogging blitt enda enklere!
                </PopupTitle>
                <PopupText>
                    <p>
                        Nå kan du logge inn raskere på Meny-appen med biometri.
                    </p>
                    <p>
                        Ved å logge inn med biometri brukes ansiktsgjenkjenning eller fingeravtrykk til å identifisere deg som Trumf-medlem,
                        og du slipper å skrive inn passordet neste gang du skal logge inn!
                    </p>
                </PopupText>
            </PopupContent>
            <PopupButtons isSimple>
                {userLoginLevel > 0 ? (
                    <Button
                        text="Ok"

                        onClick={onClose}
                    />
                ) : (
                    <>
                        <LoginButton
                            onSuccess={onClose}
                        />
                        <Button
                            text="Ikke nå"
                            isCancel={true}
                            onClick={onClose}
                        />
                    </>
                )}
            </PopupButtons>

        </>
    )
}

export default FirstTrumfId
