import "./style/feedback-form.scss"

import React, { useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import leaf4Icon from "@resources/icons/leaf4"

// Components
import Button from "@ng-mw/reol/components/Button"
import Icon from "@ng-mw/reol/components/Icon"
import PopupButtons from "@modules/popup/PopupButtons"
import PopupContent from "@modules/popup/PopupContent"
import PopupText from "@modules/popup/PopupText"
import PopupTitle from "@modules/popup/PopupTitle"

interface FeedbackFormProps {
    onClose: () => void
}

const FeedbackForm = ({
    onClose,
}: FeedbackFormProps) => {
    const data = useSelector((state: RootState) => state.app.feedbackPopup.data)
    const [step, setStep] = useState(0)

    if (!data) return null

    const { isThumbsUp, url, thumbsUpResponse, thumbsDownResponse, skipConsentStep } = data

    return (
        <>
            {step > 1 ? null : (
                <>
                    <img
                        className="feedback-form__image"
                        src="https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_800,h_444/r2dcv8nublhnclxhbkey"
                        aria-hidden="true"
                        alt=""
                    />
                    <PopupTitle
                        illustration={(
                            <Icon
                                icon={leaf4Icon}
                                size={{ width: "128px", height: "40px" }}
                                isInline
                            />
                        )}
                    >
                        {step > 0 ? "Si din mening" : "Takk for din tilbakemelding!"}
                    </PopupTitle>
                </>
            )}
            <PopupContent>
                {step === 0 ? (
                    <PopupText>
                        <p>
                            {isThumbsUp ? thumbsUpResponse : thumbsDownResponse}
                        </p>
                    </PopupText>
                ) : null}
                {step === 1 ? (
                    <PopupText>
                        <p>
                            Alle svar lagres anonymisert og vil bare analyseres på aggregert nivå. Svarene slettes etter maks 6 måneder. Vi oppfordrer til å ikke skrive inn noen personopplysninger i svarene.
                        </p>
                        <p>
                            Spørreundersøkelsen gjennomføres for MENY med Trumf og Questback som databehandlere, og forholdet er regulert i en databehandleravtale.
                        </p>
                        <p>
                            Du kan lese mer om behandling av personopplysninger i vår personvernerklæring.
                        </p>
                    </PopupText>
                ) : null}
                {step > 1 ? (
                    <iframe
                        className="feedback-form__iframe"
                        src={url}
                    />
                ) : null}
            </PopupContent>
            {step === 0 ? (
                <PopupButtons>
                    <Button
                        text="Si din mening"
                        onClick={() => setStep(skipConsentStep ? 2 : 1)}
                    />
                </PopupButtons>
            ) : null}
            {step === 1 ? (
                <PopupButtons>
                    <Button
                        text="Jeg vil delta"
                        onClick={() => setStep(2)}
                    />
                    <Button
                        text="Avbryt"
                        isCancel
                        onClick={onClose}
                    />
                </PopupButtons>
            ) : null}
        </>
    )
}

export default FeedbackForm
