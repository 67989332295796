import "../style/app-news.scss"

import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { AppNews } from "../loaders"

export interface AppNewsData {
    title: string
    text: string
    localStorageKey: string
    cloudinaryId: string
    linkPath?: string
    validFrom?: Date //date
}

const NewsPopup = () => {
    const { visible, data } = useSelector((state: RootState) => state.app.newsPopup)

    return (
        <Popup
            isOpen={visible}
            isSwipeToCloseDisabled
        >
            <LazyRender
                isVisible={visible}
                className="app-news"
            >
                <AppNews
                    data={data}
                />
            </LazyRender>
        </Popup>
    )
}

export default NewsPopup
