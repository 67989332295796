export const QrAction = {
    QrLoading: "qr:loading",
    QrLoaded: "qr:loaded",
    QrError: "qr:error",
    QrPayError: "qr:payError",
    QrSuccess: "qr:success",
    QrClear: "qr:clear",
}

export const qrLoading = () => {
    return {
        type: QrAction.QrLoading,
    }
}

export const qrLoaded = () => {
    return {
        type: QrAction.QrLoaded,
    }
}

export const qrError = (payload: any) => {
    return {
        type: QrAction.QrError,
        payload,
    }
}

export const qrSuccess = (payload: any) => {
    return {
        type: QrAction.QrSuccess,
        payload,
    }
}

export const qrClear = () => {
    return {
        type: QrAction.QrClear,
    }
}
