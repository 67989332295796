import "../style/checkout-payment-popup.scss"

import classNames from "classnames"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { App } from "@ng-mw/shared-react-components"
import { hideCheckoutPaymentPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"

const CheckoutPaymentPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible: isOpen, paymentUrl } = useSelector((state: RootState) => state.app.checkoutPaymentPopup)

    const handleClose = () => dispatch(hideCheckoutPaymentPopup())

    const os = App.Context.Android ? "android" : "ios"

    return (
        <Popup
            onClose={handleClose}
            isOpen={isOpen}
            title="Betaling"
        >
            {paymentUrl ? (
                <div className={classNames("box checkout-payment-popup__box", `checkout-payment-popup__box--${os}`)}>
                    <div className={classNames("box checkout-payment-popup__wrapper", `checkout-payment-popup__wrapper--${os}`)}>
                        <iframe
                            title="Betalingsterminal"
                            className={classNames("checkout-payment-popup", `checkout-payment-popup--${os}`)}
                            scrolling="auto"
                            src={paymentUrl}
                        />
                    </div>
                </div>
            ) : null}
        </Popup>
    )
}

export default CheckoutPaymentPopup
