import "./style/icon-button.scss"

import React from "react"
import classNames from "classnames"
import { GtmData, IconResource } from "@app/types"

//components
import NgrIcon from "@ng-mw/reol/components/Icon"

export interface IconButtonProps {
    className?: string
    icon: IconResource
    onClick: any
    title: string
    ariaLabel: string
    gtmData: GtmData
    isSecondary?: boolean
    isCircle?: boolean
}

const IconButton = ({
    onClick = () => undefined,
    className,
    icon,
    title,
    ariaLabel,
    gtmData,
    isSecondary,
    isCircle,
}: IconButtonProps) => {

    const buttonClass = classNames(
        "icon-button",
        className,
        {
            "icon-button--secondary": isSecondary,
            "icon-button--is-circle": isCircle,
        },
    )

    return (
        <button
            className={buttonClass}
            onClick={onClick}
            title={title}
            aria-label={ariaLabel}
            data-gtm-click-name={gtmData.name}
            data-gtm-click-value={gtmData.value}
        >
            <NgrIcon
                icon={icon}
                size={{ width: "24px", height: "24px" }}
            />
        </button>
    )
}

export default IconButton
