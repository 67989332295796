import { NativeEvent, NativeEventBridge, scrollContainerTo } from "@ng-mw/shared-react-components"
import React, { useEffect, useRef } from "react"

interface ScrollToTopLinkProps {
    children?: React.ReactNode
}

const ScrollToTopLink = ({
    children,
}: ScrollToTopLinkProps) => {
    const eventId = useRef(null)

    useEffect(() => {
        eventId.current = NativeEventBridge.listen(NativeEvent.StatusBarClicked, () => onClick(), false)

        return () => {
            NativeEventBridge.unlisten(eventId.current)
        }
    }, [])

    const onClick = () => {
        try {
            const scrollContainer = document.querySelectorAll(".app-scroll-view__inner")[0]

            scrollContainerTo({
                scrollContainer,
                to: 0,
                duration: 300,
            })
        } catch (error) {
            // nothing to do here
        }
    }

    return (
        /* eslint-disable-next-line */
        <div
            className="scroll-to-top-link"
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default ScrollToTopLink
