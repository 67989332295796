import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideHandoverPickerPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import { HandoverPickerTitle, HandoverPickerButtons } from "@ng-mw/shared-react-components/lazy-loaders"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { HandoverPicker } from "../loaders"

const HandoverPickerPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.handoverPickerPopup.visible)

    const handleClose = () => dispatch(hideHandoverPickerPopup())

    return (
        <Popup
            isOpen={isOpen}
            onClose={handleClose}
            title={<HandoverPickerTitle />}
            buttons={<HandoverPickerButtons />}
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <HandoverPicker />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default HandoverPickerPopup
