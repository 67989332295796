import { createStore, applyMiddleware, compose } from "redux"
import thunkMiddleware from "redux-thunk"
import { createLogger } from "redux-logger"
import reduxPromise from "redux-promise"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./all-reducers"
import trackingMiddleware from "./tracking-middleware"
import rootSaga from "./all-sagas"

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

const makeStore = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const sagaMiddleware = createSagaMiddleware()

    const middlewares = [
        sagaMiddleware,
        thunkMiddleware,
        reduxPromise,
        trackingMiddleware,
        createLogger({ collapsed: true }),
    ]

    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(...middlewares),
        ),
    )

    sagaMiddleware.run(rootSaga)

    return store
}

export default makeStore
