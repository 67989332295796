import { shoppingListsActions } from "@ng-mw/shared-react-components/shopping-lists"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { RootState } from "@app/types"

// Components
import { hideShoppingListChangeNamePopup } from "@app/store/app/appActions"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import InputGroup from "@ng-mw/reol/components/InputGroup"
import Button from "@ng-mw/reol/components/Button"
import Popup, { PopupButtons, PopupContent } from "@modules/popup/Popup"

const ShoppingListChangeNamePopup = () => {
    const dispatch = useDispatch<any>()
    const location = useLocation() as any
    const [shoppingListName, setShoppingListName] = useState("")
    const isOpen = useSelector((state: RootState) => state.app.changeNameShoppingListPopup.visible)

    const { data } = useSelector((state: RootState) => state.shoppingLists)

    const shoppingList = data?.find(item => item.id === location.state?.shoppingList?.id)

    useEffect(() => {
        if (shoppingList?.name) {
            setShoppingListName(shoppingList.name)
        }

    }, [shoppingList])

    const hidePopup = () => dispatch(hideShoppingListChangeNamePopup())

    const handleStoreUpdate = () => {
        dispatch(shoppingListsActions.updateShoppingListStore({
            ...shoppingList,
            name: shoppingListName,
            items: shoppingList.items,
        }))
        hidePopup()
    }

    return (
        <Popup
            isOpen={isOpen && !!shoppingList}
            onClose={hidePopup}
            title="Endre navn"
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className=""
            >
                <PopupContent>
                    <InputGroup
                        id="ChangeName"
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShoppingListName(e.target.value)}
                        value={shoppingListName}
                        placeholder="F.eks. Mine favoritter"
                        autoComplete="off"
                        hideLabel
                        label="Nytt navn på liste"
                    />
                </PopupContent>
                <PopupButtons isSimple>
                    <Button
                        onClick={handleStoreUpdate}
                        text="Lagre"
                    />
                    <Button
                        onClick={hidePopup}
                        text="Avbryt"
                        isSecondary
                    />
                </PopupButtons>
            </LazyRender>
        </Popup>
    )
}

export default ShoppingListChangeNamePopup
