import classNames from "classnames"
import "./style/popup-text.scss"

import React from "react"

interface PopupTextProps {
    children: React.ReactNode
    className?: string
}

export const PopupText = ({
    children,
    className,
}: PopupTextProps) => {
    return (
        <div className={classNames("app-popup-text", className)}>
            {children}
        </div>
    )
}

export default PopupText
