import React from "react"
import Loadable from "react-loadable"

export const Julelotteri = Loadable({
    loader: () => import( /* webpackChunkName: "christmas" */ "./Julelotteri"),
    loading: () => <div />,
})

export const Kronelotteri = Loadable({
    loader: () => import( /* webpackChunkName: "kronelotteri" */ "./Kronelotteri"),
    loading: () => <div />,
})
