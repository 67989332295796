import { Logger, Cacher } from "@ng-mw/shared-react-components"
import { useState, useEffect, useCallback } from "react"
import { performRESTRequest } from "@ng-mw/framework-core"

export const getFullLocalUrl = (absolutePath: string) => {
    const params = new URLSearchParams(document.location.search.substring(1))
    const env = params.get("env")

    if (env === "2") {
        return `https://preprod.meny.no${absolutePath}`
        // return `https://preprodmeny-feature3.azurewebsites.net${absolutePath}`

    } else if (env === "3") {
        return `https://meny.no${absolutePath}`
    }
    return `https://${window.location.host}${absolutePath}`
}

const CACHE_KEY = "getAppcontent"

const useAppContent = (initialAppContentId, initialData) => {
    const [data, setData] = useState<any>(initialData)
    const [appContentId, setAppContentId] = useState(initialAppContentId)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const fetchData = useCallback(async appContentId => {
        setIsError(false)
        setIsLoading(true)
        const predicate = {
            appContentId,
        }
        try {
            const cached = await Cacher.get(CACHE_KEY, predicate)
            if (cached) {
                setData(cached)
            } else {
                const data = await performRESTRequest({
                    url: getFullLocalUrl(appContentId ? `/api/appcontent/${appContentId}` : "/api/appcontent"),
                    method: "GET",
                })
                setData(data)
                Cacher.cache(CACHE_KEY, predicate, data)
            }

        } catch (error) {
            Logger.error("app/store/useAppContent", predicate, error)
            setIsError(true)
        }
        setIsLoading(false)
    }, [])

    const reload = useCallback(() => {
        Cacher.empty(CACHE_KEY)
        fetchData(appContentId)
    }, [appContentId, fetchData])

    useEffect(() => {
        fetchData(appContentId)
    }, [fetchData, appContentId])

    return [{ data, isLoading, isError }, setAppContentId, reload]
}

export default useAppContent
