import { GenericAction } from "@app/types"
import { HistoryAction } from "../actions/historyActions"

const INITIAL_STATE = {
    complaintProduct: null,
}

export default (state = INITIAL_STATE, action: GenericAction) => {
    switch (action.type) {
        case HistoryAction.SetComplaintProduct: {
            return {
                ...state,
                complaintProduct: action.payload,
            }
        }
        default:
            return state
    }
}
