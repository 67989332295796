import "./style/app-link.scss"

import React, { CSSProperties } from "react"
import classNames from "classnames"
import { useHistory, useLocation } from "react-router-dom"
import { saveScrollOffset } from "@app/helpers"

import { UniversalLink } from "@ng-mw/shared-react-components/shared"

export type AppLinkSkin = "none" | "button" | "link"

interface AppLinkProps {
    children: React.ReactNode
    className?: string
    from?: string
    instant?: boolean
    onClick?: () => void
    onTouchEnd?: (e: React.TouchEvent) => void
    onTouchMove?: () => void
    onTouchStart?: () => void
    pseudoHistory?: string[]
    replace?: boolean
    skin?: AppLinkSkin
    state?: object
    style?: CSSProperties
    to?: string
    url?: string
}

const AppLink = ({
    children,
    className,
    from,
    instant,
    onClick = () => undefined,
    onTouchEnd = () => undefined,
    onTouchMove = () => undefined,
    onTouchStart = () => undefined,
    pseudoHistory,
    replace = false,
    skin = "none",
    state,
    style,
    to,
    url,
}: AppLinkProps) => {
    const history = useHistory()
    const location = useLocation()

    const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (onClick) {
            onClick()
        }

        if (location.pathname !== to && to !== "") {
            e.preventDefault()
            e.stopPropagation()

            saveScrollOffset(history, location)

            if (pseudoHistory) {
                for (let i = 0; i < pseudoHistory.length; i++) {
                    history.push({ pathname: pseudoHistory[i], state: {} })
                }
            }
            history[replace ? "replace" : "push"]({
                pathname: to,
                state: { ...state, from, isImmediate: instant },
            })
        }
    }

    const elementClassName = classNames("app-link", `app-link--skin-${skin}`, className)

    if (url) {
        return (
            <UniversalLink
                className={elementClassName}
                destinationUrl={url}
            >
                {children}
            </UniversalLink>
        )
    }

    return (
        <span
            className={elementClassName}
            role="link"
            tabIndex={0}
            aria-current={location.pathname === to ? true : false}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            onClick={handleClick}
            onKeyDown={(e: React.KeyboardEvent) => e.key === "Enter" && handleClick(e)}
            style={style}
        >
            {children}
        </span>
    )
}

export default AppLink
