import * as categoriesActions from "./creators/categories"
import { core } from "@ng-mw/shared-react-components/framework"
import { Logger } from "@ng-mw/shared-react-components"
import { Dispatch } from "redux"
import { RootState } from "@app/types"

export const get = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const storeId = getState().user.data.store.gln
        const categories = getState().categories

        // if store id matches, do not load data again
        if (categories.storeId === storeId) {
            return
        }

        dispatch(categoriesActions.loading())
        dispatch(categoriesActions.setStoreId(storeId))

        try {
            const result = await core.performRESTRequest({
                path: "/api/products/{chainid}/categories",
                method: "GET",
                qs: {
                    store_id: storeId,
                },
            })
            // result.sort(function(a, b) {
            //     return a.sort - b.sort
            // })

            dispatch(categoriesActions.success(result))
        } catch (e) {
            dispatch(categoriesActions.error(e))
            Logger.error("actions/categories/get()", e)
        } finally {
            dispatch(categoriesActions.loaded())
        }
    }
}
