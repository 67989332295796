import "./style/not-found.scss"

import React from "react"

// Components
import AppLayoutTop from "@modules/framework/AppLayoutTop"
import AppLink from "./AppLink"

interface NotFoundProps {
    homeUrl: string
    homeUrlTitle?: string
    currentUrl: string
}

const NotFound = ({
    homeUrl,
    homeUrlTitle = "Tilbake til start",
    currentUrl,
}: NotFoundProps) => {
    return (
        <AppLayoutTop>
            <div className="not-found">
                <div className="not-found__box">
                    <h1 className="not-found__title">
                        Ojsann
                    </h1>
                    <p className="not-found__description">
                        Vi finner visst ikke siden du ba om.
                    </p>
                    <div className="not-found__go-home-link">
                        <AppLink
                            className="ngr-button"
                            to={homeUrl}
                        >
                            {homeUrlTitle}
                        </AppLink>
                    </div>
                </div>
                <div className="not-found__current-url">
                    {currentUrl}
                </div>
            </div>
        </AppLayoutTop>
    )
}

export default NotFound
