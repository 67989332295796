import "./style/home-footer.scss"

import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"
import { useLoyaltyClubStatus } from "@modules/loyalty-club/helpers"
import PathNames from "@app/const/PathNames"

import bonusProductsIcon from "@resources/icons/bonusProducts"
import couponsIcon from "@resources/icons/coupons"
import coins2 from "@resources/icons/coins2"

// Components
import Container from "@modules/shared/Container"
import { SavingsSummary } from "@ng-mw/shared-react-components/savings"
import { useDefaultPeriod } from "@ng-mw/shared-react-components/src/modules/savings/helpers"
import LoyaltyClubBonusCampaign from "@modules/loyalty-club/LoyaltyClubBonusCampaign"
import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"
import Icon from "@ng-mw/reol/components/Icon"
import AppLink from "@modules/shared/AppLink"
import TrumfLink from "./TrumfLink"
import BecomeLoyaltyClubMemberPoster from "@modules/loyalty-club/BecomeLoyaltyClubMemberPoster"
import Feedback from "@modules/shared/Feedback"

const LoyaltyClubSection = () => {
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)
    const userIsOrganization = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const isLoyaltyClubMember = useLoyaltyClubStatus()

    const thresholds = {
        amountThreshold: 50,
        amountThresholdThirtyDays: 200,
        percentageThreshold: 8,
        percentageThresholdThirtyDays: 8,
    }

    const defaultPeriod = useDefaultPeriod(thresholds)
    const initialPeriod = defaultPeriod ? defaultPeriod.period : "lastYear"

    const getPeriodTab = (initialPeriod: string) => {
        switch (initialPeriod) {
            case "lastYear":
                return 2
            case "lastThirtyDays":
                return 1
            case "lastTransaction":
                return 0
            default:
                return 2
        }
    }

    return (
        isLoyaltyClubMember && userIsLoggedIn && !userIsOrganization ? (
            <>
                <Container
                    title="Penger spart"
                    to={PathNames.Savings}
                    state={{ tab: getPeriodTab(initialPeriod) }}
                    linkText="Se mer"
                    from="Besparelser link. Home page"
                    className="savings-summary"
                >
                    <SavingsSummary
                        amountThreshold={thresholds.amountThreshold}
                        amountThresholdThirtyDays={thresholds.amountThresholdThirtyDays}
                        percentageThreshold={thresholds.percentageThreshold}
                        percentageThresholdThirtyDays={thresholds.percentageThresholdThirtyDays}
                        illustrationWidth={100}
                        illustrationOffsetRight={0}
                        illustration={
                            <Icon
                                className="savings-summary__illustration"
                                icon={coins2}
                                size={{ width: "124px", height: "124px" }}
                            />
                        }
                        illustrationAltText="Illustrasjon av en prislapp"
                        noTransactionsTitle="Lyst til å spare mer?"
                        noTransactionsDescription="Neste gang du handler hos Meny vil du kunne se hvor mye du har spart her!"
                        cardType="secondary"
                        className="savings-summary__card"
                        isFooterHidden
                    />
                </Container>
                <Container
                    title="Dine MENY MER-fordeler"
                >
                    <LoyaltyClubBonusCampaign isOnlyVisibleIfActive />
                    <ButtonGroup
                        direction="horizontal"
                        spacing="large"
                    >
                        <AppLink
                            to={PathNames.LoyaltyClub}
                            skin="button"
                            from="Bonusprodukter button. Home page"
                        >
                            <Icon
                                icon={bonusProductsIcon}
                                size={{ width: "85px", height: "85px" }}
                            />
                            Bonusprodukter
                        </AppLink>
                        <AppLink
                            to={PathNames.Vouchers}
                            skin="button"
                            from="Kuponger button. Home page"
                        >
                            <Icon
                                icon={couponsIcon}
                                size={{ width: "85px", height: "85px" }}
                            />
                            Kuponger
                        </AppLink>
                    </ButtonGroup>
                    {!userIsOrganization ? (
                        <TrumfLink
                            from="TrumfLink. Home page"
                        />
                    ) : null}
                    <Feedback
                        id="loyalty-feedback"
                        title="Hva synes du om MENY MER så langt?"
                        description="Vi ønsker å forbedre kundeklubben vår og vil gjerne høre din mening!"
                        url="https://response.questback.com/trumfas/menymer"
                        thumbsUpResponse="Så hyggelig! Vi vil gjerne stille noen flere spørsmål for å høre hvorfor du liker MENY MER og hvordan vi kan gjøre kundeklubben vår enda bedre!"
                        thumbsDownResponse="Det var synd. Vi vil gjerne stille noen flere spørsmål for å høre hvorfor du ikke liker MENY MER og hvordan vi kan gjøre kundeklubben vår bedre!"
                        background="light-red"
                        isCloseButtonVisible={true}
                    />
                </Container>
            </>
        )
            :
            !userIsOrganization ? (
                <BecomeLoyaltyClubMemberPoster />
            ) : null
    )
}

export default LoyaltyClubSection
