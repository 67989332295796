import "../../common/styles/insight-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideClimateComparisonPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import { ClimateComparisonSource } from "@ng-mw/shared-react-components/insight-lazy"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import ChatBubble from "@ng-mw/shared-meny-components/src/modules/insight/ChatBubble"

const ClimateComparisonPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible: isOpen, data } = useSelector((state: RootState) => state.app.climateComparisonPopup)

    return (
        <Popup
            onClose={() => dispatch(hideClimateComparisonPopup())}
            isOpen={isOpen}
            className="insight-popup"
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <ClimateComparisonSource
                        data={data}
                        CardComponent={ChatBubble}
                    />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default ClimateComparisonPopup
