import "./style/scroll-view.scss"

import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"

import { scrollPositionChange } from "@app/store/app/appActions"
import { RootState } from "@app/types"

export interface ScrollViewProps {
    allowOverscrollBottom?: boolean
    allowOverscrollTop?: boolean
    children?: ReactNode
    className?: string
    id?: string
    onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
    onScrollOverflowBottom?: (overflow: number, e: React.TouchEvent<HTMLDivElement>) => void
    onScrollOverflowTop?: (overflow: number, e: React.TouchEvent<HTMLDivElement>) => void
    showScrollBar?: boolean
    transparent?: boolean
}

const ScrollView = ({
    allowOverscrollBottom = true,
    allowOverscrollTop = true,
    children,
    className,
    id,
    onScroll = () => undefined,
    onScrollOverflowBottom = () => undefined,
    onScrollOverflowTop = () => undefined,
    showScrollBar = false,
    transparent = false,
}: ScrollViewProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const dispatch = useDispatch<any>()
    const scrollPosition = useSelector((state: RootState) => state.app.scrollPosition)
    const innerRef = useRef<HTMLDivElement>({} as any)
    const [y, setY] = useState(0)

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setY(e.touches[0].clientY)
    }

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = innerRef.current

        const dy = e.touches[0].clientY - y
        const goingDown = dy < 15
        const goingUp = dy > 0
        const atTop = scrollTop <= 15
        const atBottom = scrollHeight - scrollTop <= clientHeight

        if (atBottom && goingDown) {
            onScrollOverflowBottom(Math.abs(dy), e)
        }

        if (atTop) {
            onScrollOverflowTop(Math.abs(dy), e)

            if (!scrollPosition.top) {
                dispatch(scrollPositionChange(true, false, false))
            }
        }

        if (goingDown && !scrollPosition.goingDown && !atTop) {
            dispatch(scrollPositionChange(false, false, true))
        }

        if (!allowOverscrollBottom && atBottom && goingDown) {
            e.preventDefault()
        }

        if (!allowOverscrollTop && atTop && goingUp) {
            e.preventDefault()
        }
    }

    const handleTouchEnd = () => {
        setY(y)
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop } = innerRef.current as unknown as any
        const goingDown = scrollTop > 10
        const atTop = scrollTop <= 15

        onScroll(e)

        if (atTop) {
            if (!scrollPosition.top) {
                dispatch(scrollPositionChange(true, false, false))
            }
        }

        if (goingDown && !scrollPosition.goingDown && !atTop) {
            dispatch(scrollPositionChange(false, false, true))
        }
    }

    useEffect(() => {

        // Reset scroll position on unmount
        return () => {
            dispatch(scrollPositionChange(true, false, false))
        }
    }, [dispatch])

    return (
        <div
            className={
                classNames("app-scroll-view ",
                    className,
                    {
                        "app-scroll-view--transparent": transparent,
                    },
                )
            }
            ref={ref}
        >
            <div
                className={`app-scroll-view__inner ${showScrollBar ? "app-scroll-view__inner--scrollbar" : ""}`}
                id={id}
                onScroll={handleScroll}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
                ref={innerRef}
            >
                {children}
            </div>
        </div>
    )
}
export default React.forwardRef(ScrollView)
