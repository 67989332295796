import "./style/app-layout-header.scss"

import classNames from "classnames"
import React from "react"

interface AppLayoutHeaderProps {
    className?: string
    children?: React.ReactNode
}

export const AppLayoutHeader = ({
    className,
    children,
}: AppLayoutHeaderProps) => {
    return (
        <div className={classNames("app-layout-header", className)}>
            {children}
        </div>
    )
}

export default AppLayoutHeader
