import { LocalStorage } from "@ng-mw/shared-react-components"
import { userHelpers } from "@ng-mw/shared-react-components/user"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import MenyLocalStorage from "@app/MenyLocalStorage"
import { showPushConsentsPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

/**
 * Wrapper for Fk's useLoyaltyClubStatus. Adds logic for showing welcome and push-consents popup.
 */
const useLoyaltyClubStatus = () => {
    const dispatch = useDispatch<any>()
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const isUserLoaded = useSelector((state: RootState) => !!state.user.data.chainId)
    const {
        isConsentsLoading,
        isLoyaltyClubMember,
        loyaltyClubAgreement,
        isConsentsInitialized,
    } = userHelpers.useLoyaltyClubStatus()

    useEffect(() => {
        if (isConsentsInitialized && isLoyaltyClubMember && isUserLoaded && !userIsCorporate) {
            if (!LocalStorage.get(MenyLocalStorage.UserSeenPushPopup)) {
                dispatch(showPushConsentsPopup())
            }
        }
    }, [dispatch, isConsentsInitialized, isLoyaltyClubMember, userIsCorporate, isUserLoaded])

    return {
        isConsentsLoading,
        isLoyaltyClubMember,
        loyaltyClubAgreement,
        isConsentsInitialized,
    }
}

export default useLoyaltyClubStatus
