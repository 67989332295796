import "./style/home.scss"

import React from "react"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { useNativeBuildNumber } from "@app/helpers"
import PathNames from "@app/const/PathNames"
import { CampaignType, RootState } from "@app/types"
import AppGlobalSettings from "@app/AppGlobalSettings"
import * as appAction from "@app/store/app/appActions"

import radish1Icon from "@resources/icons/radish1"
import earthAndThermometerIcon from "@resources/icons/earthAndThermometer"

// Components
import { HandoverPickerTypeAndTime } from "@ng-mw/shared-react-components/handover-picker"
import Container, { Background } from "@modules/shared/Container"
import ScrollView from "@modules/shared/ScrollView"
import CampaignsList from "@modules/savings/CampaignsList"
import { ProductView } from "@ng-mw/shared-react-components/lazy-loaders"
import ShoppingLists from "@modules/shopping-lists/ShoppingLists"
import { OrderStatus } from "@ng-mw/shared-react-components/order-status"
import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"
import Icon from "@ng-mw/reol/components/Icon"
import AppLink from "@modules/shared/AppLink"
import GlobalMaintenanceMessage from "./GlobalMaintenanceMessage"
import EditorialContent from "@modules/editorial-content/EditorialContent"
import ProductListSkeleton from "@modules/products/ProductListSkeleton"
import OrderStatusContainer from "./OrderStatusContainer"
import TopBanner from "./TopBanner"
import HomeFooter from "./HomeFooter"
import WelcomeBox from "@modules/loyalty-club/WelcomeBox"
import RecipesList from "@modules/recipes/RecipesList"
import LoyaltyClubSection from "./LoyaltyClubSection"

const productViewId = "home-popular"

const Home = () => {
    const dispatch = useDispatch<any>()
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const productView = useSelector((state: RootState) => state.productView[productViewId])
    const history = useHistory()
    const { supportsComplaints } = useNativeBuildNumber()
    const popularProductsTitle = userIsLoggedIn ? "Vil du handle dette på nytt?" : "Våre bestselgere"

    const handleCreateComplaint = (batchId: string) => {
        history.push({ pathname: PathNames.PurchaseComplaint, state: { batchId } })
    }

    const dietTitle = "Logg inn for å se din kostholdsinnsikt"
    const dietDescription = "Logg inn med Trumf for å lære mer om dine kostholdsvaner og bli inspirert til å ta sunnere valg!"
    const climateTitle = "Logg inn for å se din klimainnsikt"
    const climateDescription = "Logg inn med Trumf for å lære mer om hvor klimavennlig du handler og bli inspirert til å ta mer klimavennlige valg!"

    const handleShowLoginPopup = (title: string, text: string) => {
        if (userIsLoggedIn) return

        const loginData = {
            title,
            text,
        }
        dispatch(appAction.showLoginPopup(loginData))
    }

    return (
        <ScrollView className="app-home">
            <div className="app-home__handover-picker">
                <HandoverPickerTypeAndTime />
            </div>
            <GlobalMaintenanceMessage />
            {userIsLoggedIn ? (
                <OrderStatus
                    orderItemClassName="app-home__order-status"
                    customerServicePhone={AppGlobalSettings.customerServicePhone}
                    customerSupportUrl="https://form.socialboards.com/meny?categoryId=30255"
                    onCreateComplaint={supportsComplaints ? handleCreateComplaint : null}
                    wrapperComponent={OrderStatusContainer}
                />
            ) : null}
            <WelcomeBox />
            <TopBanner />
            <Container content="full-width">
                <CampaignsList
                    type={userIsCorporate ? CampaignType.Offer : CampaignType.Home}
                />
            </Container>
            <LoyaltyClubSection />
            <Container
                title={userIsLoggedIn ? "Ofte kjøpte varer" : "Våre bestselgere"}
                linkText="Se alle"
                to={PathNames.Search}
                from="Varer link. Home page"
            >
                <ProductListSkeleton
                    isLoading={productView?.loading}
                    productCount={5}
                >
                    <div className="app-home__products-list">
                        <ProductView
                            id={productViewId}
                            showFilter={false}
                            hideCategoriesFilter
                            perPage={4}
                            spinnerless
                            headingless
                            emptyResultMessage={() => (
                                <div className="ws-product-view__empty-result">
                                    <strong>Ojsann</strong>, klarte ikke å laste inn varene akkurat nå.
                                </div>
                            )}
                            list={`Hjem-side: ${popularProductsTitle}`}
                        />
                    </div>
                </ProductListSkeleton>
            </Container>
            <RecipesList
                title="Dine oppskrifter"
                linkText="Se alle"
                to={PathNames.RecipesHome}
                state={{ data: { initialTab: 1 } }}
            />
            <Container
                title="Handlelister"
                linkText="Se alle"
                to={userIsLoggedIn ? PathNames.ShoppingListsHome : null}
                background={Background.Gray}
                from="Handlelister link. Home page"
            >
                <ShoppingLists
                    maxNumberOfLists={3}
                    from="Handlelister. Home page"
                />
            </Container>
            <EditorialContent />
            {!userIsCorporate ?
                <Container
                    title="Innsikt"
                >
                    <ButtonGroup direction="horizontal">
                        <AppLink
                            to={userIsLoggedIn ? PathNames.DietHome : ""}
                            onClick={() => handleShowLoginPopup(dietTitle, dietDescription)}
                            from="Innsikt diet button. Home page"
                            skin="button"
                        >
                            <Icon
                                icon={radish1Icon}
                                size={{ width: "87px", height: "87px" }}
                            />
                            Kosthold
                        </AppLink>
                        <AppLink
                            to={userIsLoggedIn ? PathNames.ClimateHome : ""}
                            onClick={() => handleShowLoginPopup(climateTitle, climateDescription)}
                            from="Innsikt climate button. Home page"
                            skin="button"
                        >
                            <Icon
                                icon={earthAndThermometerIcon}
                                size={{ width: "87px", height: "87px" }}
                            />
                            Klima
                        </AppLink>
                    </ButtonGroup>
                </Container>
                : null
            }
            <HomeFooter />
        </ScrollView>
    )
}

export default Home
