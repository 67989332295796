const getDateParts = (inputDate: Date) => {
    // getFullYear returns a number corresponding to the year of the given date, according to local time.
    // getMonth returns a number, between 0 and 11, representing the month in the given date.
    // getDate returns a number, between 1 and 31, representing the day of the month for the given date.

    const year = inputDate.getFullYear()
    const month = inputDate.getMonth() + 1 // add 1 to make it normal 1 - 12
    const day = inputDate.getDate()

    return { year, month, day }
}

export default getDateParts

