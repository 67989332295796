import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideCouponInfoPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import { CouponsInfo } from "../loaders"

const CouponInfoPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.couponPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideCouponInfoPopup())}
            isOpen={isOpen}
            isSimple
            title="Slik fungerer kuponger"
        >
            <CouponsInfo />
        </Popup>
    )
}

export default CouponInfoPopup
