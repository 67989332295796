import { GenericAction } from "@app/types"
import { CampaignsAction } from "../actions/creators/campaigns"

const init = {
    loading: false,
    data: {
        all: [],
        generic: [],
        bargain: [],
        app_hjem: [],
    },
    error: null,
}

export default (state = init, action: GenericAction) => {
    switch (action.type) {
        case CampaignsAction.FromCache:
        case CampaignsAction.Success:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.campaignType]: action.payload.data,
                },
                error: null,
            }
        case CampaignsAction.Loading:
            return { ...state, loading: true }
        case CampaignsAction.Loaded:
            return { ...state, loading: false }
        case CampaignsAction.Error:
            return { ...state, error: action.payload }
        default:
            return state
    }
}
