import { AboutLoyaltyClub } from "@app/types/root-state/aboutLoyaltyClub"

export const AboutLoyaltyClubAction = {
    Loading: "about-loyalty-club:loading",
    Loaded: "about-loyalty-club:loaded",
    Success: "about-loyalty-club:success",
    FromCache: "about-loyalty-club:from-cache",
    Error: "about-loyalty-club:error",
}

export const success = (data: AboutLoyaltyClub[]) => {
    return {
        type: AboutLoyaltyClubAction.Success,
        payload: data,
    }
}
export const fromCache = (data: AboutLoyaltyClub[]) => {
    return {
        type: AboutLoyaltyClubAction.FromCache,
        payload: data,
    }
}

export const error = (error: any) => {
    return {
        type: AboutLoyaltyClubAction.Error,
        payload: error,
    }
}

export const loading = () => {
    return {
        type: AboutLoyaltyClubAction.Loading,
    }
}

export const loaded = () => {
    return {
        type: AboutLoyaltyClubAction.Loaded,
    }
}
