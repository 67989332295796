import "./style/app-version-status.scss"

import React from "react"
import { useLocation } from "react-router"
import useIsAppLatestVersion from "./helpers/useIsAppLatestVersion"
import { NativeEventBridge } from "@ng-mw/shared-react-components"
import * as tracking from "@app/tracking"
import MenyNativeEvent from "@app/events/MenyNativeEvent"

import refreshIcon from "@resources/icons/refresh"

import Icon from "@ng-mw/reol/components/Icon"
import Button, { ButtonSize } from "@ng-mw/reol/components/Button"

const AppVersionStatus = () => {
    const { isAppLatestVersion } = useIsAppLatestVersion()
    const location = useLocation<any>()
    const { pathname } = location

    const handleReloadApp = () => {
        tracking.updateWebAppClick()
        NativeEventBridge.broadcastNative(MenyNativeEvent.ReloadApp)
    }

    if (isAppLatestVersion || pathname.includes("cart")) {
        return null
    }

    return (
        <div className="app-version-status">
            <div className="app-version-status__box">
                <div className="app-version-status__info">
                    <Icon
                        className="app-version-status__icon"
                        icon={refreshIcon}
                    />
                    <p>Appen trenger en oppdatering</p>
                </div>
                <Button
                    text="Oppdater"
                    size={ButtonSize.Small}
                    onClick={handleReloadApp}
                    isCancel
                />
            </div>
        </div>
    )
}

export default AppVersionStatus
