import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { shoppingListsActions } from "@ng-mw/shared-react-components/shopping-lists"
import { hideShoppingListCreatePopup } from "@app/store/app/appActions"
import { CartItem, RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import InputGroup from "@ng-mw/reol/components/InputGroup"
import Button from "@ng-mw/reol/components/Button"
import Popup, { PopupButtons, PopupContent } from "@modules/popup/Popup"

const ShoppingListCreatePopup = () => {
    const dispatch = useDispatch<any>()
    const [shoppingListName, setShoppingListName] = useState("")
    const isOpen = useSelector((state: RootState) => state.app.createShoppingListPopup.visible)

    const hidePopup = () => dispatch(hideShoppingListCreatePopup())

    const createList = () => {
        if (!shoppingListName) return

        const items: CartItem[] = []

        dispatch(shoppingListsActions.createShoppingList(shoppingListName, items))
        hidePopup()
        setShoppingListName("")
    }

    return (
        <Popup
            isOpen={isOpen}
            onClose={hidePopup}
            title="Lag ny handleliste"
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className=""
            >
                <PopupContent>
                    <InputGroup
                        id="CreateShoppingList"
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShoppingListName(e.target.value)}
                        value={shoppingListName}
                        placeholder="F.eks. Mine favoritter"
                        autoComplete="off"
                        hideLabel
                        label="Navn på liste"
                    />
                </PopupContent>
                <PopupButtons isSimple>
                    <Button
                        onClick={createList}
                        text="Lagre"
                    />
                    <Button
                        onClick={hidePopup}
                        text="Avbryt"
                        isSecondary
                    />
                </PopupButtons>
            </LazyRender>
        </Popup>
    )
}

export default ShoppingListCreatePopup
