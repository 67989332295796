import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { hidePurchaseComplaintPopup } from "@app/store/app/appActions"
import { transactionDetailsActions } from "@ng-mw/shared-react-components/transaction-history"
import { RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Popup, { PopupContent } from "@modules/popup/Popup"
import { TransactionComplaintForm } from "@ng-mw/shared-react-components/component-loaders/transaction-complaint-form"

const PurchaseComplaintPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.purchaseComplaintPopup.visible)
    const complaintProduct = useSelector((state: RootState) => state.history.complaintProduct)
    const batchId = useSelector((state: RootState) => state.transactionHistory.history.activeDetails?.batchId)
    const complaintCodes = useSelector((state: RootState) => state.transactionHistory.complaint.complaintCodes)

    const debugMode = window.location.search.indexOf("debug") >= 0

    const handleClose = (isComplaintSubmitted?: boolean) => {
        dispatch(hidePurchaseComplaintPopup())

        if (isComplaintSubmitted && batchId) {
            dispatch(transactionDetailsActions.getTransactionDetails(batchId))
        }
    }

    return (
        <Popup
            isOpen={isOpen}
            onClose={handleClose}
            title="Reklamasjon"
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    {complaintProduct ? (
                        <TransactionComplaintForm
                            complaintProduct={complaintProduct}
                            complaintCodes={complaintCodes}
                            onClose={handleClose}
                            isModal={false}
                            selectBoxMaxQuantity={debugMode ? 8 : 20}
                        />
                    ) : (<div>...</div>)}
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default PurchaseComplaintPopup
