import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"
import isWithinInterval from "date-fns/isWithinInterval"
import parseISO from "date-fns/parseISO"

import trumfTIcon from "@ng-mw/reol/icons/trumfT"

import Tips from "@modules/shared/Tips"
import Container from "@modules/shared/Container"

const TopBanner = () => {
    const { bannerInfo } = useSelector((state: RootState) => state.settings)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)

    if (!bannerInfo?.bannerType) return null

    const now = new Date(new Date().toDateString())

    const isDateWithinDates = isWithinInterval(
        now,
        {
            start: parseISO(bannerInfo.topBannerFrom),
            end: parseISO(bannerInfo.topBannerTo),
        },
    )

    const icon = bannerInfo.bannerType === "trumf" ? trumfTIcon : null

    const text = bannerInfo.topBannerText ? bannerInfo.topBannerText : "Husk at på torsdag så er det 3% bonus på hele butikken!"

    if (isDateWithinDates && bannerInfo.enableTopBanner && !isOrganization) {
        return (
            <Container>
                <Tips
                    size={{ height: "46px", width: "46px" }}
                    text={text}
                    illustration={icon}
                    illustrationLeftOffset="0"
                />
            </Container>
        )
    }

    return null
}

export default TopBanner
