import * as weekMenuActions from "@ng-mw/shared-meny-components/src/modules/WeekMenu/store/actions/weekmenuActionCreators"
import { StaleWhileRevalidateApiUrls } from "./constants"
import { actions as settingsActions } from "@app/store/settings/settingsActions"
import { updateSavingsTipsStore } from "@modules/loyalty-club/store/actions/savingsTipsActions"
import { updateAboutLoyaltyClubInfoStore } from "@modules/loyalty-club/store/actions/aboutLoyaltyClubActions"

export const StaleWhileRevalidateStateServiceWorkerConfig = [
    {
        apiUrl: StaleWhileRevalidateApiUrls.WeeklyRecipesApi,
        action: (data: any) => weekMenuActions.getWeekMenuSuccess(data),
    },
    {
        apiUrl: StaleWhileRevalidateApiUrls.MobileAppSettingApi,
        action: (data: any) => settingsActions.success(data),
    },
    {
        apiUrl: StaleWhileRevalidateApiUrls.SavingsTipsApi,
        action: (data: any) => updateSavingsTipsStore(data),
    },
    {
        apiUrl: StaleWhileRevalidateApiUrls.AboutLoyaltyClubApi,
        action: (data: any) => updateAboutLoyaltyClubInfoStore(data),
    },
]
