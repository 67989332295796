export const CampaignType = {
    All: "all",
    Bargain: "Knallkjøp",
    Home: "app_hjem",
    LoyaltyClub: "meny_mer",
    Offer: "tilbud",
    LoyaltyClubPri: "MM_pri",
} as const

export type CampaignTypeEnum = typeof CampaignType[keyof typeof CampaignType]
