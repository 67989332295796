import "regenerator-runtime/runtime"

import { DebugLogger, NativeEventBridge, GlobalSettings } from "@ng-mw/shared-react-components"
import inlineSvg from "@ng-mw/reol/utils/inlineSvg"
import { createMemoryHistory } from "history"
import React from "react"
import { Store } from "redux"
import { createRoot } from "react-dom/client"
import authConnectors from "@app/connectors/auth-connectors"
import startupConnectors from "@app/connectors/startup-connectors"
import appConnectors from "@app/connectors/app-connectors"
import historyConnectors from "@app/connectors/history-connectors"
import AppGlobalSettings from "@app/AppGlobalSettings"
import appRatingConnectors from "@app/connectors/app-rating-connectors"
import lifecycleConnectors from "@app/connectors/lifecycle-connectors"
import MenyWebAppEvent from "@app/events/MenyWebAppEvent"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import registerServiceWorker from "@app/service-worker/register"
import makeStore from "@app/store/make-store"
import * as tracking from "@app/tracking"

// oh noes
import "./shame"
// includes overrides
import "./resources/resources"

// Components
import { Provider } from "react-redux"
import { Router } from "react-router"
import AppRoot from "@modules/framework/AppRoot"
import ErrorBoundary from "@modules/framework/ErrorBoundary"
import PathNames from "@app/const/PathNames"
import { RootState } from "@app/types"

// TS fix: Declare performanceTimings on window
declare global {
    interface Window {
        performanceTimings: {
            html: number
            js: number
            splash: number
        }
    }
}

// TS fix: Enable custom props in style
declare module "react" {
    interface CSSProperties {
        [key: `--${string}`]: string | number
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface HTMLAttributes<T> {
        inert?: ""
    }
}

const store: Store<RootState> = makeStore() as any

const history = createMemoryHistory({
    initialEntries: [PathNames.Home],
    initialIndex: 0,
})

if (process.env.MOCK === "true") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { mockConnectors } = require("@app/connectors/mock-connectors")
    mockConnectors(store)
}

startupConnectors(store)

if (AppGlobalSettings.enableTrumfId) {
    authConnectors(store)
}

lifecycleConnectors(store, history)
appConnectors(store)
appRatingConnectors(store)
historyConnectors(store, history)

NativeEventBridge.listen(MenyWebAppEvent.ReactCanMount, () => {
    const container = document.getElementById("app") as HTMLElement
    const root = createRoot(container)

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <Router history={history}>
                    <AppRoot />
                </Router>
            </Provider>
        </ErrorBoundary>,
    )
    window.performanceTimings.splash = performance.now()
    NativeEventBridge.broadcastNative(MenyNativeEvent.LoadingComplete)
    DebugLogger.log("Load-order-debug: LOADING COMPLETE! Time: ", window.performanceTimings.splash, "ms")
    console.timeLog("Load-order-debug-TIMER")
    tracking.startUpMetricsSplash()

    setTimeout(() => {
        DebugLogger.log("Load-order-debug: rendered!")
        console.timeEnd("Load-order-debug-TIMER")
    })
})
DebugLogger.log("Load-order-debug: Listening for MenyWebAppEvent.ReactCanMount")

inlineSvg(GlobalSettings.inlineIconSetUrl, process.env.APP_VERSION)
registerServiceWorker(store)
