import "./style/popup.scss"

import useUniqueId from "@ng-mw/reol/utils/useUniqueId"
import { LifecycleEmitter } from "@ng-mw/shared-react-components"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { usePrevious } from "@app/helpers"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import { popupScrollPositionChange } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import { StaticBox, StretchBox } from "@modules/shared/Grid"
import PopupButtons from "./PopupButtons"
import PopupHeader from "./PopupHeader"
import PopupScrollView from "./PopupScrollView"
import PopupTitle from "./PopupTitle"
import PopupTransitions, { PopupAnimationEnum } from "./PopupTransitions"

const CLOSE_THRESHOLD = 115
const DRAG_AREA_HEIGHT = 64

export const Background = {
    White: "white",
    Red: "red",
    LightRed: "light-red",
} as const

export type BackgroundEnum = typeof Background[keyof typeof Background];

interface PopupProps {
    buttons?: React.ReactNode | React.JSX.Element
    children?: React.ReactNode
    className?: string
    isForcedToFront?: boolean
    isForcedFullscreen?: boolean
    isHeaderActionsOverflow?: boolean
    isOpen: boolean
    isSimple?: boolean
    isSwipeToCloseDisabled?: boolean
    isTitleHidden?: boolean
    onClose?: () => void
    onEnter?: () => void
    onEntered?: () => void
    onEntering?: () => void
    onExit?: () => void
    onExited?: () => void
    onExiting?: () => void
    onGoBack?: () => void
    title?: string | React.ReactNode
    titleIllustration?: React.ReactNode
    wrapperClassName?: string
    popupAnimation?: PopupAnimationEnum
    backgroundColor?: BackgroundEnum
}

const Popup = ({
    buttons,
    children,
    className,
    isForcedToFront,
    isForcedFullscreen,
    isHeaderActionsOverflow,
    isOpen,
    isSimple,
    isSwipeToCloseDisabled,
    isTitleHidden,
    onClose,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onGoBack,
    title,
    titleIllustration,
    wrapperClassName,
    popupAnimation,
    backgroundColor,
}: PopupProps) => {
    const dispatch = useDispatch<any>()
    const isFullscreenPopup = useSelector((state: RootState) => state.app.isFullscreenPopup)
    const id = useUniqueId("popup-")
    const headingId = useUniqueId("popup-heading-")
    const [y, setY] = useState(0)
    const [yDiff, setYDiff] = useState(0)
    const [dragDistance, setDragDistance] = useState(0)
    const [shallCloseOnTouchEnd, setShallCloseOnTouchEnd] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [zIndex, setZIndex] = useState(isForcedToFront ? 99 : 1)
    const contentRef = useRef(null) as any
    const wrapperRef = useRef<HTMLDivElement>(null)
    const prevIsOpen = usePrevious(isOpen)

    const contentHasFocus = () => {
        return document.activeElement === contentRef.current || contentRef.current.contains(document.activeElement)
    }

    useEffect(() => {
        if (!prevIsOpen && isOpen) {
            // Popup is opened
            setDragDistance(0)
            LifecycleEmitter.broadcast(MenyLifecycleEvent.PopupOpen, { id })
            if (contentRef.current && !contentHasFocus()) {
                contentRef.current.focus()
            }
        } else if (prevIsOpen && !isOpen) {
            // Popup is closed
            setTimeout(() => LifecycleEmitter.broadcast(MenyLifecycleEvent.PopupClosed, { id }), 300)
            // Reset scroll position
            dispatch(popupScrollPositionChange(true, false))
        }
    }, [dispatch, id, isOpen, prevIsOpen])

    const handleTouchStart = (e: React.TouchEvent) => {
        if (isSwipeToCloseDisabled) {
            return
        }

        const touchY = e.touches[0].clientY
        const wrapperY = wrapperRef.current?.offsetTop || 0
        const isWithinDragArea = touchY < wrapperY + DRAG_AREA_HEIGHT

        if (isWithinDragArea) {
            setIsDragging(true)
        }

        setY(touchY)
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        if (isSwipeToCloseDisabled || !isDragging) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        const dy = e.touches[0].clientY - y

        setYDiff(dy)
        setDragDistance(dy > 0 ? dy : 0)
        setShallCloseOnTouchEnd(Math.abs(dy) > CLOSE_THRESHOLD && dy > 0)
    }

    const handleTouchEnd = () => {
        if (isSwipeToCloseDisabled) {
            return
        }

        if (shallCloseOnTouchEnd && onClose) {
            onClose()
        }

        setY(shallCloseOnTouchEnd ? y : 0)
        setYDiff(shallCloseOnTouchEnd ? yDiff : 0)
        setShallCloseOnTouchEnd(false)
        setIsDragging(false)
        setDragDistance(shallCloseOnTouchEnd ? dragDistance : 0)
    }

    useEffect(() => {
        setZIndex(isForcedToFront ? 99 : 1)
    }, [isForcedToFront])

    return (
        <PopupTransitions
            className={className}
            contentRef={contentRef}
            headingId={headingId}
            id={id}
            isOpen={isOpen}
            onEnter={onEnter}
            onEntered={onEntered}
            onEntering={onEntering}
            onExit={onExit}
            onExited={onExited}
            onExiting={onExiting}
            zIndex={zIndex}
            popupAnimation={popupAnimation}
        >
            <div
                style={{ transform: dragDistance > 0 ? `translateY(${dragDistance}px)` : undefined }}
                className={classNames(
                    wrapperClassName,
                    "app-popup__wrapper",
                    `app-popup__wrapper--${backgroundColor}`,
                    {
                        "app-popup__wrapper--fullscreen": isFullscreenPopup,
                        "app-popup__wrapper--force-fullscreen": isForcedFullscreen,
                        "app-popup__wrapper--touching": isDragging,
                    },
                )}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                ref={wrapperRef}
            >
                <PopupHeader
                    ariaControls={id}
                    isActionsOverflow={isHeaderActionsOverflow}
                    onClose={onClose}
                    onGoBack={onGoBack}
                    title={title}
                />
                <StretchBox>
                    <PopupScrollView>
                        {title ? (
                            <PopupTitle
                                id={id}
                                illustration={titleIllustration}
                                isHidden={isTitleHidden}
                                isSimple={isSimple}
                            >
                                {title}
                            </PopupTitle>
                        ) : null}
                        {children}
                    </PopupScrollView>
                </StretchBox>
                {buttons ? (
                    <StaticBox className="">
                        <PopupButtons>
                            {buttons}
                        </PopupButtons>
                    </StaticBox>
                ) : null}
            </div>
        </PopupTransitions>
    )
}

export { default as PopupButtons } from "./PopupButtons"
export { default as PopupContent } from "./PopupContent"
export { default as PopupScrollView } from "./PopupScrollView"
export { default as PopupText } from "./PopupText"
export { default as PopupTitle } from "./PopupTitle"
export { default as PopupTransitions } from "./PopupTransitions"

export default Popup
