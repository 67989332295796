import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideBarcodePopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Popup from "@modules/popup/Popup"
import { BarcodeScanner } from "../loaders"

const BarcodePopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.barcodePopup.visible)

    return (
        <Popup
            isForcedToFront
            isOpen={isOpen}
            onClose={() => dispatch(hideBarcodePopup())}
            title="Strekkodeleser"
            isTitleHidden
            isForcedFullscreen
            className="barcode-scanner-popup"
        >
            <LazyRender
                isVisible={isOpen}
            >
                <BarcodeScanner />
            </LazyRender>
        </Popup>
    )
}
export default BarcodePopup
