const saveScrollOffset = (history, location) => {
    try {
        const scrollContainer = document.querySelectorAll(".app-scroll-view__inner")[0]
        const replaced = { pathname: location.pathname, state: { ...location.state, prevScrollTop: scrollContainer.scrollTop } }
        history.replace(replaced)
    } catch (error) {
        // nothing to do here
    }
}

export default saveScrollOffset
