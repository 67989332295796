import "./style/feedback.scss"

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { showFeedbackPopup } from "@app/store/app/appActions"
import { eventHasHappenedRecently, triggerEventNow } from "@modules/shared/MessageAfterEvent"
import MenyLocalStorage from "@app/MenyLocalStorage"

// Icons
import thumbsDownIcon from "@ng-mw/reol/icons/thumbsDown"
import thumbsUpIcon from "@ng-mw/reol/icons/thumbsUp"

// Components
import Tips, { BackgroundEnum } from "@modules/shared/Tips"
import Button from "@ng-mw/reol/components/Button"
import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"

interface FeedbackProps {
    id: string
    title?: string
    description?: string
    url?: string
    thumbsUpResponse?: string
    thumbsDownResponse?: string
    skipConsentStep?: boolean
    isCloseButtonVisible?: boolean
    background?: BackgroundEnum
    maxMinutesAgo?: number
}

const Feedback = ({
    id = "generic-feedback", // Used for hiding the component after interaction
    title = "Var dette nyttig for deg?",
    description = "Tjenesten er inne i en tidlig fase, og den forbedres fortløpende. Vi vil gjerne høre din mening.",
    url = "https://response.questback.com/trumfas/qzceotg7w5",
    thumbsUpResponse = "Så hyggelig! Vi vil gjerne høre mer om hvorfor du likte innsiktstjenesten og hva vi eventuelt kan gjøre for at tjenesten skal bli enda bedre. Trykk på knappen nedenfor for å svare på noen raske spørsmål.",
    thumbsDownResponse = "Det var synd. Vi vil gjerne høre mer om hvorfor du ikke likte innsiktstjenesten, og hva vi kan gjøre for å forbedre tjenesten slik at den står til dine forventninger. Hjelp oss å bli bedre ved å trykke på knappen nedenfor og svare på noen raske spørsmål.",
    skipConsentStep = false,
    isCloseButtonVisible = false,
    background,
    maxMinutesAgo = 20160,
}: FeedbackProps) => {
    const dispatch = useDispatch<any>()
    const localStorageKey = `${MenyLocalStorage.UserInteractedWithFeedback}${id}`
    const recentlyAnsweredThis = eventHasHappenedRecently(localStorageKey, maxMinutesAgo)
    const [isVisible, setIsVisible] = useState(recentlyAnsweredThis ? false : true)

    const handleThumbClick = (isThumbsUp: boolean) => {
        dispatch(showFeedbackPopup({
            isThumbsUp,
            url,
            thumbsUpResponse,
            thumbsDownResponse,
            skipConsentStep,
            id,
        }))
    }

    const handleClose = () => {
        triggerEventNow(localStorageKey)
        setIsVisible(false)
    }

    return isVisible ? (
        <Tips
            title={title}
            text={description}
            illustrationLeftOffset="0"
            onClose={isCloseButtonVisible ? handleClose : null}
            titleFont="mulish"
            background={background}
        >
            <ButtonGroup
                spacing="large"
                className="feedback__buttons"
            >
                <Button
                    ariaLabel="Ja"
                    title="Ja"
                    onClick={() => handleThumbClick(true)}
                    iconRight={thumbsUpIcon}
                    isTextHidden
                />
                <Button
                    ariaLabel="Nei"
                    title="Nei"
                    onClick={() => handleThumbClick(false)}
                    iconRight={thumbsDownIcon}
                    isTextHidden
                />
            </ButtonGroup>
        </Tips>
    ) : null
}

export default Feedback
