import { DebugLogger, LifecycleEmitter } from "@ng-mw/shared-react-components"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"

const findNearestScroller = element => {
    while (element.parentElement) {
        element = element.parentElement
        const className = element.className.trim()

        if (className === "box" || className === "app-scroll-view__inner") {

            return element
        }
    }

    return null
}

const findHighestPopup = () => {
    const popups = document.querySelectorAll(".app-popup")
    let result
    let highest = -1

    for (const popup of popups) {
        const zIndex = parseInt(popup.style.zIndex)

        if (zIndex > highest) {
            result = popup
            highest = zIndex
        }
    }

    return result
}

window.oncontextmenu = e => {
    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) && !["radio", "checkbox"].includes(e.target.type)) {
        e.preventDefault()
        e.stopPropagation()

        return false
    }
}

// FIX SCROLLING-ISSUE

// Do not apply this to iOS 13 and above:
const isNewIOS = (/iPhone OS (13|14|15|16|17|18)/i.test(navigator.userAgent)) || (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)

document.addEventListener("focusin", e => {
    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) && !["radio", "checkbox"].includes(e.target.type)) {
        try {
            if (findNearestScroller(e.target)) {
                setTimeout(() => {
                    if (!isNewIOS) {
                        e.target.parentElement.parentElement.scrollIntoView({ behavior: "smooth", block: "start" })
                    } else {
                        e.target.parentElement.parentElement.scrollIntoView({ behavior: "smooth", block: "center" })
                    }
                }, 200)
            }
        } catch (e) {
            // swallow
        }
    }
})

LifecycleEmitter.on(MenyLifecycleEvent.PopupOpen, ({ id }) => {
    const scrollViewInner = document.querySelector(".app-layout-main .app-scroll-view__inner")
    if (scrollViewInner) {
        scrollViewInner.style.overflow = "hidden"
    } else {
        DebugLogger.warn("This page doesn't have a scrollview!")
    }

    try {
        const inputs = "input, select, textarea"
        const all = document.querySelectorAll(inputs)
        const popupInputs = Array.from(document.getElementById(id).querySelectorAll(inputs))

        for (const element of all) {
            if (!popupInputs.includes(element)) {
                element.tabIndex = -1
            }
        }
    } catch (e) {
        DebugLogger.info(e)
    }
})

LifecycleEmitter.on(MenyLifecycleEvent.PopupClosed, () => {
    const scrollViewInner = document.querySelector(".app-layout-main .app-scroll-view__inner")
    if (scrollViewInner) {
        scrollViewInner.style.removeProperty("overflow")
    } else {
        DebugLogger.warn("This page doesn't have a scrollview!")
    }

    try {
        const inputs = "input, select, textarea"
        const popup = findHighestPopup()
        const all = Array.from(document.querySelectorAll(inputs))

        for (const element of all) {
            element.tabIndex = -1
        }

        if (popup) {
            const popupInputs = Array.from(popup.querySelectorAll(inputs))

            for (const element of popupInputs) {
                element.tabIndex = 0
            }
        } else {
            for (const element of all) {
                element.tabIndex = 0
            }
        }
    } catch (e) {
        DebugLogger.info(e)
    }
})
