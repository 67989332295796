import { useEffect, useState } from "react"
import { DebugLogger } from "@ng-mw/shared-react-components"

const useIsAppLatestVersion = () => {
    const [latestVersion, setLatestVersion] = useState("")
    const [isAppLatestVersion, setIsAppLatestVersion] = useState(true)

    useEffect(() => {
        const fetchVersion = async () => {
            try {
                fetch("/js/versioninfo.txt")
                    .then(response => response.text())
                    .then(text => {
                        setLatestVersion(text)
                    })
            } catch (error) {
                DebugLogger.log("Fetch latestVersion error:", error)
            }
        }
        const interval = setInterval(() => fetchVersion(), 30000)
        fetchVersion()
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (latestVersion && latestVersion !== process.env.APP_VERSION) {
            setIsAppLatestVersion(false)
        }
    }, [latestVersion])

    return {
        isAppLatestVersion,
    }
}

export default useIsAppLatestVersion
