import "./style/app-layout-footer.scss"

import React from "react"

interface AppLayoutFooterProps {
    children?: React.ReactNode
}

const AppLayoutFooter = ({
    children,
}: AppLayoutFooterProps) => {
    return (
        <div
            className="app-layout-footer"
        >
            {React.Children.map(children, (child: React.ReactNode) => {
                if (!React.isValidElement(child)) {
                    return child
                }

                return React.cloneElement(child, {})
            })}
        </div>
    )
}

export default AppLayoutFooter
