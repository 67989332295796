import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideCategoryFilterPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import PopupContent from "@modules/popup/PopupContent"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { CategoryFilter } from "../loaders"

const CategoryFilterPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible, productViewId } = useSelector((state: RootState) => state.app.categoryFilterPopup)

    const handleClose = () => dispatch(hideCategoryFilterPopup())

    return (
        <Popup
            onClose={handleClose}
            isOpen={visible}
            title="Filtrering"
            isForcedToFront
        >
            <PopupContent>
                <LazyRender
                    isVisible={visible}
                    hiddenClassName=""
                    className="grid"
                >
                    <CategoryFilter productViewId={productViewId} />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default CategoryFilterPopup
