import React from "react"
import { useDispatch, useSelector } from "react-redux"
import MenyLocalStorage from "@app/MenyLocalStorage"
import { hideFeedbackPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import { triggerEventNow } from "@modules/shared/MessageAfterEvent"

// Components
import Popup from "@modules/popup/Popup"
import FeedbackForm from "../FeedbackForm"

const FeedbackPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible: isOpen, data } = useSelector((state: RootState) => state.app.feedbackPopup)
    const id = data?.id

    const onClose = () => {
        triggerEventNow(`${MenyLocalStorage.UserInteractedWithFeedback}${id}`)
        dispatch(hideFeedbackPopup())
    }

    return (
        <Popup
            onClose={onClose}
            isOpen={isOpen}
            isHeaderActionsOverflow
        >
            <FeedbackForm onClose={onClose} />
        </Popup>
    )
}

export default FeedbackPopup
