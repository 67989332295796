import "../../common/styles/insight-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideClimateNumbersPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import { ClimateNumbers } from "@ng-mw/shared-react-components/insight-lazy"
import LazyRender from "@ng-mw/reol/components/LazyRender"

const ClimateNumbersPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.climateNumbersPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideClimateNumbersPopup())}
            isOpen={isOpen}
            className="insight-popup"
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
            >
                <PopupContent>
                    <ClimateNumbers />
                </PopupContent>
            </LazyRender>
        </Popup>
    )
}

export default ClimateNumbersPopup
