import "./style/error-boundary.scss"

import React, { Component } from "react"
import { LifecycleEmitter, NativeEvent, NativeEventBridge, Logger } from "@ng-mw/shared-react-components"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"

// Components
import Button from "@ng-mw/reol/components/Button"

interface ErrorBoundaryData {
    error: Error
    info: React.ErrorInfo
}
interface ErrorBoundaryState {
    hasError: boolean
    extended: boolean
    data?: ErrorBoundaryData
}

interface ErrorBoundaryProps {
    children?: React.ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = {
        extended: false,
        hasError: false,
        data: undefined,
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({ hasError: true, data: { error, info } })

        LifecycleEmitter.broadcast(MenyLifecycleEvent.FatalError, { error, info })
        try {
            Logger.error("FatalError", { error, info })
        } catch {
            // Now we can't do anything more...
        }
    }

    render() {
        const { children } = this.props
        const { data, hasError, extended } = this.state as ErrorBoundaryState

        if (!hasError || !data) {
            return children
        }

        return (
            <div
                className="error-boundary"
                aria-live="assertive"
            >
                {extended ? (
                    <div className="error-boundary__deets">
                        <pre>
                            {data.error.stack}
                        </pre>
                        <pre>
                            {JSON.stringify(data.info, null, 4)}
                        </pre>
                    </div>
                ) : (
                    <div className="app-poster">
                        <h1 className="error-boundary__title">
                            Beklager, noe gikk galt
                        </h1>
                        <p className="error-boundary__text">
                            Vil du prøve å laste appen på nytt?
                        </p>
                        <Button
                            text="Last på nytt"
                            onClick={() => NativeEventBridge.broadcastNative(NativeEvent.ReloadApp)}
                        />
                    </div>
                )}

                <button
                    className="error-boundary__sub-button"
                    type="button"
                    onClick={() => this.setState({ extended: !extended })}
                >
                    Detaljer
                </button>
            </div>
        )
    }
}
