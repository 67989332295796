import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { hidePurchaseDetailsPopup } from "@app/store/app/appActions"
import PathNames from "@app/const/PathNames"
import { useNativeBuildNumber } from "@app/helpers"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import ScrollView from "@modules/shared/ScrollView"
import { TransactionDetails } from "@ng-mw/shared-react-components/component-loaders/transaction-details"

const PurchaseDetailsPopup = () => {
    const dispatch = useDispatch<any>()
    const history = useHistory()
    const isOpen = useSelector((state: RootState) => state.app.purchaseDetailsPopup.visible)
    const activeDetails = useSelector((state: RootState) => state.transactionHistory.history.activeDetails)

    const { supportsComplaints } = useNativeBuildNumber()

    if (!activeDetails) {
        return null
    }

    const handleClose = () => dispatch(hidePurchaseDetailsPopup())

    const handleCreateComplaint = (batchId: string) => {
        dispatch(hidePurchaseDetailsPopup())
        history.push({ pathname: PathNames.PurchaseComplaint, state: { batchId } })
    }

    return (
        <Popup
            isOpen={isOpen && !!activeDetails}
            onClose={handleClose}
            title="Kvittering"
        >
            <ScrollView>
                <TransactionDetails
                    batchId={activeDetails.batchId}
                    date={new Date(activeDetails.transaksjonsTidspunkt)}
                    description={activeDetails.beskrivelse}
                    partnerId={activeDetails.partnerId}
                    isDownloadButtonHidden={false}
                    isCloseButtonHidden
                    isStoreMapLinkHidden
                    isSavingsVisible
                    onClose={handleClose}
                    onCreateComplaint={supportsComplaints ? handleCreateComplaint : null}
                />
            </ScrollView>
        </Popup>
    )
}

export default PurchaseDetailsPopup
