import { IStoredShoppingList } from "@ng-mw/platform-interfaces"
import { RecipeItem } from "@app/types"
import { CompareGroupPopupData } from "@modules/insight/diet/types/CompareGroupPopupData"
import { GamePopupPayload } from "@modules/seasonal/types/GamePopupProps"
import { AppNewsData } from "@modules/onboarding/pages/NewsPopup"

export const AppAction = {
    ScrollPositionChange: "app:scroll-postion-changed",
    PopupScrollPositionChange: "app:popup-scroll-postion-changed",
    ShowCategoryFilterPopup: "app:show-category-filter-popup",
    HideCategoryFilterPopup: "app:hide-category-filter-popup",
    ShowProductPopup: "app:show-product-popup",
    HideProductPopup: "app:hide-product-popup",
    ShowBarcodePopup: "app:show-barcode-popup",
    HideBarcodePopup: "app:hide-barcode-popup",
    BarcodeFound: "app:barcode-found",
    ShowRecipePopup: "app:show-recipe-popup",
    HideRecipePopup: "app:hide-recipe-popup",
    ShowRecipeRatingPopup: "app:show-recipe-rating-popup",
    HideRecipeRatingPopup: "app:hide-recipe-rating-popup",
    ShowCouponInfoPopup: "app:show-coupon-popup",
    HideCouponInfoPopup: "app:hide-coupon-popup",
    ShowAppOnboardingPoster: "app:show-app-onboarding-poster",
    HideAppOnboardingPoster: "app:hide-app-onboarding-poster",
    ShowRegistrationPopup: "app:show-registration-popup",
    HideRegistrationPopup: "app:hide-registration-popup",
    ShowHandoverPickerPopup: "app:show-handover-picker-popup",
    HideHandoverPickerPopup: "app:hide-handover-picker-popup",
    ShowCheckoutPaymentPopup: "app:show-checkout-payment-popup",
    HideCheckoutPaymentPopup: "app:hide-checkout-payment-popup",
    ShowTrumfIdModal: "app:show-trumf-id-modal",
    HideTrumfIdModal: "app:hide-trumf-id-modal",
    ShowInsightAboutPopup: "app:show-insight-about-popup",
    HideInsightAboutPopup: "app:hide-insight-about-popup",
    ShowFeedbackPopup: "app:show-feedback-popup",
    HideFeedbackPopup: "app:hide-feedback-popup",
    ShowInsightKeyFiguresPopup: "app:show-insight-key-figures-popup",
    HideInsightKeyFiguresPopup: "app:hide-insight-key-figures-popup",
    ShowDietEnergyPopup: "app:show-diet-energy-popup",
    HideDietEnergyPopup: "app:hide-diet-energy-popup",
    ShowDietGroupPopup: "app:show-diet-group-popup",
    SetDietGroupPopupTabIndex: "app-set-diet-group-popup-tab-index",
    HideDietGroupPopup: "app:hide-diet-group-popup",
    ShowDietPropertyPopup: "app:show-diet-property-popup",
    HideDietPropertyPopup: "app:hide-diet-property-popup",
    ShowInsightOnboardingPopup: "app:show-insight-onboarding-popup",
    HideInsightOnboardingPopup: "app:hide-insight-onboarding-popup",
    ShowLoginPopup: "app:show-login-popup",
    HideLoginPopup: "app:hide-login-popup",
    ShowLogOutPopup: "app:show-log-out-popup",
    HideLogOutPopup: "app:hide-log-out-popup",

    ShowClimateNumbersPopup: "app:show-climate-numbers-popup",
    HideClimateNumbersPopup: "app:hide-climate-numbers-popup",
    ShowClimateComparisonPopup: "app:show-climate-comparison-popup",
    HideClimateComparisonPopup: "app:hide-climate-comparison-popup",
    ShowClimateInformationPopup: "app:show-climate-information-popup",
    HideClimateInformationPopup: "app:hide-climate-information-popup",
    ShowClimateProductGroupsInfoPopup: "app:show-climate-product-groups-info-popup",
    HideClimateProductGroupsInfoPopup: "app:hide-climate-product-groups-info-popup",

    //User
    ShowPushConsentsPopup: "app:show-push-consents-popup",
    HidePushConsentsPopup: "app:hide-push-consents-popup",

    // Transactions
    ShowPurchaseDetailsPopup: "app:show-transaction-details-popup",
    HidePurchaseDetailsPopup: "app:hide-transaction-details-popup",
    ShowPurchaseComplaintPopup: "app:show-transaction-complaint-popup",
    HidePurchaseComplaintPopup: "app:hide-transaction-complaint-popup",

    // digital trumf
    ShowQrPopup: "app:show-qr-popup",
    HideQrPopup: "app:hide-qr-popup",

    ShowAppUpgradePopup: "app:show-app-upgrade-popup",
    HideAppUpgradePopup: "app:hide-app-upgrade-popup",
    ShowForcedUpgradePopup: "app:show-forced-app-upgrade-popup",
    HideForcedUpgradePopup: "app:hide-forced-app-upgrade-popup",
    ShowOsUpgradePopup: "app:show-os-upgrade-popup",
    HideOsUpgradePopup: "app:hide-os-upgrade-popup",
    ShowNewsPopup: "app:show-news-popup",
    HideNewsPopup: "app:hide-news-popup",
    ShowActivateLoyaltyClubPopup: "app:show-activate-meny-club-popup",
    HideActivateLoyaltyClubPopup: "app:hide-activate-meny-club-popup",

    //Shopping list
    ShowShoppingListRemovePopup: "app:show-remove-shopping-list-popup",
    HideShoppingListRemovePopup: "app:hide-remove-shopping-list-popup",
    ShowShoppingListChangeNamePopup: "app:show-change-name-shopping-list-popup",
    HideShoppingListChangeNamePopup: "app:hide-change-name-shopping-list-popup",
    ShowShoppingListCreatePopup: "app:show-create-shopping-list-popup",
    HideShoppingListCreatePopup: "app:hide-create-shopping-list-popup",

    // Agreement Deactivation
    ShowAgreementDeactivationPopup: "app:show-agreement-deactivation-popup",
    HideAgreementDeactivationPopup: "app:hide-agreement-deactivation-popup",

    // Loyalty club
    ShowWelcomePopup: "app:show-welcome-popup",
    HideWelcomePopup: "app:hide-welcome-popup",
    ShowLoyaltyClubInfoPopup: "app:show-loyalty-club-info-popup",
    HideLoyaltyClubInfoPopup: "app:hide-loyalty-club-info-popup",

    //Game
    ShowGamePopup: "app: show-game-popup",
    HideGamePopup: "app: hide-game-popup",
}

export const scrollPositionChange = (top: boolean, bottom: boolean, goingDown: boolean) => {
    return {
        type: AppAction.ScrollPositionChange,
        payload: { top, bottom, goingDown },
    }
}

export const popupScrollPositionChange = (top: boolean, goingDown: boolean) => {
    return {
        type: AppAction.PopupScrollPositionChange,
        payload: { top, goingDown },
    }
}

export const showCategoryFilterPopup = (productViewId?: string) => {
    return {
        type: AppAction.ShowCategoryFilterPopup,
        payload: productViewId,
    }
}

export const hideCategoryFilterPopup = () => {
    return {
        type: AppAction.HideCategoryFilterPopup,
    }
}

export const showCouponInfoPopup = () => {
    return {
        type: AppAction.ShowCouponInfoPopup,
    }
}

export const hideCouponInfoPopup = () => {
    return {
        type: AppAction.HideCouponInfoPopup,
    }
}

export const showTrumfIdModal = () => {
    return {
        type: AppAction.ShowTrumfIdModal,
    }
}
export const hideTrumfIdModal = () => {
    return {
        type: AppAction.HideTrumfIdModal,
    }
}

export const showAppOnboardingPoster = () => {
    return {
        type: AppAction.ShowAppOnboardingPoster,
    }
}

export const hideAppOnboardingPoster = () => {
    return {
        type: AppAction.HideAppOnboardingPoster,
    }
}

export interface RegistrationPopupPayload {
    phoneNumber?: string
    registrationType?: "trumf" | "corporate"
}

export const showRegistrationPopup = (data?: RegistrationPopupPayload) => {
    return {
        type: AppAction.ShowRegistrationPopup,
        payload: data,
    }
}

export const hideRegistrationPopup = () => {
    return {
        type: AppAction.HideRegistrationPopup,
    }
}

export const showProductPopup = () => {
    return {
        type: AppAction.ShowProductPopup,
    }
}

export const hideProductPopup = () => {
    return {
        type: AppAction.HideProductPopup,
    }
}

export const showBarcodePopup = () => {
    return {
        type: AppAction.ShowBarcodePopup,
    }
}

export const hideBarcodePopup = () => {
    return {
        type: AppAction.HideBarcodePopup,
    }
}

export const barcodeFound = (productEan: string) => {
    return {
        type: AppAction.BarcodeFound,
        payload: productEan,
    }
}
export const showRecipePopup = (recipeId: string) => {
    return {
        type: AppAction.ShowRecipePopup,
        payload: recipeId,
    }
}

export const hideRecipePopup = () => {
    return {
        type: AppAction.HideRecipePopup,
    }
}

export const showRecipeRatingPopup = (recipeItem: RecipeItem) => {
    return {
        type: AppAction.ShowRecipeRatingPopup,
        payload: recipeItem,
    }
}

export const hideRecipeRatingPopup = () => {
    return {
        type: AppAction.HideRecipeRatingPopup,
    }
}

export const showHandoverPickerPopup = () => {
    return {
        type: AppAction.ShowHandoverPickerPopup,
    }
}

export const hideHandoverPickerPopup = () => {
    return {
        type: AppAction.HideHandoverPickerPopup,
    }
}

export const showCheckoutPaymentPopup = (paymentUrl: string) => {
    return {
        type: AppAction.ShowCheckoutPaymentPopup,
        payload: paymentUrl,
    }
}

export const hideCheckoutPaymentPopup = () => {
    return {
        type: AppAction.HideCheckoutPaymentPopup,
    }
}

export const showInsightAboutPopup = () => {
    return {
        type: AppAction.ShowInsightAboutPopup,
    }
}

export const hideInsightAboutPopup = () => {
    return {
        type: AppAction.HideInsightAboutPopup,
    }
}

export const hideLoyaltyClubInfoPopup = () => {
    return {
        type: AppAction.HideLoyaltyClubInfoPopup,
    }
}

export const showLoyaltyClubInfoPopup = () => {
    return {
        type: AppAction.ShowLoyaltyClubInfoPopup,
    }
}

export interface FeedbackPopupPayload {
    description?: string
    id: string
    isThumbsUp: boolean
    skipConsentStep?: boolean
    thumbsDownResponse: string
    thumbsUpResponse: string
    title?: string
    url: string
}

export const showFeedbackPopup = (data: FeedbackPopupPayload) => {
    return {
        type: AppAction.ShowFeedbackPopup,
        payload: data,
    }
}

export const hideFeedbackPopup = () => {
    return {
        type: AppAction.HideFeedbackPopup,
    }
}

export const showInsightKeyFiguresPopup = (data?: CompareGroupPopupData) => {
    return {
        type: AppAction.ShowInsightKeyFiguresPopup,
        payload: data,
    }
}

export const hideInsightKeyFiguresPopup = () => {
    return {
        type: AppAction.HideInsightKeyFiguresPopup,
    }
}

// export const showInsightGroupPopup = (navigationHeader) =>{
//     return {
//         type: AppAction.ShowInsightGroupPopup,
//         navigationHeader,
//     }
// }
//
// export const hideInsightGroupPopup = () =>{
//     return {
//         type: AppAction.HideInsightGroupPopup,
//     }
// }

// FROM TRUMF

export const showDietEnergyPopup = () => {
    return {
        type: AppAction.ShowDietEnergyPopup,
    }
}

export const hideDietEnergyPopup = () => {
    return {
        type: AppAction.HideDietEnergyPopup,
    }
}

export const showDietGroupPopup = () => {
    return {
        type: AppAction.ShowDietGroupPopup,
    }
}

export const setDietGroupPopupTabIndex = (tabIndex: number) => {
    return {
        type: AppAction.SetDietGroupPopupTabIndex,
        payload: tabIndex,
    }
}

export const hideDietGroupPopup = () => {
    return {
        type: AppAction.HideDietGroupPopup,
    }
}

export const showDietPropertyPopup = (navigationHeader?: string) => {
    return {
        type: AppAction.ShowDietPropertyPopup,
        payload: navigationHeader,
    }
}

export const hideDietPropertyPopup = () => {
    return {
        type: AppAction.HideDietPropertyPopup,
    }
}
export const showInsightOnboardingPopup = () => {
    return {
        type: AppAction.ShowInsightOnboardingPopup,
    }
}

export const hideInsightOnboardingPopup = () => {
    return {
        type: AppAction.HideInsightOnboardingPopup,
    }
}

export const showClimateNumbersPopup = () => {
    return {
        type: AppAction.ShowClimateNumbersPopup,
    }
}

export const hideClimateNumbersPopup = () => {
    return {
        type: AppAction.HideClimateNumbersPopup,
    }
}

export interface ClimateComparisonPopupData {
    title: string
    description: string
    externalUrls: string[]
    externalUrlDescriptions: string[]
}

export const showClimateComparisonPopup = (data: ClimateComparisonPopupData) => {
    return {
        type: AppAction.ShowClimateComparisonPopup,
        payload: data,
    }
}

export const hideClimateComparisonPopup = () => {
    return {
        type: AppAction.HideClimateComparisonPopup,
    }
}

export const showClimateInformationPopup = () => {
    return {
        type: AppAction.ShowClimateInformationPopup,
    }
}

export const hideClimateInformationPopup = () => {
    return {
        type: AppAction.HideClimateInformationPopup,
    }
}

export const showClimateProductGroupsInfoPopup = () => {
    return {
        type: AppAction.ShowClimateProductGroupsInfoPopup,
    }
}

export const hideClimateProductGroupsInfoPopup = () => {
    return {
        type: AppAction.HideClimateProductGroupsInfoPopup,
    }
}

export const showPurchaseDetailsPopup = () => {
    return {
        type: AppAction.ShowPurchaseDetailsPopup,
    }
}

export const hidePurchaseDetailsPopup = () => {
    return {
        type: AppAction.HidePurchaseDetailsPopup,
    }
}

export const showPurchaseComplaintPopup = () => {
    return {
        type: AppAction.ShowPurchaseComplaintPopup,
    }
}

export const hidePurchaseComplaintPopup = () => {
    return {
        type: AppAction.HidePurchaseComplaintPopup,
    }
}

export const showQrPopup = () => {
    return {
        type: AppAction.ShowQrPopup,
    }
}

export const hideQrPopup = () => {
    return {
        type: AppAction.HideQrPopup,
    }
}

export const showAgreementDeactivationPopup = () => {
    return {
        type: AppAction.ShowAgreementDeactivationPopup,
    }
}

export const hideAgreementDeactivationPopup = () => {
    return {
        type: AppAction.HideAgreementDeactivationPopup,
    }
}

export const showAppUpgradePopup = () => {
    return {
        type: AppAction.ShowAppUpgradePopup,
    }
}

export const hideAppUpgradePopup = () => {
    return {
        type: AppAction.HideAppUpgradePopup,
    }
}
export const showForcedUpgradePopup = () => {
    return {
        type: AppAction.ShowForcedUpgradePopup,
    }
}
export const hideForcedUpgradePopup = () => {
    return {
        type: AppAction.HideForcedUpgradePopup,
    }
}
export const showOsUpgradePopup = () => {
    return {
        type: AppAction.ShowOsUpgradePopup,
    }
}
export const hideOsUpgradePopup = () => {
    return {
        type: AppAction.HideOsUpgradePopup,
    }
}

export const showNewsPopup = (data: AppNewsData) => {
    return {
        type: AppAction.ShowNewsPopup,
        payload: data,
    }
}
export const hideNewsPopup = () => {
    return {
        type: AppAction.HideNewsPopup,
    }
}

export const showActivateLoyaltyClubPopup = () => {
    return {
        type: AppAction.ShowActivateLoyaltyClubPopup,
    }
}
export const hideActivateLoyaltyClubPopup = () => {
    return {
        type: AppAction.HideActivateLoyaltyClubPopup,
    }
}

export const showShoppingListRemovePopup = (shoppingList: any) => {
    return {
        type: AppAction.ShowShoppingListRemovePopup,
        payload: shoppingList,

    }
}

export const hideShoppingListRemovePopup = () => {
    return {
        type: AppAction.HideShoppingListRemovePopup,
    }
}

export const showShoppingListChangeNamePopup = (shoppingList: IStoredShoppingList) => {
    return {
        type: AppAction.ShowShoppingListChangeNamePopup,
        payload: shoppingList,
    }
}

export const hideShoppingListChangeNamePopup = () => {
    return {
        type: AppAction.HideShoppingListChangeNamePopup,
    }
}

export const showShoppingListCreatePopup = (shoppingListId?: string) => {
    return {
        type: AppAction.ShowShoppingListCreatePopup,
        payload: shoppingListId,
    }
}

export const hideShoppingListCreatePopup = () => {
    return {
        type: AppAction.HideShoppingListCreatePopup,
    }
}

export interface LoginOutPopupPayload {
    title: string
    text: string
}

export const showLoginPopup = (data: LoginOutPopupPayload) => {
    return {
        type: AppAction.ShowLoginPopup,
        payload: data,
    }
}

export const hideLoginPopup = () => {
    return {
        type: AppAction.HideLoginPopup,
    }
}

export const showLogOutPopup = (data?: LoginOutPopupPayload) => {
    return {
        type: AppAction.ShowLogOutPopup,
        payload: data,
    }
}

export const hideLogOutPopup = () => {
    return {
        type: AppAction.HideLogOutPopup,
    }
}

export const showWelcomePopup = (data?: any) => {
    return {
        type: AppAction.ShowWelcomePopup,
        payload: data,
    }
}

export const hideWelcomePopup = () => {
    return {
        type: AppAction.HideWelcomePopup,
    }
}

export const showPushConsentsPopup = () => {
    return {
        type: AppAction.ShowPushConsentsPopup,
    }
}

export const hidePushConsentsPopup = () => {
    return {
        type: AppAction.HidePushConsentsPopup,
    }
}

export const showGamePopup = (data?: GamePopupPayload) => {
    return {
        type: AppAction.ShowGamePopup,
        payload: data,
    }
}

export const hideGamePopup = () => {
    return {
        type: AppAction.HideGamePopup,
    }
}
