import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { hideProductPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { ProductDetails } from "@ng-mw/shared-react-components/lazy-loaders"
import Popup, { PopupContent } from "@modules/popup/Popup"
import BarcodeButton from "@modules/barcode/BarcodeButton"

const ProductPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.productPopup.visible)
    const scanMode = useSelector((state: RootState) => state.app.productPopup.scanMode)
    const safeArea = useSelector((state: RootState) => state.native.safeArea)
    const productTitle = useSelector((state: RootState) => state.productDetails.data.title)

    const handleClose = () => {
        dispatch(hideProductPopup())
    }

    return (
        <Popup
            isForcedToFront
            isOpen={isOpen}
            isTitleHidden
            onClose={handleClose}
            title={productTitle}
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <ProductDetails
                        isBig={false}
                        safeArea={safeArea}
                        isBreadcrumbHidden
                        BarcodeButton={scanMode && BarcodeButton}
                    />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}
export default ProductPopup
