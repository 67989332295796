import "./styles/diet-info-button.scss"

import React from "react"

// Icons
import infoIcon from "@ng-mw/reol/icons/info"

// Compoents
import Button from "@ng-mw/reol/components/Button"

const InfoButton = ({
    iconFirst = false,
    ...rest
}) => {
    return (
        <Button
            className="diet-info-button"
            iconLeft={iconFirst ? infoIcon : undefined}
            iconRight={iconFirst ? undefined : infoIcon}
            isCancel
            {...rest}
        />
    )
}

export default InfoButton
