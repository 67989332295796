import * as EditorialContentAction from "./creators/editorialContentCreator"
import { performRESTRequest } from "@ng-mw/framework-core"
import { Logger } from "@ng-mw/shared-react-components"
import { getFullLocalUrl } from "@app/helpers/useAppContent"
import { Dispatch } from "redux"
import { RootState } from "@app/types"

export const getEditorialContent = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(EditorialContentAction.getAllEditorialContentLoading())
        const { isOrganization } = getState().user.data.household

        const appContentId = isOrganization ? "corporatefeed" : "oppdagfeed"

        try {
            const response = await performRESTRequest({
                url: getFullLocalUrl(`/api/appcontent/${appContentId}`),
                method: "GET",
            })
            dispatch(EditorialContentAction.getAllEditorialContentSuccess(response))
        } catch (error) {
            dispatch(EditorialContentAction.getAllEditorialContentError(error))
            Logger.error("actions/EditorialContent/getEditorialContent()", error)
        } finally {
            dispatch(EditorialContentAction.getAllEditorialContentLoaded())
        }
    }
}

export const updateEditorialContentStore = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(EditorialContentAction.updateEditorialContentStore(data))
    }
}
