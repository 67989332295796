import "./style/message-after-event.scss"

import React, { useEffect } from "react"
import differenceInMinutes from "date-fns/differenceInMinutes"
import { LocalStorage } from "@ng-mw/shared-react-components"

// Icons
import InfoIcon from "@ng-mw/reol/icons/info"

// Components
import Icon from "@ng-mw/reol/components/Icon"

const eventHasHappenedRecently = (localStorageKey: string, maxMinutesAgo = 120) => {
    const triggerEventDate = LocalStorage.get(localStorageKey)
    const visible = triggerEventDate && (differenceInMinutes(new Date(), new Date(triggerEventDate)) < maxMinutesAgo)
    return visible
}

const triggerEventNow = (localStorageKey: string) => {
    LocalStorage.set(localStorageKey, new Date())
}

interface EventTriggerProps {
    localStorageKey: string
}

const EventTrigger = ({
    localStorageKey,
}: EventTriggerProps) => {
    useEffect(() => {
        if (localStorageKey) {
            triggerEventNow(localStorageKey)
        }
    }, [localStorageKey])
    return null
}

interface MessageAfterEventProps {
    localStorageKey: string
    message: string
    maxMinutesAgo?: number
}

const MessageAfterEvent = ({
    localStorageKey,
    message,
    maxMinutesAgo = 120, // default within 2 hours ago
}: MessageAfterEventProps) => {
    const visible = eventHasHappenedRecently(localStorageKey, maxMinutesAgo)

    return visible ? (
        <p className="message-after-event">
            <Icon
                icon={InfoIcon}
                isInline
            />
            {message}
        </p>
    ) : null
}

export { EventTrigger, eventHasHappenedRecently, triggerEventNow }
export default MessageAfterEvent
