import { DebugLogger } from "@ng-mw/shared-react-components"

const deleteOldServiceWorkerCache = async () => {
    await caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
            if (cacheName === "style-cache" || cacheName === "script-cache" || cacheName === "svg-cache") {
                caches.delete(cacheName)
                DebugLogger.log(
                    `deleted service worker cache: ${cacheName}`,
                )
            }
        })
    })
}

export default deleteOldServiceWorkerCache
