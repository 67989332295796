import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

// Components
import Popup from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { ForcedUpgrade } from "../loaders"

const ForcedUpgradePopup = () => {
    const isOpen = useSelector((state: RootState) => state.app.forcedUpgradePopup.visible)

    return (
        <Popup
            isOpen={isOpen}
            isSwipeToCloseDisabled
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className="forced-upgrade__container"
            >
                <ForcedUpgrade isOS={false} />
            </LazyRender>
        </Popup>
    )
}

export default ForcedUpgradePopup
