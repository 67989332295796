import { DebugLogger } from "@ng-mw/shared-react-components"
import { userActions } from "@ng-mw/shared-react-components/user"
import { cardsActions, profileInfoActions } from "@ng-mw/shared-react-components/ngc-my-page"
import { bonusActions } from "@ng-mw/shared-react-components/use-bonus"
import { couponsActions } from "@ng-mw/shared-react-components/coupons"
import { transactionHistoryActions } from "@ng-mw/shared-react-components/transaction-history"
import { resetSavings, getSavings } from "@ng-mw/shared-meny-components/src/modules/savings/store/actions/savingsActions"
import { getAllFavoriteRecipes, unloadFavoriteRecipes } from "@ng-mw/shared-meny-components/src/modules/recipes/store/actions/favoriteRecipesActions"
import { consentsActions } from "@ng-mw/shared-react-components/consents"
import { ordersActions } from "@ng-mw/shared-react-components/orders"
import { insightActions } from "@ng-mw/shared-react-components/insight"
import MenyInsightConfig from "@ng-mw/shared-meny-components/src/modules/insight/MenyInsightConfig"
import { AnyAction, Dispatch } from "redux"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { RootState } from "@app/types"
import { qrClear } from "./creators/qrCreator"
import { NetworkFirstApiCache } from "@app/service-worker/constants"
import { bonusCampaignActions } from "@ng-mw/shared-meny-components/bonus-campaign"

const deleteUserServiceWorkerCache = async () => {
    await caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
            if (cacheName.includes(NetworkFirstApiCache)) {
                caches.delete(cacheName)

                DebugLogger.log(
                    `deleted service worker cache: ${cacheName}`,
                )
            }
        })
    })
}

export const loadAuthedBaseData = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const userIsCorporate = state.user.data.household.isOrganization
        const userIsLoggedIn = state.user.loginLevel > 0

        await Promise.all([
            // only dispatch things that are mandatory before rendering here
        ])
        // dispatch any calls that can be asyncronous outside Promise.all:
        dispatch(getAllFavoriteRecipes() as unknown as AnyAction)
        dispatch(userActions.checkIfDeliveryIsPossible())

        if (!userIsCorporate) {
            dispatch(bonusActions.getBonusData())
            dispatch(couponsActions.getAllCoupons())
            dispatch(consentsActions.fetchConsentsAndAgreements([{
                id: AppGlobalSettings.loyaltyClubAgreementId,
            }]))
        }

        if (!userIsLoggedIn) {
            return
        }
        const extraPreload = () => {
            if (userIsCorporate) {
                return
            }

            DebugLogger.log("Load-order-debug: preloading API's now!")
            // Preload transactions
            dispatch(transactionHistoryActions.getTransactionHistory())
            // Preload savings
            dispatch(getSavings("MENY") as unknown as AnyAction)
            // Trigger Diet aggregations (backend)
            // dispatch(insightActions.initializeInsight(MenyInsightConfig))
        }

        if (AppGlobalSettings.aggressivePreload) {
            extraPreload()
        } else {
            setTimeout(extraPreload, 7000) // delay preloading for 7 seconds
        }

        DebugLogger.log("Load-order-debug: loadAuthedBaseData!")
    }
}

export const loadBaseData = () => {
    return async () => {
        await Promise.all([
            // dispatch(handoverWindowsActions.getHandoverWindows()),
        ])

        // Trigger Diet aggregations (backend)
        // dispatch(dietActions.loadPropertiesAndTimePeriods())
        DebugLogger.log("Load-order-debug: loadBaseData!")
    }
}

export const resetAuthedBaseData = () => {
    return async (dispatch: Dispatch) => {
        await Promise.all([
            dispatch(bonusActions.resetBonusData()),
            dispatch(profileInfoActions.resetMemberInformation()),
            dispatch(transactionHistoryActions.resetTransactionHistory()),
            dispatch(resetSavings() as unknown as AnyAction),
            dispatch(unloadFavoriteRecipes() as unknown as AnyAction),
            dispatch(cardsActions.resetCardInformation()),
            dispatch(insightActions.resetAllDietData()),
            dispatch(consentsActions.resetConsents()),
            dispatch(qrClear()),
            dispatch(ordersActions.clear()),
            deleteUserServiceWorkerCache(),
            dispatch(bonusCampaignActions.clearRelevantBonuses()),
        ])
    }
}

export const setToken = (token = "", isInitialLogin = false) => {
    return async (dispatch: Dispatch) => {
        try {
            if (token) {
                await dispatch(userActions.setToken(token, isInitialLogin))
                // tracking.navigation(PathNames.Home, "in") // copied from TrumfApp
                await dispatch(loadAuthedBaseData() as unknown as AnyAction)
            } else {
                dispatch(userActions.logOut())
            }
        } catch (e) {
            // swallow
            // await dispatch(loadBaseData()) // pointless
        }
    }
}

export const initAnonymousUser = () => {
    return async (dispatch: Dispatch) => {
        await Promise.all([
            // dispatch(loadBaseData()), // currently this actually does nothing
            dispatch(getExtendedUser()),
        ])
    }
}

export const updateToken = (token: string) => {
    return (dispatch: Dispatch) => {
        try {
            if (token) {
                dispatch(userActions.updateToken(token))
            } else {
                dispatch(userActions.logOut())
            }
        } catch (e) {
            // swallow
        }
    }
}

export const {
    ChangeTrigger,
    checkIfDeliveryIsPossible,
    updateTrumfProfile,
    updateAlternativeAddress,
    deleteAlternativeAddress,
    logOut,
    logIn,
    clearError,
    extendedUserHasChanged,
    saveExtendedUserHousehold,
    setHandoverInfoAndStore,
    setHandoverTypeAndLocation,
    setHandoverWindow,
    updateHandoverWindowData,
    resetHandoverWindow,
    getExtendedUser,
} = userActions
