export const EditorialContentAction = {
    Loading: "editorial-content:loading",
    Loaded: "editorial-content:loaded",
    Success: "editorial-content:success",
    Error: "editorial-content:error",
    UpdateStore: "editorial-content:update-store",
}

export function getAllEditorialContentLoading() {
    return {
        type: EditorialContentAction.Loading,
    }
}

export function getAllEditorialContentLoaded() {
    return {
        type: EditorialContentAction.Loaded,
    }
}

export function getAllEditorialContentSuccess(data: any) {
    return {
        type: EditorialContentAction.Success,
        payload: data,
    }
}

export function getAllEditorialContentError(error: any) {
    return {
        type: EditorialContentAction.Error,
        payload: error,
    }
}

export function updateEditorialContentStore(data: any) {
    return {
        type: EditorialContentAction.UpdateStore,
        payload: data,
    }
}

