import "../style/push-consents-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hidePushConsentsPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Button from "@ng-mw/reol/components/Button"
import Popup from "@modules/popup/Popup"
import Container, { Content, Padding } from "@modules/shared/Container"
import UserConsents from "../UserConsents"
import { SubHeader } from "@modules/framework/AppHeader"

export const PushConsentsPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.pushConsentsPopup.visible)
    const pushNotifications = useSelector((state: RootState) => state.pushNotifications)
    const data = pushNotifications?.data
    const pushConsentActive = data?.consentActive

    const handleClose = () => {
        dispatch(hidePushConsentsPopup())
    }

    return (
        <Popup
            isOpen={isOpen}
            isForcedFullscreen
            popupAnimation="slide-left"
            buttons={
                <>
                    <Button
                        text="Bekreft"
                        onClick={handleClose}
                        isDisabled={!pushConsentActive}
                    />
                    {!pushConsentActive ?
                        <Button
                            text="Hopp over"
                            isCancel
                            onClick={handleClose}
                        />
                        : null
                    }
                </>
            }
        >
            <SubHeader title="Hold deg oppdatert med varslinger" />
            <UserConsents
                isConsentsOnlyVisibleIfStatusFalse
                isExternalLinkHidden
            />
            <Container
                className="push-consents-popup__colophon"
                padding={Padding.None}
                content={Content.Padding}
            >
                Du kan når som helst reservere deg mot å motta app-varsler under dine innstillinger her i appen eller i dine telefoninnstillinger
            </Container>
        </Popup>
    )
}

export default PushConsentsPopup
