import { AppContent } from "@app/types/root-state/appContent"
import { EditorialContentAction } from "../actions/creators/editorialContentCreator"
import { GenericAction } from "@app/types"

const init: AppContent = {
    loading: false,
    error: null,
    data: null,
    loaded: false,
}

export default function (state = init, action: GenericAction) {
    switch (action.type) {
        // Get all
        case EditorialContentAction.Loading:
            return { ...state, loading: true }
        case EditorialContentAction.Loaded:
            return { ...state, loading: false, loaded: true }
        case EditorialContentAction.Success:
            return { ...state, data: action.payload, error: null }
        case EditorialContentAction.Error:
            return { ...state, error: action.payload }
        // Update store
        case EditorialContentAction.UpdateStore: {
            return { ...state, data: action.payload, error: null }
        }
        default:
            return state
    }
}
