import React from "react"
import PathNames from "@app/const/PathNames"

// Components
import Container from "@modules/shared/Container"

interface OrderStatusContainerProps {
    children: React.ReactNode
}

const OrderStatusContainer = ({
    children,
}: OrderStatusContainerProps) => {
    return (
        <Container
            title="Din bestilling"
            to={PathNames.OrderHistory}
            linkText="Se mer"
        >
            {children}
        </Container>
    )
}

export default OrderStatusContainer
