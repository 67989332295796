import { SavingTip } from "@app/types/root-state/savingsTips"

export const SavingsTipsAction = {
    Loading: "savings-tips:loading",
    Loaded: "savings-tips:loaded",
    Success: "savings-tips:success",
    FromCache: "savings-tips:from-cache",
    Error: "savings-tips:error",
}

export const success = (data: SavingTip[]) => {
    return {
        type: SavingsTipsAction.Success,
        payload: data,
    }
}
export const fromCache = (data: SavingTip[]) => {
    return {
        type: SavingsTipsAction.FromCache,
        payload: data,
    }
}

export const error = (error: any) => {
    return {
        type: SavingsTipsAction.Error,
        payload: error,
    }
}

export const loading = () => {
    return {
        type: SavingsTipsAction.Loading,
    }
}

export const loaded = () => {
    return {
        type: SavingsTipsAction.Loaded,
    }
}
