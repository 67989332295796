import React from "react"
import { useDispatch, useSelector } from "react-redux"
import LazyRender from "@ng-mw/reol/components/LazyRender"

import { RootState } from "@app/types"
import FirstTrumfId from "../FirstTrumfId"
import { hideTrumfIdModal } from "@app/store/app/appActions"
import Popup from "@modules/popup/Popup"

export const UserSeenTrumfIdModalKey = "HAS_SEEN_FIRST_TRUMFID_MODAL-v1"
export const TRUMF_ID_MODAL_ENABLED = true

const FirstTrumfIdModal = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.trumfIdModal.visible)
    const handleClose = () => {
        dispatch(hideTrumfIdModal())
    }

    return (
        <Popup
            isOpen={isOpen}
            isForcedToFront
            onClose={handleClose}
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className="first-trumf-id"
            >
                <FirstTrumfId onClose={handleClose} />
            </LazyRender>
        </Popup>
    )

}

export default FirstTrumfIdModal
