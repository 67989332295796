import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { shoppingListsActions } from "@ng-mw/shared-react-components/shopping-lists"
import { hideShoppingListRemovePopup } from "@app/store/app/appActions"
import PathNames from "@app/const/PathNames"
import { RootState } from "@app/types"

// Components
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Button from "@ng-mw/reol/components/Button"
import Popup, { PopupButtons, PopupContent, PopupText } from "@modules/popup/Popup"

const ShoppingListRemovePopup = () => {
    const dispatch = useDispatch<any>()
    const history = useHistory()
    const { shoppingList } = useSelector((state: RootState) => state.app.removeShoppingListPopup)

    const isOpen = useSelector((state: RootState) => state.app.removeShoppingListPopup.visible)

    const hidePopup = () => dispatch(hideShoppingListRemovePopup())

    const handleRemoveList = () => {
        dispatch(shoppingListsActions.removeShoppingList(shoppingList?.id))
        hidePopup()
        history.push(PathNames.ShoppingListsHome)
    }

    return (
        <Popup
            isOpen={isOpen && !!shoppingList}
            onClose={hidePopup}
            title="Slette handelisten?"
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className=""
            >
                <PopupContent>
                    <PopupText>
                        <p>
                            Er du sikker på at du vil slette handlelisten? Den kan ikke gjenopprettes.
                        </p>
                    </PopupText>
                </PopupContent>
                <PopupButtons
                    isSimple
                >
                    <Button
                        onClick={handleRemoveList}
                        text="Ja, slett handlelisten"
                    />
                    <Button
                        onClick={hidePopup}
                        text="Avbryt"
                        isSecondary
                    />
                </PopupButtons>
            </LazyRender>
        </Popup>
    )
}

export default ShoppingListRemovePopup
