import "./style/loyalty-club-info.scss"

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAboutLoyaltyClubInfo } from "./store/actions/aboutLoyaltyClubActions"
import { RootState } from "@app/types"

import Container from "@modules/shared/Container"
import AccordionItem from "@ng-mw/reol/components/AccordionItem"
import Spinner from "@ng-mw/reol/components/Spinner"
import SafeHtml from "@ng-mw/reol/components/SafeHtml"

const LoyaltyClubInfo = () => {
    const dispatch = useDispatch<any>()
    const { data, loaded, loading } = useSelector((state: RootState) => state.aboutLoyaltyClub)

    useEffect(() => {
        if (!data?.length && !loaded && !loading) {
            dispatch(getAboutLoyaltyClubInfo())
        }
    }, [data, loaded, loading, dispatch])

    const mainContent = !data?.length ? null : data[0]

    return (
        <div className="loyalty-club-info">
            {loading || !mainContent ?
                <Spinner /> :
                <>
                    <Container className="loyalty-club-info">
                        <h2 className="loyalty-club-info__title">{mainContent.Title}</h2>
                        <SafeHtml
                            html={mainContent.Content}
                            className="loyalty-club-info__content"
                        />
                    </Container>
                    <Container>
                        <div>
                            <h2 className="loyalty-club-info__title">{mainContent?.Accordions?.Title}</h2>
                            {mainContent.Accordions.AccordionList.map((item, i) => (
                                <AccordionItem
                                    key={item.Title + i}
                                    titleText={item.Title}
                                    isInline
                                >
                                    <SafeHtml
                                        html={item.Content}
                                        className="loyalty-club-info__content"
                                    />
                                </AccordionItem>
                            ))}
                        </div>
                    </Container>
                </>
            }
        </div>
    )
}
export default LoyaltyClubInfo
