import "./style/barcode-button.scss"

import React from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { showBarcodePopup } from "@app/store/app/appActions"
import { useNativeBuildNumber } from "@app/helpers"

// Icons
import barcodeScannerIcon from "@resources/icons/barcodeScanner"

// Components
import Icon from "@ng-mw/reol/components/Icon"

interface BarcodeButtonProps {
    text?: string
    hideIcon?: boolean
    className?: string
}

const BarcodeButton = ({
    text,
    hideIcon,
    className,
}: BarcodeButtonProps) => {
    const dispatch = useDispatch<any>()
    const { supportsScanner } = useNativeBuildNumber()

    const handleBarcodeButtonClicked = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(showBarcodePopup())
    }

    return supportsScanner ? (
        <div className={className}>
            <button
                className={classNames("barcode-button", {
                    "barcode-button--with-text": !!text,
                })}
                type="button"

                onClick={handleBarcodeButtonClicked}
                aria-label="Les en strekkode"
            >
                {hideIcon ? null : (
                    <Icon
                        icon={barcodeScannerIcon}
                        size={{ height: "28px", width: "28px" }}
                    />
                )}
                {text}
            </button>
        </div>
    ) : null
}

export default BarcodeButton
