import * as SavingsTipsAction from "./creators/savingsTipsCreator"
import { performRESTRequest } from "@ng-mw/framework-core"
import { Logger } from "@ng-mw/shared-react-components"
import { getFullLocalUrl } from "@app/helpers/useAppContent"
import { Dispatch } from "redux"
import { SavingTip } from "@app/types/root-state/savingsTips"

export const getSavingsTips = () => {
    return async (dispatch: Dispatch) => {
        dispatch(SavingsTipsAction.loading())

        try {
            const response = await performRESTRequest({
                url: getFullLocalUrl("/api/appcontent/sparetips"),
                method: "GET",
            })
            dispatch(SavingsTipsAction.success(response))
        } catch (error) {
            dispatch(SavingsTipsAction.error(error))
            Logger.error("actions/SavingsTips/getSavingsTips()", error)
        } finally {
            dispatch(SavingsTipsAction.loaded())
        }
    }
}

export const updateSavingsTipsStore = (data: SavingTip[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(SavingsTipsAction.fromCache(data))
    }
}
