import { LifecycleEmitter } from "@ng-mw/shared-react-components"
import { Store } from "redux"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import { userNavigated } from "@app/store/appRating/appRatingCreator"
import { RootState } from "@app/types"

export default async (store: Store<RootState, any>) => {
    LifecycleEmitter.on(MenyLifecycleEvent.Navigation, () => {
        store.dispatch(userNavigated())
    })
}
