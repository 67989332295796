import { DebugLogger } from "@ng-mw/shared-react-components"

// Temporary fix for Old Native Wrapper not responding to fetchTokenFromNative
const fetchTokenFromNativeError = () => new Promise(resolve => {
    setTimeout(() => {
        DebugLogger.log("Load-order-debug: fetchTokenFromNativeError done")
        resolve(null)
    }, 5000)
})

export default fetchTokenFromNativeError
