import "./style/loading-skeleton.scss"

import React, { ReactNode } from "react"
import classNames from "classnames"
import { Spacing, SpacingEnum } from "@app/types"

export interface LoadingSkeletonProps {
    className?: string
    children?: ReactNode
    isLoading: boolean
    margin?: SpacingEnum
}

const LoadingSkeleton = ({
    className = "",
    children = null,
    isLoading = false,
    margin = Spacing.None,
}: LoadingSkeletonProps) => {
    return (
        <div
            className={classNames(
                "app-loading-skeleton",
                className,
                {
                    "app-loading-skeleton--is-loading": isLoading,
                    "app-loading-skeleton--margin-none": isLoading && margin === Spacing.None,
                    "app-loading-skeleton--margin-small": isLoading && margin === Spacing.Small,
                    "app-loading-skeleton--margin-medium": isLoading && margin === Spacing.Medium,
                    "app-loading-skeleton--margin-large": isLoading && margin === Spacing.Large,
                },
            )}
        >
            <div className={classNames("app-loading-skeleton__children", { "app-loading-skeleton__children--is-loading": isLoading })}>
                {children}
            </div>
        </div>
    )
}

export default LoadingSkeleton
