import { AppEnvConfigAction } from "./appEnvConfigCreator"

const INITIAL_STATE = {
    version: null,
    buildNumber: null,
    os: null,
    osVersion: null,
    isProdLogin: null,
    isTestTrumfId: null,
    isTrumfIdEnabled: null,
    isTrumfPayEnabled: null,
    urls: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppEnvConfigAction.SetAppEnvConfigData:
            if (action.payload.version === "legacy") {
                // Do not overwrite version or buildnumber when handling a legacy appconfig:
                return {
                    ...state,
                    version: state.version || action.payload.version,
                    buildNumber: state.buildNumber || action.payload.buildNumber,
                }
            }
            return action.payload
        default:
            return state
    }
}
