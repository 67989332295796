import { useSelector } from "react-redux"
import {
    footerHeight,
    footerPaddingBottom,
    //headerHeight,
} from "@app/LayoutSettings"
import { RootState } from "../types"

const useSafeLayoutSettings = () => {
    const safeArea = useSelector((state: RootState) => state.native.safeArea)

    const safeFooterPaddingBottom = Math.max(footerPaddingBottom, safeArea.bottom)
    const safeFooterHeight = footerHeight + safeFooterPaddingBottom
    //const safeHeaderHeight = headerHeight + safeArea.top

    const safeLayoutSettings = {
        safeFooterHeight,
        safeFooterPaddingBottom,
        //safeHeaderHeight,
    }

    return safeLayoutSettings
}

export default useSafeLayoutSettings
