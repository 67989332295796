import React from "react"
import Loadable from "react-loadable"

export const InsightAbout = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "./InsightAbout"),
    loading: () => <div />,
})

export const InsightPopupHeader = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@ng-mw/shared-meny-components/src/modules/insight/InsightPopupHeader"),
    loading: () => <div />,
})
