import { SafeArea } from "@app/types/SafeArea"

export const NativeAction = {
    SetSafeArea: "native:set-safe-zone",
    SetNetworkStatus: "native:set-network-status",
}

export const setNetworkStatus = (status: boolean) => {
    return {
        type: NativeAction.SetNetworkStatus,
        payload: status,
    }
}

export const setSafeArea = (safeArea: SafeArea) => {
    return {
        type: NativeAction.SetSafeArea,
        payload: safeArea,
    }
}
