import "./style/grid.scss"

import classNames from "classnames"
import React from "react"

interface GridProps {
    className?: string
    children: React.ReactNode
}

export const Grid = ({
    className,
    children,
    ...rest
}: GridProps) => {
    return (
        <div
            {...rest}
            className={classNames("grid", className)}
        >
            {children}
        </div>
    )
}

interface StretchBoxProps {
    className?: string
    children: React.ReactNode
    id?: string
}

export const StretchBox = ({
    className,
    children,
    id,
    ...rest
}: StretchBoxProps) => {
    return (
        <div
            {...rest}
            id={id}
            className={classNames("box", className)}
        >
            {children}
        </div>
    )
}

interface StaticBoxProps {
    className?: string
    children: React.ReactNode
}

export const StaticBox = ({
    className,
    children,
    ...rest
}: StaticBoxProps) => {
    return (
        <div
            {...rest}
            className={classNames("box", "box--unflex", className)}
        >
            {children}
        </div>
    )
}
