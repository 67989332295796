import { App, LocalStorage } from "@ng-mw/shared-react-components"
import { Dispatch } from "redux"
import MenyLocalStorage from "@app/MenyLocalStorage"
import * as appRatingActions from "./appRatingCreator"

export const showRatingPopup = () => {
    return (dispatch: Dispatch) => {
        // App review is not supported on Android
        if (App.Context.Android) return

        dispatch(appRatingActions.showRatingPopup())
    }
}

export const hideRatingPopup = () => {
    LocalStorage.set(MenyLocalStorage.UserAskedToRateApp, new Date())

    return (dispatch: Dispatch) => {
        dispatch(appRatingActions.hideRatingPopup())
    }
}
