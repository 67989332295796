import "./style/popup-buttons.scss"

import classNames from "classnames"
import React from "react"
import { Spacing, SpacingEnum } from "@app/types"

// Components
import ButtonGroup from "@ng-mw/reol/components/ButtonGroup"

interface PopupButtonsProps {
    children: React.ReactNode
    isSimple?: boolean
    padding?: SpacingEnum
}

export const PopupButtons = ({
    children,
    isSimple,
    padding = Spacing.Medium,
}: PopupButtonsProps) => {
    return (
        <ButtonGroup
            className={classNames(
                "app-popup-buttons",
                `app-popup-buttons--padding-${padding}`,
                {
                    "app-popup-buttons--simple": isSimple,
                },
            )}
            direction="vertical"
            alignItems="stretch"
        >
            {children}
        </ButtonGroup>
    )
}

export default PopupButtons
