import { put, call, takeEvery } from "redux-saga/effects"
import { Logger } from "@ng-mw/shared-react-components"

import AppGlobalSettings from "@app/AppGlobalSettings"
import { actions } from "./settingsActions"
import { SettingsApiResponse, SettingsActionTypes } from "./types"

export function* loadAppSettings() {
    try {
        const response: Response = yield call(
            fetch,
            `${AppGlobalSettings.chainsiteUrl}${AppGlobalSettings.appSettingsUrl}`,
        )

        if (!response.ok) {
            yield put(actions.error(response.statusText))
            return
        }

        const settings: SettingsApiResponse = yield call([response, response.json])
        yield put(actions.success(settings))
    } catch (error: any) {
        yield put(actions.error(error))
        Logger.error("loadAppSettings()", error)
    } finally {
        yield put(actions.loaded())
    }
}

export default function* loadAppSettingsSaga() {
    yield takeEvery(SettingsActionTypes.Loading, loadAppSettings)
}
