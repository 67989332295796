import "../../common/styles/insight-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideClimateProductGroupsInfoPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import { ClimateProductGroupsInfo } from "@ng-mw/shared-react-components/insight-lazy"
import LazyRender from "@ng-mw/reol/components/LazyRender"

const ClimateProductGroupsInfoPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.climateProductGroupsInfoPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideClimateProductGroupsInfoPopup())}
            isOpen={isOpen}
            className="insight-popup"
        >
            <PopupContent>
                <LazyRender
                    isVisible={isOpen}
                    hiddenClassName=""
                >
                    <ClimateProductGroupsInfo />
                </LazyRender>
            </PopupContent>
        </Popup>
    )
}

export default ClimateProductGroupsInfoPopup
