import "./style/product-list-skeleton.scss"

import React from "react"

import LoadingSkeleton from "@modules/shared/LoadingSkeleton"

interface ProductListSkeletonProps {
    isLoading: boolean
    productCount?: number
    children?: React.ReactNode
}

const ProductListSkeleton = ({
    isLoading,
    productCount = 10,
    children,
}: ProductListSkeletonProps) => {

    const products = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (
        <>
            {isLoading ?
                <ul className="product-list-skeleton">
                    {products.slice(0, productCount).map(item => (
                        <li
                            className="product-list-skeleton__item"
                            key={item}
                        >
                            <div><LoadingSkeleton isLoading={true}><div className="product-list-skeleton__image"></div></LoadingSkeleton></div>
                            <div className="product-list-skeleton__content">
                                <div className="product-list-skeleton__wrapper">
                                    <LoadingSkeleton isLoading={true}><div className="product-list-skeleton__title"></div></LoadingSkeleton>
                                </div>
                                <div className="product-list-skeleton__wrapper">
                                    <LoadingSkeleton isLoading={true}><div className="product-list-skeleton__subtitle"></div></LoadingSkeleton>
                                </div>
                                <div className="product-list-skeleton__wrapper">
                                    <LoadingSkeleton isLoading={true}><div className="product-list-skeleton__price"></div></LoadingSkeleton>
                                </div>
                            </div>
                            <div>
                                <LoadingSkeleton isLoading={true}><div className="product-list-skeleton__quantity-content"></div></LoadingSkeleton>
                            </div>
                        </li>
                    ))}
                </ul>
                : null}
            <LoadingSkeleton isLoading={isLoading}>{children}</LoadingSkeleton>
        </>
    )
}

export default ProductListSkeleton
