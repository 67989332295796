import { NativeEventBridge } from "@ng-mw/shared-react-components"
import { userActions } from "@ng-mw/shared-react-components/user"
import { Store } from "redux"
import PopupNames from "@app/const/PopupNames"
import {
    hideAgreementDeactivationPopup,
    hideAppOnboardingPoster,
    hideAppUpgradePopup,
    hideBarcodePopup,
    hideCategoryFilterPopup,
    hideCheckoutPaymentPopup,
    hideCouponInfoPopup,
    hideFeedbackPopup,
    hideHandoverPickerPopup,
    hideInsightAboutPopup,
    hideInsightKeyFiguresPopup,
    hideProductPopup,
    hidePushConsentsPopup,
    hideQrPopup,
    hideRecipeRatingPopup,
    hideRegistrationPopup,
    hideLoyaltyClubInfoPopup,
    showRegistrationPopup,
} from "@app/store/app/appActions"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import MenyWebAppEvent from "@app/events/MenyWebAppEvent"
import { RootState } from "@app/types"

export default async (store: Store<RootState, any>) => {
    NativeEventBridge.listen(MenyWebAppEvent.CloseMostRecentPopup, (type: typeof PopupNames[keyof typeof PopupNames]) => {
        switch (type) {
            case PopupNames.agreementDeactivationPopup: return store.dispatch(hideAgreementDeactivationPopup())
            case PopupNames.appOnboardingPoster: return store.dispatch(hideAppOnboardingPoster())
            case PopupNames.appUpgradePopup: return store.dispatch(hideAppUpgradePopup())
            case PopupNames.barcodePopup: return store.dispatch(hideBarcodePopup())
            case PopupNames.categoryFilterPopup: return store.dispatch(hideCategoryFilterPopup())
            case PopupNames.checkoutPaymentPopup: return store.dispatch(hideCheckoutPaymentPopup())
            case PopupNames.couponPopup: return store.dispatch(hideCouponInfoPopup())
            case PopupNames.feedbackPopup: return store.dispatch(hideFeedbackPopup())
            case PopupNames.handoverPickerPopup: return store.dispatch(hideHandoverPickerPopup())
            case PopupNames.insightAboutPopup: return store.dispatch(hideInsightAboutPopup())
            case PopupNames.insightKeyFiguresPopup: return store.dispatch(hideInsightKeyFiguresPopup())
            case PopupNames.productPopup: return store.dispatch(hideProductPopup())
            case PopupNames.pushConsentsPopup: return store.dispatch(hidePushConsentsPopup())
            case PopupNames.qrPopup: return store.dispatch(hideQrPopup())
            case PopupNames.recipeRatingPopup: return store.dispatch(hideRecipeRatingPopup())
            case PopupNames.registrationPopup: return store.dispatch(hideRegistrationPopup())
            case PopupNames.loyaltyClubInfoPopup: return store.dispatch(hideLoyaltyClubInfoPopup())
        }
    }, false)

    interface OpenRegistrationPayload {
        phoneNumber?: string
        registrationType?: "trumf" | "corporate"
    }

    NativeEventBridge.listen(MenyNativeEvent.OpenRegistration, (payload?: OpenRegistrationPayload) => {
        // Close Trumf ID login drawer if it's open
        store.dispatch(userActions.hideTrumfIdLoginDrawer())
        // Open registration popup with payload from native
        store.dispatch(showRegistrationPopup(payload))
    }, false)
}
