import "./style/app-layout-top.scss"

import React from "react"
import classNames from "classnames"
import { calcStyle, useSafeLayoutSettings } from "@app/helpers"

interface AppLayoutTopProps {
    children?: React.ReactNode
    isFooterless?: boolean
}

const AppLayoutTop = ({
    children,
    isFooterless,
}: AppLayoutTopProps) => {
    const { safeFooterHeight } = useSafeLayoutSettings()

    return (
        <main
            className={classNames("app-layout-top", {
                "app-layout-top--footerless": isFooterless,
            })}
            style={{ height: calcStyle("100%", isFooterless ? 0 : -safeFooterHeight) }}
        >
            {children}
        </main>
    )
}

export default AppLayoutTop
