export const AppRatingAction = {
    UserNavigated: "app-rating:user-navigated",
    OrderIsConfirmed: "app-rating:order-is-confirmed",
    ShowRatingPopup: "app:show-store-rating-popup",
    HideRatingPopup: "app:hide-store-rating-popup",
}

export const userNavigated = () => {
    return {
        type: AppRatingAction.UserNavigated,
    }
}

export const orderIsConfirmed = () => {
    return {
        type: AppRatingAction.OrderIsConfirmed,
    }
}

export const showRatingPopup = () => {
    return {
        type: AppRatingAction.ShowRatingPopup,
    }
}

export const hideRatingPopup = () => {
    return {
        type: AppRatingAction.HideRatingPopup,
    }
}
