import "./style/maintenance-message.scss"

import React from "react"

// Components
import Alert, { AlertType } from "@ng-mw/reol/components/Alert"
import Container, { Padding } from "./Container"

interface MaintenanceMessageProps {
    title?: string
    body?: string
    operationsIsActive: boolean
    onHide?: () => void
    isUserHidden: boolean
}

const MaintenanceMessage = ({
    title,
    body,
    operationsIsActive,
    onHide,
    isUserHidden,
}: MaintenanceMessageProps) => {
    if (!operationsIsActive || isUserHidden) {
        return null
    }

    return (
        <Container padding={Padding.Reduced}>
            <Alert
                show
                type={AlertType.Warning}
                className="app-maintenance-message"
                onClose={onHide ? onHide : null}
                message={
                    <>
                        <p className="app-maintenance-message__title">
                            {title}
                        </p>
                        <p className="app-maintenance-message__body">
                            {body}
                        </p>
                    </>
                }
            />
        </Container>
    )
}

export default MaintenanceMessage
