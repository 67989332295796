import PathNames from "@app/const/PathNames"

const mapNativeLinkToRoute = (link: string) => {

    if (link.endsWith("min-side/Mine-kuponger1") || link.endsWith("/mer/om-menymer")) {
        return PathNames.Vouchers
    }
    else if (link.endsWith("varer/Tilbud")) {
        return PathNames.GenericOffers
    } else {
        return PathNames.Home
    }

}

export default mapNativeLinkToRoute
