import React from "react"
import { useDispatch, useSelector } from "react-redux"

// Components
import { hideAppUpgradePopup } from "@app/store/app/appActions"
import Popup from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import { AppUpgrade } from "../loaders"
import { RootState } from "@app/types"

const AppUpgradePopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.appUpgradePopup.visible)

    const onClose = () => dispatch(hideAppUpgradePopup())

    return (
        <Popup
            isOpen={isOpen}
            onClose={onClose}
            isSimple
        >
            <LazyRender
                isVisible={isOpen}
                hiddenClassName=""
                className=""
            >
                <AppUpgrade onClose={onClose} />
            </LazyRender>
        </Popup>
    )
}

export default AppUpgradePopup
