import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideLoyaltyClubInfoPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import Popup from "@modules/popup/Popup"
import ScrollView from "@modules/shared/ScrollView"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import LoyaltyClubInfo from "../LoyaltyClubInfo"

const LoyaltyClubInfoPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.loyaltyClubInfoPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideLoyaltyClubInfoPopup())}
            isOpen={isOpen}
            title="Om MENY MER"
        >
            <ScrollView transparent>
                <LazyRender
                    isVisible={isOpen}
                >
                    <LoyaltyClubInfo />
                </LazyRender>
            </ScrollView>
        </Popup>
    )
}
export default LoyaltyClubInfoPopup
