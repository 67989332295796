import { DebugLogger, trackingHelper } from "@ng-mw/shared-react-components"
import { Product } from "./types"
import { AppEnvConfig } from "./types/root-state/appEnvConfig"

if (!window.dataLayer) {
    window.dataLayer = []
}

const CONSOLE_LOG_EVENTS = false

// HELPERS

interface Filter {
    onlyCategory?: string
    onlyCategory2?: string
    onlyCategory3?: string
    onlyCategory4?: string
}

const transformFilter = (filter: Filter = {}) => {
    return {
        ...filter,
        onlyCategory: filter.onlyCategory || "Alle",
        onlyCategory2: filter.onlyCategory2 || "Alle",
        onlyCategory3: filter.onlyCategory3 || "Alle",
        onlyCategory4: filter.onlyCategory4 || "Alle",
        allergens: undefined,
        categories: undefined,
    }
}

const logToConsole = () => {
    if (!CONSOLE_LOG_EVENTS) return

    // eslint-disable-next-line
    DebugLogger.info(
        "%cdataLayer.push",
        "color: green",
        JSON.stringify(window.dataLayer[window.dataLayer.length - 1]),
    )
}

export const navigation = (url: string, userStatus: string, customerType: string, state = {}) => {
    const data = {
        event: "VirtualPageview",
        virtualPageURL: url,
        virtualPageTitle: state,
        loggedInStatus: userStatus,
        customerType: customerType,
    }

    if (data.loggedInStatus) {
        window.dataLayer.push(data)
        logToConsole()
    } else {
        DebugLogger.log("NOT FIRING VirtualPageview: because data.loggedInStatus is falsy: ", data)
    }
}

export const modalOpened = (name: string, state = {}) => {
    const data = {
        event: "ModalOpened",
        modalName: name,
        modalExtraState: state,
        // loggedInStatus: userStatus,
        // customerType: customerType,
    }
    window.dataLayer.push(data)
    logToConsole()
}
export const modalClosed = (name: string, state = {}) => {
    const data = {
        event: "ModalClosed",
        modalName: name,
        modalExtraState: state,
        // loggedInStatus: userStatus,
        // customerType: customerType,
    }
    window.dataLayer.push(data)
    logToConsole()
}

export const search = (query: string, userStatus: string, customerType: string) => {
    navigation(`/søk/?searchQuery=${query}`, userStatus, customerType)
}

export const emptyCart = () => {
    window.dataLayer.push({
        Event: "click",
        eventCategory: "cart-trashcan",
        eventLabel: "remove-all-products-from-cart",
    })

    logToConsole()
}

export const setFilter = (filter: any, location: string) => {
    filter = transformFilter(filter)

    window.dataLayer.push({
        event: "filter",
        eventCategory: location,
        eventLabel: JSON.stringify(filter),
    })

    logToConsole()
}

export const emptySearch = (query: string) => {
    window.dataLayer.push({
        event: "zero-results",
        eventCategory: "search",
        eventLabel: query,
    })

    logToConsole()
}

export const userHasDeliveryStatus = (coverage: boolean) => {
    window.dataLayer.push({
        event: "user",
        eventCategory: "leveringsområde",
        eventLabel: coverage ? "innafor" : "utafor",
    })

    logToConsole()
}

export const changeDeliveryDetailsPreemptively = () => {
    window.dataLayer.push({
        event: "leveringsdetaljer",
        eventCategory: "ecommerce",
        eventLabel: "forandret-fra-svart-toppbanner",
    })

    logToConsole()
}

export const offerCampaignsView = (campaigns: any) => {
    window.dataLayer.push({
        ecommerce: {
            promoView: {
                promotions: campaigns.map((c: any, index: number) => {
                    return {
                        id: c.campaignId,
                        name: c.marketTextLong || c.marketText,
                        creative: c.imageId,
                        position: index,
                    }
                }),
            },
        },
    })

    logToConsole()
}

export const productView = (product: Product) => {
    window.dataLayer.push({
        ecommerce: {
            detail: {
                products: [trackingHelper.getProductFieldObject(product)],
            },
        },
    })

    logToConsole()
}

export const productsView = (products: Product[], filter: any, location: string) => {
    filter = transformFilter(filter)

    window.dataLayer.push({
        ecommerce: {
            filter,
            impressions: products.map((product, position) => trackingHelper.getProductFieldObject(product, { list: location, position })),
        },
    })

    logToConsole()
}

export const homeSearchClick = () => {
    window.dataLayer.push({
        event: "click",
        eventCategory: "home search",
        eventLabel: "magnifier",
    })

    logToConsole()
}

export const searchIconClick = () => {
    window.dataLayer.push({
        event: "click",
        eventCategory: "search",
        eventLabel: "magnifier",
    })

    logToConsole()
}

export const pushNotification = (action: string) => {
    const data = {
        event: "pushNotificationRedirect",
        action: action,
    }
    window.dataLayer.push(data)
    logToConsole()
}

export const nativeAppLink = (linkData: any) => {
    const data = {
        event: "nativeAppLink",
        linkData,
    }
    window.dataLayer.push(data)
    logToConsole()
}

// CHECKOUT

export const checkoutOption = trackingHelper.checkoutOption
export const checkout = trackingHelper.checkout

//QR

export const qrCodeDisplayed = () => {
    window.dataLayer.push({
        eventCategory: "Trumf-kort",
        event: "qrCodeDisplayed",
        eventLabel: "Viste Trumf-kort",
    })

    logToConsole()
}

export const startUpMetricsJs = () => {
    const data = {
        event: "StartUpTiming-html",
        ms: window.performanceTimings.html,
        delta: window.performanceTimings.html,
    }
    window.dataLayer.push(data)
    logToConsole()

    const data2 = {
        event: "StartUpTiming-js",
        ms: window.performanceTimings.js,
        delta: window.performanceTimings.js - window.performanceTimings.html,
    }
    window.dataLayer.push(data2)
    logToConsole()
}

export const startUpMetricsSplash = () => {
    const data = {
        event: "StartUpTiming-splash",
        ms: window.performanceTimings.splash,
        delta: window.performanceTimings.splash - window.performanceTimings.js,
    }
    window.dataLayer.push(data)
    logToConsole()
}

export const nativeTrackingData = (data: any) => {
    if (data) {
        window.dataLayer.push({
            ...data,
            event: data.event || "UnknownNativeWrapperEvent",
        })
    }

    logToConsole()
}

export const webAppConfigData = (data: any) => {
    if (data) {
        window.dataLayer.push({
            ...data,
            event: "WebAppConfig",
        })
    }

    logToConsole()
}

export function nativeConfigData(data: AppEnvConfig) {
    if (data) {
        window.dataLayer.push({
            ...data,
            event: "NativeConfig",
        })
    }
}

export function updateWebAppClick() {
    window.dataLayer.push({
        event: "click",
        eventCategory: "update web app",
        eventLabel: "Oppdater",
    })

    logToConsole()
}
