/**
 * Maps from the dropdown backing value in Epi to a text description of
 * a recipe preparation time.
 * @param {number} value Backing value from Epi.
 * @returns {string} Text description of the preparation time.
 */
export const mapPreparationTime = (value: number): string => {
    switch (value) {
        case 1:
            return "20 min"
        case 2:
        default:
            return "20-40 min"
        case 3:
            return "40-60 min"
        case 4:
            return "60 min"
    }
}
