import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RecipeItem, RootState } from "@app/types"
import { resolveRecipeItem } from "@ng-mw/shared-react-components/recipes"

// Components
import Recipe from "../Recipe"
import RecipeSummary from "../RecipeSummary"
import Popup, { PopupContent } from "@modules/popup/Popup"
import { hideRecipePopup } from "@app/store/app/appActions"

const RecipePopup = () => {
    const dispatch = useDispatch<any>()
    const recipes = useSelector((state: RootState) => state.recipes)

    const isOpen = useSelector((state: RootState) => state.app.recipePopup.visible)
    const recipeId = useSelector((state: RootState) => state.app.recipePopup.recipeId)

    const handleClose = () => dispatch(hideRecipePopup())

    const recipeItem: RecipeItem = resolveRecipeItem({
        recipes: recipes,
        recipeId: recipeId,
    })

    return (
        <Popup
            isOpen={isOpen}
            onClose={handleClose}
            title={recipeItem?.rawData?.name}
            buttons={recipeItem ? <RecipeSummary recipeItem={recipeItem} /> : null}
        >
            <PopupContent>
                <Recipe
                    recipeItem={recipeItem}
                    recipeId={recipeId}
                />
            </PopupContent>
        </Popup>

    )
}

export default RecipePopup
