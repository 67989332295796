import React from "react"
import Loadable from "react-loadable"

export const InsightKeyFiguresInfo = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "./InsightKeyFiguresInfo"),
    loading: () => <div />,
})

export const DietInsight = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "./DietInsight"),
    loading: () => <div />,
    delay: 0,
})

export { DietPropertyInfo, DietEnergyInfo } from "@ng-mw/shared-react-components/diet"
