import "./style/popup-title.scss"

import classNames from "classnames"
import React from "react"

interface PopupTitleProps {
    children: React.ReactNode
    id?: string
    illustration?: React.ReactNode | React.JSX.Element
    isHidden?: boolean
    isSimple?: boolean
}

export const PopupTitle = ({
    children,
    id,
    illustration,
    isHidden,
    isSimple,
}: PopupTitleProps) => {
    return (
        <>
            {illustration ? (
                <div className="app-popup-title__illustration">
                    {illustration}
                </div>
            ) : null}
            <h2
                className={classNames("app-popup-title__text", {
                    "app-popup-title__text--simple": isSimple,
                    "ws-visually-hidden": isHidden,
                })}
                id={id}
            >
                {children}
            </h2>
        </>
    )
}

export default PopupTitle
