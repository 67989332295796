import "../style/activate-loyalty-club-popup.scss"

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import { showActivateLoyaltyClubPopup, hideActivateLoyaltyClubPopup, showLogOutPopup } from "@app/store/app/appActions"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { useLoyaltyClubStatus } from "../helpers"

// Components
import Popup from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Button from "@ng-mw/reol/components/Button"
import PopupContent from "@modules/popup/PopupContent"
import { AgreementWrapper } from "@ng-mw/shared-react-components/lazy-loaders"
import ActivateLoyaltyClubButton from "../ActivateLoyaltyClubButton"

const ActivateLoyaltyClubPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.activateLoyaltyClubPopup.visible)
    const isOnboardingOpen = useSelector((state: RootState) => state.app.appOnboardingPoster.visible)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)

    const { isLoyaltyClubMember = null, isConsentsLoading, isConsentsInitialized } = useLoyaltyClubStatus()

    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)

    useEffect(() => {
        if (isConsentsInitialized && !isConsentsLoading && userIsLoggedIn && !isOnboardingOpen && !isLoyaltyClubMember && !userIsCorporate) {

            const timeout = setTimeout(() => {
                dispatch(showActivateLoyaltyClubPopup())
            }, 1000)

            return () => clearTimeout(timeout)
        }

    }, [isLoyaltyClubMember, dispatch, isConsentsInitialized, isConsentsLoading, userIsLoggedIn, isOnboardingOpen, userIsCorporate])

    useEffect(() => {
        if (isOpen && (!userIsLoggedIn || isLoyaltyClubMember)) {
            dispatch(hideActivateLoyaltyClubPopup())
        }

    }, [isOpen, dispatch, isLoyaltyClubMember, userIsLoggedIn])

    const handleClose = () => {
        const logoutData = {
            title: "Du kan også bli MENY MER-kunde senere",
            text: "Ved å fortsette vil du bli logget ut av appen. Du kan fortsatt bruke appen til å sjekke tilbud og legge til varer i handlevognen for å bruke den som handleliste i butikk.",
            buttonText: "Skjønner, logg meg ut",
        }

        dispatch(showLogOutPopup(logoutData))
    }

    const config = {
        agreement: {
            id: AppGlobalSettings.loyaltyClubAgreementId, // Meny kundeklubb
        },
        title: "Som MENY MER-kunde får du mer Trumf-bonus",
        activationText: "Aktiver MENY MER",
        chainId: "1300",
        benefitsList: ["10% velkomstbonus på første handel", "Ekstra Trumf-bonus på utvalgte varer", "Tilbudskuponger"],
        benefitsTitle: "Dette får du som MENY kundeklubb-kunde",
        isActivatorButtonHidden: true,
        description: "<p>Ved å bli MENY kundeklubb-kunde kan du spare mer Trumf-bonus på utvalgte dagligvarer hos MENY. Du vil også motta nyhetsbrev på e-post og SMS fra MENY. Dette kan du senere administrere i valgene på profilsiden. </p>",
        descriptionActivated: "<p>Du har nå blitt MENY kundeklubb-kunde og får tilgang på fordeler fra MENY.</p><p>Og forresten, har du husket å laste ned MENY-appen? Der finner du alle fordelene på én plass!</p><p>Last ned MENY appen og få oversikt over alle fordelene dine, MENY MER-tilbud, og personlige kuponger!</p>",
        activatedMobileImageUrl: "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_800/ulcf9km7wqwhllqy4wv6",
        mobileImageUrl: "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_800/gr2vycsqnztatp9w0tzl",
        desktopImageUrl: "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_800/gr2vycsqnztatp9w0tzl",
        activatedDesktopImageUrl: "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_800/uhfaf3qz99q8xznibzot",
        googlePlayAppUrl: "https://play.google.com/store/apps/details?id=no.norgesgruppen.apps.meny",
        appleStoreAppUrl: "https://apps.apple.com/no/app/meny/id662833115",
    }

    return (
        <Popup
            isOpen={isOpen && !isOnboardingOpen}
            isSwipeToCloseDisabled
            isForcedFullscreen
            popupAnimation="slide-left"
            buttons={
                <>
                    <ActivateLoyaltyClubButton />
                    <Button
                        onClick={handleClose}
                        isCancel
                        text="Ikke nå"
                    />
                </>
            }
        >
            <LazyRender
                isVisible={isOpen}
                className="activate-loyalty-club-popup"
            >
                <PopupContent>
                    <AgreementWrapper config={config} />
                </PopupContent>
            </LazyRender>
        </Popup>
    )
}

export default ActivateLoyaltyClubPopup
