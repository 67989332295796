import { DebugLogger } from "@ng-mw/shared-react-components"
import { useEffect } from "react"

const MountNotifier = () => {
    useEffect(() => {
        // when this has mounted, everything should be in place for viewing,
        // but lets do it next render tick
        DebugLogger.log("Load-order-debug: MountNotifier mounted")
        setTimeout(() => {
            DebugLogger.log("Load-order-debug: MountNotifier did mount")
        })
    }, [])

    return null
}

export default MountNotifier
