import "./style/app-layout-main.scss"

import React from "react"

interface AppLayoutMainProps {
    children?: React.ReactNode
}

const AppLayoutMain = ({
    children,
}: AppLayoutMainProps) => {
    return (
        <div
            className="app-layout-main"
        >
            {children}
        </div>
    )
}

export default AppLayoutMain
