import "./style/editorial-content.scss"

import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "@app/types"
import { getEditorialContent } from "./store/actions/editorialContentActions"

import ContentBlock from "./ContentBlock"
import HorizontalScroller from "@modules/shared/HorizontalScroller"
import Container, { Content } from "@modules/shared/Container"

const EditorialContent = () => {
    const dispatch = useDispatch<any>()
    const appContent = useSelector((state: RootState) => state.appContent)

    useEffect(() => {
        dispatch(getEditorialContent())
    }, [dispatch])

    const contentBlockItems = appContent?.data && appContent.data.filter((block: any) => block.Type === "ContentBlock")

    if (!contentBlockItems?.length) return null

    return (
        <div className="editorial-content">
            <Container
                title="Utforsk"
                content={Content.FullWidth}
                from="Utforsk link. Home page"
            >
                <HorizontalScroller
                    singleItemFillter
                    height={220}
                >
                    {contentBlockItems.map((block: any, i: number) => {
                        const key = `content-${i + 1}`
                        return block.Type === "ContentBlock" ? (
                            <ContentBlock
                                content={block.Content}
                                id={key}
                                key={key}
                            />
                        ) : null
                    })}
                </HorizontalScroller>
            </Container>
        </div>
    )
}

export default EditorialContent
