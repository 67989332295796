import { GenericAction } from "@app/types"
import { QrAction } from "../actions/creators/qrCreator"

const INITIAL_STATE = {
    loading: false,
    data: {},
    error: false,
}

export default (state = INITIAL_STATE, action: GenericAction) => {
    switch (action.type) {
        case QrAction.QrLoading:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case QrAction.QrLoaded:
            return {
                ...state,
                loading: false,
                error: false,
            }
        case QrAction.QrSuccess:
            return {
                ...state,
                loading: false,
                error: false,
                data: action.payload,
            }
        case QrAction.QrError:
            return action.payload ? {
                ...state,
                error: {
                    message: action.payload,
                },
            } : { ...state, error: true }
        case QrAction.QrClear:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}
