import { DebugLogger, App } from "@ng-mw/shared-react-components"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

const useNativeBuildNumber = () => {

    const {
        version,
        buildNumber,
        // os,
        // osVersion,
        // isProdLogin,
        // isTestTrumfId,
        // isTrumfIdEnabled,
        // isTrumfPayEnabled,
        // urls,
    } = useSelector((state: RootState) => state.appEnvConfig)

    // const realNativeBuildNumber = useSelector(state => state.app.nativeBuildNumber)
    const debugMode = window.location.search.indexOf("debug") >= 0
    const nativeBuildNumber = buildNumber === null && debugMode ? 999 : buildNumber
    const notLegacy = version !== "legacy"

    const supportsTelLinks = notLegacy || (App.Context.Android ? nativeBuildNumber >= 63 : nativeBuildNumber >= 118)
    const supportsRating = notLegacy || (App.Context.Android ? nativeBuildNumber >= 70 : nativeBuildNumber >= 125)
    const supportsScanner = notLegacy || (App.Context.Android ? nativeBuildNumber >= 70 : nativeBuildNumber >= 125)
    const supportsComplaints = notLegacy || (App.Context.Android ? nativeBuildNumber >= 72 : nativeBuildNumber >= 122)
    const supportsTrumfQr = notLegacy || (App.Context.Android ? nativeBuildNumber >= 110 : nativeBuildNumber >= 173)

    DebugLogger.log("Version:", { version, buildNumber, notLegacy, supportsTelLinks, supportsRating, supportsScanner, supportsComplaints, supportsTrumfQr })

    return { supportsTelLinks, supportsRating, supportsScanner, supportsComplaints, supportsTrumfQr }
}

export default useNativeBuildNumber
