import "./style/recipe.scss"

import React from "react"

// Components
import { Image } from "@ng-mw/shared-react-components/shared"
import { getViewportScaledImageConf } from "@ng-mw/shared-meny-components/src/data/MenyImageConfig"
import { RecipeItem } from "@app/types"
import { Recipe as SharedRecipe } from "@ng-mw/shared-meny-components/recipes"

export interface RecipeProps {
    recipeItem: RecipeItem
    recipeId?: string
}

const Recipe = ({
    recipeItem,
    recipeId,
}: RecipeProps) => {
    return (
        <div className="recipe">
            <div className="recipe__image">
                <Image
                    filename={recipeItem?.rawData?.media?.imageId}
                    config={getViewportScaledImageConf({
                        maxWidth: 800,
                        aspect: 2 / 1,
                        ratio: 0.8,
                    })}
                    objectFit="cover"
                />
            </div>
            <SharedRecipe
                recipeId={recipeId}
                showTip
            />
        </div>
    )
}

export default Recipe
