import "./style/recipes-list.scss"

import React, { useEffect } from "react"

import { getAllFavoriteRecipes } from "@ng-mw/shared-meny-components/src/modules/recipes/store/actions/favoriteRecipesActions"
import HorizontalScroller from "@modules/shared/HorizontalScroller"
import RecipeItem from "@modules/recipes/RecipeItem"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import Container, { Content } from "@modules/shared/Container"
import Icon from "@ng-mw/reol/components/Icon"
import crabIcon from "@resources/icons/crab"
import PathNames from "@app/const/PathNames"
import AppLink from "@modules/shared/AppLink"

interface RecipesList {
    title?: string
    linkText?: string
    to?: string
    state?: object
}

const RecipesList = ({
    title,
    linkText,
    to,
    state,
}: RecipesList) => {
    const dispatch = useDispatch<any>()

    const favoriteRecipes = useSelector((state: RootState) => state.favoriteRecipes)
    const user = useSelector((state: RootState) => state.user)
    const Wrapper = title ? Container : "div"

    useEffect(() => {
        if (user.loginLevel >= 1) {
            dispatch(getAllFavoriteRecipes())
        }
    }, [dispatch, user.loginLevel])

    const favoredRecipes = favoriteRecipes.data.favoredRecipes

    return (
        <>
            {favoredRecipes?.length > 0 ? (
                <Wrapper
                    title={title}
                    linkText={linkText}
                    to={to}
                    content={Content.FullWidth}
                    state={state}
                >
                    <HorizontalScroller
                        height={300}
                    >
                        {favoredRecipes.slice(0, 6).map((recipe, index) => (
                            <RecipeItem
                                recipe={recipe}
                                key={recipe.id + index}
                            />
                        ))}
                    </HorizontalScroller>
                </Wrapper>
            ) : (
                <Container
                    title={title}
                    linkText={linkText}
                    to={to}
                    state={state}
                >
                    <div className="recipes-list">
                        <p>Se ukemenyen, eller lagre dine favorittoppskrifter så du finner de igjen her.</p>
                        <div className="recipes-list__footer">
                            <AppLink
                                skin="link"
                                to={PathNames.RecipesHome}
                            >
                                Se ukemenyen
                            </AppLink>
                            <Icon
                                icon={crabIcon}
                                size={{ width: "68px", height: "68px" }}
                            />
                        </div>
                    </div>
                </Container>
            )}
        </>
    )
}

export default RecipesList

