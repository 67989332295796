import { DebugLogger } from "@ng-mw/shared-react-components"
import React from "react"
import { Route } from "react-router-dom"
import NotFound from "@modules/shared/NotFound"
import AppLayoutTop from "@modules/framework/AppLayoutTop"
import Loadable from "react-loadable"
import PathNames from "./const/PathNames"
import * as tracking from "@app/tracking"

// These are loaded instantly anyway so there is no reason to lazy load them:
import HomePage from "@modules/home/pages/HomePage"

window.performanceTimings.js = performance.now()
DebugLogger.log("Load-order-debug: (routes.js executed) Script load time:", window.performanceTimings.js, "ms")
console.timeLog("Load-order-debug-TIMER")
tracking.startUpMetricsJs()

let i = 0

const CouponsPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/loyalty-club/pages/CouponsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const LoyaltyClubPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/loyalty-club/pages/LoyaltyClubPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const CampaignPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/savings/pages/CampaignPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const CartPage = Loadable({
    loader: () => import( /* webpackChunkName: "cartpage" */ "@modules/cart/pages/CartPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const CheckoutPage = Loadable({
    loader: () => import( /* webpackChunkName: "checkoutpage" */ "@modules/checkout/pages/CheckoutPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const SavingsPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/loyalty-club/pages/SavingsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const OffersPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/savings/pages/OffersPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ShoppingListsHomePage = Loadable({
    loader: () => import( /* webpackChunkName: "handlelister" */ "@modules/shopping-lists/pages/ShoppingListsHomePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const ShoppingListPage = Loadable({
    loader: () => import( /* webpackChunkName: "handlelister" */  "@modules/shopping-lists/pages/ShoppingListItemPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const SearchPage = Loadable({
    loader: () => import( /* webpackChunkName: "Search" */ "@modules/products/pages/SearchPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ContactDetailsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/ContactDetailsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const TrumfProfilePage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfProfilePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const TrumfMemberPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfMemberPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const TrumfBankCardsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfBankCardsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const TrumfCardsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfCardsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const TrumfChainConsentsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfChainConsentsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,

})

const TrumfAssociatesPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/TrumfAssociatesPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const UserManageBiometricsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/UserManageBiometricsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const NotificationSettingsPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/NotificationSettingsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const UseBonusPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/UseBonusPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const UseBonusToBankPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/UseBonusToBankPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const UseBonusToCharityPage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */ "@modules/user/pages/UseBonusToCharityPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const DebugPage = Loadable({
    loader: () => import( /* webpackChunkName: "debug" */ "@modules/debug/pages/DebugPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const AccessibilityPlaygroundPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/debug/pages/AccessibilityPlaygroundPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ComponentPlaygroundPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/debug/pages/ComponentPlaygroundPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const BargainsPage = Loadable({
    loader: () => import( /* webpackChunkName: "savings" */ "@modules/savings/pages/BargainsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const OrderItemPage = Loadable({
    loader: () => import( /* webpackChunkName: "purchases" */  "@modules/orders/pages/OrderItemPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const UserProfilePage = Loadable({
    loader: () => import( /* webpackChunkName: "users" */  "@modules/user/pages/UserProfilePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const OrderHistoryPage = Loadable({
    loader: () => import( /* webpackChunkName: "purchases" */  "@modules/orders/pages/OrderHistoryPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const PurchaseHistoryPage = Loadable({
    loader: () => import( /* webpackChunkName: "purchases" */  "@modules/orders/pages/PurchaseHistoryPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const PurchaseComplaintPage = Loadable({
    loader: () => import( /* webpackChunkName: "purchases" */  "@modules/orders/pages/PurchaseComplaintPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const RecipesHomePage = Loadable({
    loader: () => import( /* webpackChunkName: "recipes" */ "@modules/recipes/pages/RecipesHomePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const CategoryPage = Loadable({
    loader: () => import( /* webpackChunkName: "Search" */ "@modules/products/pages/CategoryPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ChatPage = Loadable({
    loader: () => import( /* webpackChunkName: "chatpage" */ "@modules/debug/pages/ChatPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const CustomerServicePage = Loadable({
    loader: () => import( /* webpackChunkName: "customerservice" */ "@modules/user/pages/CustomerServicePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const HealthInspirationPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/HealthInspirationPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietHomePage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietHomePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const DietOverviewPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietOverviewPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietMacroNutrientsPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietMacroNutrientsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietLevelOnePage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietLevelOnePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietLevelTwoPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietLevelTwoPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietLevelThreePage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietLevelThreePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const DietLevelFourPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/diet/pages/DietLevelFourPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ClimateHomePage = Loadable({
    loader: () => import( /* webpackChunkName: "climateinsight" */ "@modules/insight/climate/pages/ClimateHomePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const ClimateInspirationPage = Loadable({
    loader: () => import( /* webpackChunkName: "dietinsight" */ "@modules/insight/climate/pages/ClimateInspirationPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})
const ClimateProductGroupsPage = Loadable({
    loader: () => import( /* webpackChunkName: "climateinsight" */ "@modules/insight/climate/pages/ClimateProductGroupsPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ClimateLevelTwoPage = Loadable({
    loader: () => import( /* webpackChunkName: "climateinsight" */ "@modules/insight/climate/pages/ClimateLevelTwoPage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

const ClimateLevelThreePage = Loadable({
    loader: () => import( /* webpackChunkName: "climateinsight" */ "@modules/insight/climate/pages/ClimateLevelThreePage"),
    loading: () => <AppLayoutTop />,
    delay: 0,
})

// Make sure all routes preload and are ready from the get go.
// Also fixes transition-animation bug for first render.
for (const component of [
    AccessibilityPlaygroundPage,
    BargainsPage,
    CampaignPage,
    CartPage,
    CategoryPage,
    ChatPage,
    CheckoutPage,
    ClimateHomePage,
    ClimateInspirationPage,
    ClimateLevelThreePage,
    ClimateLevelTwoPage,
    ClimateProductGroupsPage,
    ComponentPlaygroundPage,
    ContactDetailsPage,
    CouponsPage,
    CustomerServicePage,
    DebugPage,
    DietHomePage,
    DietLevelFourPage,
    DietLevelOnePage,
    DietLevelThreePage,
    DietLevelTwoPage,
    DietMacroNutrientsPage,
    DietOverviewPage,
    HealthInspirationPage,
    NotificationSettingsPage,
    OffersPage,
    OrderHistoryPage,
    OrderItemPage,
    PurchaseComplaintPage,
    PurchaseHistoryPage,
    RecipesHomePage,
    SearchPage,
    ShoppingListPage,
    ShoppingListsHomePage,
    TrumfAssociatesPage,
    TrumfBankCardsPage,
    TrumfCardsPage,
    TrumfChainConsentsPage,
    TrumfMemberPage,
    TrumfProfilePage,
    UseBonusPage,
    UseBonusToBankPage,
    UseBonusToCharityPage,
    UserManageBiometricsPage,
    UserProfilePage,
]) {
    component.preload()
}

export default [
    <Route
        path={PathNames.Home}
        key={i++}
        component={HomePage}
        exact
    />,
    <Route
        path={PathNames.Debug}
        key={i++}
        component={DebugPage}
        exact
    />,
    <Route
        path={PathNames.AccessibilityPlayground}
        key={i++}
        component={AccessibilityPlaygroundPage}
        exact
    />,
    <Route
        path={PathNames.ComponentPlayground}
        key={i++}
        component={ComponentPlaygroundPage}
        exact
    />,
    <Route
        path={PathNames.Category}
        key={i++}
        component={CategoryPage}
    />,
    <Route
        path={PathNames.Cart}
        key={i++}
        component={CartPage}
        exact
    />,
    <Route
        path={PathNames.Checkout}
        key={i++}
        component={CheckoutPage}
        exact
    />,
    <Route
        path={PathNames.ContactDetails}
        key={i++}
        component={ContactDetailsPage}
        exact
    />,
    <Route
        path={PathNames.TrumfProfile}
        key={i++}
        component={TrumfProfilePage}
        exact
    />,
    <Route
        path={PathNames.TrumfProfile}
        key={i++}
        component={TrumfProfilePage}
        exact
    />,
    <Route
        path={PathNames.TrumfMember}
        key={i++}
        component={TrumfMemberPage}
        exact
    />,
    <Route
        path={PathNames.TrumfBankCards}
        key={i++}
        component={TrumfBankCardsPage}
        exact
    />,
    <Route
        path={PathNames.TrumfTrumfCards}
        key={i++}
        component={TrumfCardsPage}
        exact
    />,
    <Route
        path={PathNames.TrumfChainConsents}
        key={i++}
        component={TrumfChainConsentsPage}
        exact
    />,
    <Route
        path={PathNames.TrumfAssociates}
        key={i++}
        component={TrumfAssociatesPage}
        exact
    />,
    <Route
        path={PathNames.UserManageBiometrics}
        key={i++}
        component={UserManageBiometricsPage}
        exact
    />,
    <Route
        path={PathNames.NotificationSettings}
        key={i++}
        component={NotificationSettingsPage}
        exact
    />,
    <Route
        path={PathNames.ShoppingListsHome}
        key={i++}
        component={ShoppingListsHomePage}
        exact
    />,
    <Route
        path={PathNames.ShoppingListPage}
        key={i++}
        component={ShoppingListPage}
    />,
    <Route
        path={PathNames.Savings}
        key={i++}
        component={SavingsPage}
        exact
    />,
    <Route
        path={PathNames.BargainOffers}
        key={i++}
        component={BargainsPage}
        exact
    />,
    <Route
        path={PathNames.GenericOffers}
        key={i++}
        component={OffersPage}
        exact
    />,
    <Route
        path={PathNames.Campaign}
        key={i++}
        component={CampaignPage}
        exact
    />,
    <Route
        path={PathNames.Vouchers}
        key={i++}
        component={CouponsPage}
        exact
    />,
    <Route
        path={PathNames.LoyaltyClub}
        key={i++}
        component={LoyaltyClubPage}
        exact
    />,
    <Route
        path={PathNames.OrderItem}
        key={i++}
        component={OrderItemPage}
    />,
    <Route
        path={PathNames.OrderHistory}
        key={i++}
        component={OrderHistoryPage}
        exact
    />,
    <Route
        path={PathNames.PurchaseHistory}
        key={i++}
        exact
    >
        <PurchaseHistoryPage />
    </Route>,
    <Route
        path={PathNames.PurchaseComplaint}
        key={i++}
        component={PurchaseComplaintPage}
        exact
    />,
    <Route
        path={PathNames.UseBonus}
        key={i++}
        component={UseBonusPage}
        exact
    />,
    <Route
        path={PathNames.UseBonusToCharity}
        key={i++}
        component={UseBonusToCharityPage}
        exact
    />,
    <Route
        path={PathNames.UseBonusToBank}
        key={i++}
        component={UseBonusToBankPage}
        exact
    />,
    <Route
        path={PathNames.UserProfile}
        key={i++}
        component={UserProfilePage}
        exact
    />,
    <Route
        path={PathNames.RecipesHome}
        key={i++}
        component={RecipesHomePage}
    />,
    <Route
        path={PathNames.ChatPage}
        key={i++}
        component={ChatPage}
        exact
    />,
    <Route
        path={PathNames.CustomerServicePage}
        key={i++}
        component={CustomerServicePage}
        exact
    />,
    <Route
        path={PathNames.HealthInspiration}
        key={i++}
        component={HealthInspirationPage}
        exact
    />,
    <Route
        path={PathNames.DietOverview}
        key={i++}
        component={DietOverviewPage}
        exact
    />,
    <Route
        path={PathNames.DietMacroNutrients}
        key={i++}
        component={DietMacroNutrientsPage}
        exact
    />,
    <Route
        path={PathNames.DietLevelOne}
        key={i++}
        component={DietLevelOnePage}
        exact
    />,
    <Route
        path={PathNames.DietLevelTwo}
        key={i++}
        component={DietLevelTwoPage}
        exact
    />,
    <Route
        path={PathNames.DietLevelThree}
        key={i++}
        component={DietLevelThreePage}
        exact
    />,
    <Route
        path={PathNames.DietLevelFour}
        key={i++}
        component={DietLevelFourPage}
        exact
    />,
    <Route
        path={PathNames.DietHome}
        key={i++}
        component={DietHomePage}
        exact
    />,
    <Route
        path={PathNames.ClimateInspiration}
        key={i++}
        component={ClimateInspirationPage}
        exact
    />,
    <Route
        path={PathNames.ClimateProductGroups}
        key={i++}
        component={ClimateProductGroupsPage}
        exact
    />,
    <Route
        path={PathNames.ClimateLevelTwo}
        key={i++}
        component={ClimateLevelTwoPage}
        exact
    />,
    <Route
        path={PathNames.ClimateLevelThree}
        key={i++}
        component={ClimateLevelThreePage}
        exact
    />,
    <Route
        path={PathNames.ClimateHome}
        key={i++}
        component={ClimateHomePage}
        exact
    />,
    <Route
        path={PathNames.Search}
        key={i++}
        component={SearchPage}
        exact
    />,
    <Route
        key={i++}
        render={({ location }) => (
            <NotFound
                homeUrl={PathNames.Search}
                currentUrl={location.pathname}
            />
        )}
        exact
    />,
]
