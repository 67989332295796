import "../style/user-qr.scss"

import { NativeEventBridge } from "@ng-mw/shared-react-components"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideQrPopup } from "@app/store/app/appActions"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import { RootState } from "@app/types"
import { qrError, qrLoaded, qrLoading } from "../store/actions/creators/qrCreator"

// Components
import Popup, { PopupContent } from "@modules/popup/Popup"
import { TrumfQrCode } from "@ng-mw/shared-react-components/trumf-qr-code"
import MessageAfterEvent from "@modules/shared/MessageAfterEvent"
import Button from "@ng-mw/reol/components/Button"

const MAX_QR_CODE_RESPONSE_TIME = 4000 // how long in ms we're willing to wait for the code before showing error message

export const UserQrPopup = () => {
    const dispatch = useDispatch<any>()

    const isOpen = useSelector((state: RootState) => state.app.qrPopup.visible)
    const online = useSelector((state: RootState) => state.native.online)
    const { loading, data, error } = useSelector((state: RootState) => state.qr)
    const code = data.code
    const noErrors = !error

    const handleClose = () => {
        // fist reset loaded and error status so we don't get stuck with error and so that we retry next time:
        dispatch(qrLoaded())
        // then close:
        dispatch(hideQrPopup())
    }

    const handleGetTrumfQrCode = () => {
        dispatch(qrLoading()) // set loading status so that we don't show the error message while waiting
        NativeEventBridge.broadcastNative(MenyNativeEvent.GetTrumfQrCode)
    }

    useEffect(() => {
        // If we don't already have a code, and are not currently loading, try fetching it now
        if (isOpen && !code && !loading && noErrors) {
            dispatch(qrLoading()) // set loading status so that we don't show the error message while waiting
            NativeEventBridge.broadcastNative(MenyNativeEvent.GetTrumfQrCode)

            // Uncomment to debug errors locally
            // setTimeout(() => {
            //     NativeEventBridge.broadcastNative(MenyNativeEvent.ListenForTrumfQrCode, { code: "null" })
            // }, 200)
        }
    }, [isOpen, code, noErrors, dispatch, loading])

    useEffect(() => {
        let t: NodeJS.Timeout
        // if we are loading, wait some seconds and if nothing happens, show error
        if (isOpen && loading && noErrors) {
            t = setTimeout(() => {
                dispatch(qrError("Timeout"))
            }, MAX_QR_CODE_RESPONSE_TIME)
        }
        return () => {
            clearTimeout(t)
        }
    }, [dispatch, isOpen, loading, noErrors])

    return (
        <Popup
            onClose={handleClose}
            isOpen={isOpen}
            buttons={noErrors ? null : <Button onClick={handleGetTrumfQrCode}>Prøv på nytt</Button>}
        >
            {noErrors ? null : (
                <MessageAfterEvent
                    localStorageKey="INLINE_DEBUG_OUTPUT"
                    maxMinutesAgo={30}
                    message={`QR-error: ${JSON.stringify(error)}`}
                />
            )}
            <PopupContent>
                <TrumfQrCode
                    code={code || ""}
                    onClose={handleClose}
                    isOnline={online}
                    loading={loading}
                    error={error}
                />
            </PopupContent>
        </Popup>
    )
}

export default UserQrPopup
