import "./style/app-status.scss"

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import Alert, { AlertType } from "@ng-mw/reol/components/Alert"

let appHasLostNetwork = 0

const AppNetworkStatus = () => {
    const isOnline = useSelector((state: RootState) => state.native.online)
    const [isReload, setIsReload] = useState(false)

    useEffect(() => {
        if (!isOnline) {
            appHasLostNetwork++
        }

        if (appHasLostNetwork > 0 && isOnline) {
            setIsReload(true)
        } else { setIsReload(false) }

    }, [isOnline])

    const handleReloadApp = () => {
        window.location.reload()
    }

    if (isOnline && !isReload) {
        return null
    }

    return (
        <Alert
            show
            type={isReload ? AlertType.Success : AlertType.Warning}
            className="app-status"
            retryText={isReload ? "Restart appen" : null}
            onRetryClick={isReload ? handleReloadApp : null}
            message={isReload ? "Nettverkstilkobling er tilbake!" : "Mangler nettverkstilkobling!"}
        />
    )
}

export default AppNetworkStatus
