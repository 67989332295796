import "./style/app-shopping-lists.scss"

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { shoppingListsActions } from "@ng-mw/shared-react-components/shopping-lists"
import * as appAction from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Icons
import cinnamonBunsIcon from "@resources/icons/cinnamonBuns"

// Components
import Button from "@ng-mw/reol/components/Button"
import Icon from "@ng-mw/reol/components/Icon"
import ShoppingListLink from "./ShoppingListLink"
import LoadingSkeleton from "@modules/shared/LoadingSkeleton"

export interface ShoppingListsProps {
    maxNumberOfLists?: number
    isCreateButtonVisible?: boolean
    from?: string
}

const ShoppingLists = ({
    maxNumberOfLists = 99,
    isCreateButtonVisible = true,
    from,
}: ShoppingListsProps) => {
    const dispatch = useDispatch<any>()
    const { loading, error, data } = useSelector((state: RootState) => state.shoppingLists)
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loginLevel > 0)
    useEffect(() => {
        if (userIsLoggedIn && !data) {
            dispatch(shoppingListsActions.getAllShoppingLists())
        }
    }, [dispatch, userIsLoggedIn, data])

    const handleCreateList = () => {

        if (userIsLoggedIn) {
            dispatch(appAction.showShoppingListCreatePopup())
        } else {
            const loginData = {
                title: "Logg inn med Trumf for å lage handlelister",
                text: "Har du en fast ukes- eller helgehandel? Eller egne oppskrifter og favorittprodukter som du vil legge i handlevognen til senere? Lagre dem som handlelister og gjør handelen enda enklere!",
            }
            dispatch(appAction.showLoginPopup(loginData))
        }
    }

    const shoppingLists = data

    return (
        <LoadingSkeleton isLoading={loading && !error}>
            <div className="app-shopping-lists">
                {userIsLoggedIn && shoppingLists?.length > 0 ? (
                    shoppingLists.slice(0, maxNumberOfLists).map(shoppingList => (
                        <ShoppingListLink
                            key={shoppingList.id}
                            shoppingList={shoppingList}
                            from={from}
                        />
                    ))
                ) : (
                    <div className="app-shopping-lists__intro">
                        <p>For en enda enklere handel; lagre handlene dine, egne oppskrifter eller favorittprodukter som handlelister.</p>
                        {isCreateButtonVisible ? (
                            <div className="app-shopping-lists__footer">
                                <Button
                                    onClick={handleCreateList}
                                    text="Lag ny handleliste"
                                    isCancel
                                    className="app-shopping-lists__create-button"
                                />
                                <Icon
                                    className="app-shopping-lists__illustration"
                                    size={{ width: "48px", height: "43px" }}
                                    icon={cinnamonBunsIcon}
                                />
                            </div>
                        ) : null}
                    </div>
                )
                }
            </div>
        </LoadingSkeleton>
    )
}

export default ShoppingLists
