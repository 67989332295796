import "./style/trumf-link.scss"

import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"
import { formatHelper } from "@ng-mw/shared-react-components"

import trumf from "@ng-mw/reol/icons/trumf"
import chevronRightIcon from "@ng-mw/reol/icons/chevronRight"
import PathNames from "@app/const/PathNames"
import Icon from "@ng-mw/reol/components/Icon"
import AppLink from "@modules/shared/AppLink"

interface TrumfLinkProp {
    from?: string
}

const TrumfLink = ({
    from,
}: TrumfLinkProp) => {
    const { data } = useSelector((state: RootState) => state.bonus)

    return (
        <AppLink
            to={PathNames.UseBonus}
            from={from}
        >
            <div className="trumf-link">
                <div>
                    <Icon
                        icon={trumf}
                        size={{ width: "60px", height: "24px" }}
                    />
                </div>
                <div className="trumf-link__text">
                    <div className="trumf-linkt__trumf-text">
                        <span>Bruk Trumf-bonusen din</span>
                    </div>
                    <div className="trumf-link__trumf-balance">
                        <p>
                            <strong>Din saldo er {data ? formatHelper.formatPrice(data.trumfBalance) : "0"} kr</strong>
                        </p>
                    </div>
                </div>
                <div>
                    <Icon
                        icon={chevronRightIcon}
                    />
                </div>
            </div>
        </AppLink>
    )
}

export default TrumfLink
