import { DebugLogger, Connectivity, LocalStorage, NativeEventBridge } from "@ng-mw/shared-react-components"
import { cartSetup } from "@ng-mw/shared-react-components/cart"
import { core } from "@ng-mw/shared-react-components/framework"
import { pushNotificationsSetup } from "@ng-mw/shared-react-components/push-notifications"
import { userSetup } from "@ng-mw/shared-react-components/user"
import MenyLocalStorage from "@app/MenyLocalStorage"
import { setNetworkStatus, setSafeArea } from "@app/store/app/appActions"
import { initAnonymousUser, setToken } from "@modules/user/store/actions/user"
import AppGlobalSettings from "@app/AppGlobalSettings"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import MenyWebAppEvent from "@app/events/MenyWebAppEvent"
import { actions as appSettingsActions } from "@app/store/settings/settingsActions"
import extendedUserConnector from "./extended-user"
import { fetchTokenFromNative, fetchTokenFromNativeError } from "@app/helpers"
import { qrError, qrSuccess } from "@modules/user/store/actions/creators/qrCreator"
import { nativeTrackingData, webAppConfigData } from "@app/tracking"
import { SafeArea } from "@app/types"

interface QrCodeResponse {
    code: string | null
}

const query = new URLSearchParams(window.location.search.substring(1))
const envParam = parseInt(query.get("env") || "")
const env = envParam >= 0 && envParam <= 3 ? envParam : core.Environment.DEVELOPMENT
const legacyToken = LocalStorage.get(MenyLocalStorage.UserToken)

//DEBUG: Set NativeEventBridge in browser
/*
window.NativeEventBridge = NativeEventBridge
window.NativeEvent = NativeEvent
//*/

// oad-order-debug: (startup connectors starting) app.bundle.10.5.2-beta.0.js:2:1741000
// 11:33:48.183 startup-connectors: 3ms app.bundle.10.5.2-beta.0.js:2:1741387
// 11:33:48.183 startup-connectors: 3ms app.bundle.10.5.2-beta.0.js:2:1741478
// 11:33:48.186 startup-connectors: 6ms app.bundle.10.5.2-beta.0.js:2:1741549
// 11:33:48.472 startup-connectors: 291ms app.bundle.10.5.2-beta.0.js:2:1741797
// 11:33:48.509 startup-connectors: 329ms - timer ended app.bundle.10.5.2-beta.0.js:2:1741885
// 11:33:48.510 Load-order-debug: (startup connectors done) app.bundle.10.5.2-beta.0.js:2:1741923

export default async (store: any): Promise<void> => {
    DebugLogger.log("Load-order-debug: (startup connectors starting)")
    console.timeLog("Load-order-debug-TIMER")
    console.time("startup-connectors")

    // lets go easy on the logging fwrk
    core.LoggerConfig.turnOffAllFrameworkLoggers()

    // network status watcher
    store.dispatch(setNetworkStatus(Connectivity.status))

    Connectivity.watch((status: boolean) => {
        store.dispatch(setNetworkStatus(status))
    })

    // listen for safe areas
    // and trigger ready to recieve them

    // store.dispatch(setSafeArea({top: 44, bottom: 34})) // uncomment to test iPhone notch things

    NativeEventBridge.listen(MenyNativeEvent.AppSafeArea, (safeArea: SafeArea) => {
        store.dispatch(setSafeArea(safeArea))
    })
    NativeEventBridge.broadcastNative(MenyNativeEvent.ReadyForSafeArea)

    NativeEventBridge.listen(MenyNativeEvent.ForwardTrackingData, (data: any) => {
        nativeTrackingData(data)
    })
    NativeEventBridge.broadcastNative(MenyNativeEvent.ReadyForTrackingData)

    NativeEventBridge.listen(MenyNativeEvent.ListenForTrumfQrCode, (data?: QrCodeResponse) => {
        if (data?.code && data?.code !== "null") {
            store.dispatch(qrSuccess({ code: data.code }))
        } else {
            store.dispatch(qrError(data ? "empty-code" : "no-data"))
        }
    }, false) // false is very important; it makes it listen more than once

    console.timeLog("startup-connectors")

    // set framework setup up (sync)
    core.setup({
        chainId: process.env.CHAIN_ID,
        environment: env,
    })

    store.dispatch(appSettingsActions.loading())

    pushNotificationsSetup(store)

    //*/
    console.timeLog("startup-connectors")
    userSetup(store)

    // Moved the 3 common awaits into the Promise.all so that
    // they are sure to run as parallel as possible.

    // cartSetup:
    // lots of setu of cart possibly firing off cart sync calls (hard to tell if these will be "await'ed" for)

    // extendedUserConnector:
    // only listeners (probably doesn't even need await)

    // fontLoader:
    // If I understand this correctly it simply waits for all three web fonts to be loaded.
    // It doesn't seem to make much difference anyway, but I can imagine if it actually does anything; it might
    // simply delay the disappearance of the spash screen until fonts are loaded if they happen to load very slowly
    // which is not really desirable anyway.. We rather show wrong fonts than get stuck on a splash screen forever.
    // So this is commented out for now (if it turns out it was useful we at least know where it used to be).

    DebugLogger.log("Load-order-debug: (startup connectors mostly done, except login)")

    DebugLogger.log("Load-order-debug: (setToken starting)")
    console.timeLog("Load-order-debug-set-token-TIMER")

    console.time("startup-connectors fetchTokenFromNative")
    const token = AppGlobalSettings.enableTrumfId
        ? await Promise.race([
            fetchTokenFromNative(),
            fetchTokenFromNativeError(),
        ])
        : legacyToken
    console.timeEnd("startup-connectors fetchTokenFromNative")

    await Promise.all([
        cartSetup(store),
        extendedUserConnector(store),
        // fontLoader(),
        token
            ? store.dispatch(setToken(token, true))
            : store.dispatch(initAnonymousUser()),
    ])

    NativeEventBridge.broadcastWeb(MenyWebAppEvent.ReactCanMount)

    const webAppData = {
        "Webapp version": process.env.APP_VERSION,
        "@ng-mw/shared-react-components": process.env.SHARED_COMPONENTS_VERSION,
        "@ng-mw/shared-meny-components": process.env.MENY_COMPONENTS_VERSION,
        "@ng-mw/reol": process.env.REOL_VERSION,
        "env from startup-connectors": env,
        "env from GlobalSettings": AppGlobalSettings.environment,
    }

    webAppConfigData(webAppData)

    DebugLogger.log("Load-order-debug: (setToken done)")
    console.timeLog("Load-order-debug-set-token-TIMER")
}
