export const StaleWhileRevalidateApiCache = "stale-while-revalidate-api-cache"
export const CacheFirstApiCache = "cache-first-api-cache"
export const NetworkFirstApiCache = "network-first-api-cache"
export const ImageCache = "image-cache"
export const ScriptCache = "script-cache"
export const StyleCache = "style-cache"
export const SvgCache = "svg-cache"
export const FrontsCache = "fonts-cache"
export const HtmlCache = "html-cache"
export const FallbackHtmlUrl = "/index.html"

export const StaleWhileRevalidateApiUrls = {
    WeeklyRecipesApi: "api/weeklyrecipeservice/latest",
    MobileAppSettingApi: "api/mobileapp/settings",
    SavingsTipsApi: "api/appcontent/sparetips",
    AppContentOppdagfeedApi: "api/appcontent/oppdagfeed",
    AppContentCorporatefeedApi: "api/appcontent/corporatefeed",
    AboutLoyaltyClubApi: "/api/appcontent/ommenymer",
}

export const CacheFirstApiUrls = {
    // WeeklyRecipesApi: "api/weeklyrecipeservice/latest",
}

export const NetworkFirstApiUrls = {
    ExtendedUserApi: "api/extended-user/1300",
    TrumfSaldoApi: "trumf/husstand/saldo",
    DietInsightApi: "sylinder/kostholdsinnsikt-innsikt/v1",
    ClimateInsightApi: "sylinder/kostholdsinnsikt-innsikt/v1",
}

