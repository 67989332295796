import { core } from "@ng-mw/shared-react-components/framework"
import { Store } from "redux"
import { RootState } from "@app/types"
import { extendedUserHasChanged, ChangeTrigger } from "@modules/user/store/actions/user"

interface ExtendedUserHasChangedMessage {
    data: {
        prefferedStoreDidChange: boolean
    }
}

export default (store: Store<RootState, any>) => {
    core.MessageBroker.registerListener(
        core.PredefinedMessageTopics.EXTENDED_USER_HAS_CHANGED_LOCALLY,
        (message: ExtendedUserHasChangedMessage) => store.dispatch(extendedUserHasChanged(ChangeTrigger.Local, message.data)),
    )
    core.MessageBroker.registerListener(
        core.PredefinedMessageTopics.EXTENDED_USER_HAS_CHANGED,
        (message: ExtendedUserHasChangedMessage) => store.dispatch(extendedUserHasChanged(ChangeTrigger.Sync, message.data)),
    )
}
