import "../style/game-page.scss"

import React from "react"
import { useSelector } from "react-redux"

import { RootState } from "@app/types"
import { Kronelotteri } from "../loaders"
import { PopupContent, PopupTransitions } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Julelotteri from "../Julelotteri"

const GamePage = () => {
    const { visible, data } = useSelector((state: RootState) => state.app.gamePopup)

    if (!data) return null

    const { isKronelotteri, isJulelotteri } = data

    return (
        <PopupTransitions
            isOpen={visible}
            popupAnimation="slide-left"
        >
            <LazyRender
                isVisible={visible}
                className="game-page"
                hiddenClassName=""
            >
                <div className="game-page">
                    <PopupContent>
                        {isKronelotteri ?
                            <Kronelotteri /> : null
                        }
                        {isJulelotteri ?
                            <Julelotteri /> : null
                        }
                    </PopupContent>
                </div>
            </LazyRender>
        </PopupTransitions>
    )
}

export default GamePage
