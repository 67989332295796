import { spawn } from "redux-saga/effects"

import recipeSagas from "@ng-mw/shared-meny-components/src/modules/recipes/store/sagas"
import loadAppSettingsSaga from "./settings/settingsSaga"

import favoriteRecipesSaga from "@ng-mw/shared-meny-components/src/modules/recipes/store/sagas/favoriteRecipesSaga"  // For DinnerAssistant

export default function* () {
    yield spawn(recipeSagas)
    yield spawn(loadAppSettingsSaga)
    yield spawn(favoriteRecipesSaga)  // For DinnerAssistant
}
