import * as AboutLoyaltyClubAction from "./creators/aboutLoyaltyClubCreator"
import { performRESTRequest } from "@ng-mw/framework-core"
import { Logger } from "@ng-mw/shared-react-components"
import { getFullLocalUrl } from "@app/helpers/useAppContent"
import { Dispatch } from "redux"

export const getAboutLoyaltyClubInfo = () => {
    return async (dispatch: Dispatch) => {
        dispatch(AboutLoyaltyClubAction.loading())

        try {
            const response = await performRESTRequest({
                url: getFullLocalUrl("/api/appcontent/ommenymer"),
                method: "GET",
            })
            dispatch(AboutLoyaltyClubAction.success(response))
        } catch (error) {
            dispatch(AboutLoyaltyClubAction.error(error))
            Logger.error("actions/AboutLoyaltyClub/getAboutLoyaltyClubInfo()", error)
        } finally {
            dispatch(AboutLoyaltyClubAction.loaded())
        }
    }
}

export const updateAboutLoyaltyClubInfoStore = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(AboutLoyaltyClubAction.fromCache(data))
    }
}
