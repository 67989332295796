import "../styles/insight-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, matchPath } from "react-router-dom"
import {
    hideDietGroupPopup,
    setDietGroupPopupTabIndex,
} from "@app/store/app/appActions"
import { useActiveClimateData, useActiveDietData, useActiveInsightData } from "@ng-mw/shared-react-components/insight"
import PathNames from "@app/const/PathNames"
import { RootState } from "@app/types"

// Components
import { InsightGroupInfo } from "@ng-mw/shared-react-components/insight-lazy"
import Popup, { PopupContent } from "@modules/popup/Popup"
import ChatBubble from "@ng-mw/shared-meny-components/src/modules/insight/ChatBubble"

const InsightGroupPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible: isOpen, tabIndex } = useSelector((state: RootState) => state.app.dietGroupPopup)
    const { allDietGroups } = useActiveInsightData()
    const { climateActiveDietGroupId, climateNavigationHeader } = useActiveClimateData()
    const { dietActiveDietGroupId, dietNavigationHeader } = useActiveDietData()
    const location = useLocation()
    const isClimate = matchPath(location.pathname, {
        path: PathNames.ClimateHome,
        exact: false,
    })

    return (
        <Popup
            onClose={() => dispatch(hideDietGroupPopup())}
            isOpen={isOpen}
            wrapperClassName="insight-popup"
        >
            <PopupContent>
                <InsightGroupInfo
                    activeDietGroup={isClimate ? allDietGroups[climateActiveDietGroupId] : allDietGroups[dietActiveDietGroupId]}
                    navigationHeader={isClimate ? climateNavigationHeader : dietNavigationHeader}
                    tabIndex={tabIndex}
                    onChange={(nextTabIndex: number) => dispatch(setDietGroupPopupTabIndex(nextTabIndex))}
                    CardComponent={ChatBubble}
                />
            </PopupContent>
        </Popup>
    )
}

export default InsightGroupPopup
