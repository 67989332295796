import React from "react"
import PushConsentTypes from "../../modules/user/const/PushConsentTypes"

// Components
import { PushNotifications } from "@ng-mw/shared-react-components/push-notifications"
import Container, { Background, Content, Padding } from "@modules/shared/Container"

const PushNotificationWrapper = () => {
    return (
        <Container
            background={Background.Gray}
            padding={Padding.Reduced}
            content={Content.Padding}
        >
            <PushNotifications
                title="App-varsler"
                activatorTitle="Få varsler fra MENY"
                activatorDescription="Skru på push-varsler fra MENY i dine telefoninnstillinger for å få tips om kuponger og andre fordeler."
                consentTypes={PushConsentTypes}
            />
        </Container>
    )
}

export default PushNotificationWrapper
