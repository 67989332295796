import "./style/become-loyalty-club-member-poster.scss"

import React, { CSSProperties } from "react"

// Components
import { LoginButton } from "@ng-mw/shared-react-components/shared"
import Container, { Background } from "@modules/shared/Container"

interface BecomeLoyaltyClubMemberPosterProps {
    text?: string
    title?: string
    src?: string
    style?: CSSProperties
}

const BecomeLoyaltyClubMemberPoster = ({
    title = "MER TRUMF-BONUS MED MENY MER",
    text = "Logg inn med Trumf, eller bli MENY MER-kunde, og få mer Trumf-bonus på utvalgte produkter, personlige kuponger, og 10% Trumf-bonus på din første handel.",
    src = "https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_600/klq0ijs8juvvjjzecgxa",
    style,
}: BecomeLoyaltyClubMemberPosterProps) => {
    return (
        <Container
            background={Background.Gray}
            style={style}
        >
            <div className="become-loyalty-club-member-poster">
                <img
                    loading="lazy"
                    className="become-loyalty-club-member-poster__image"
                    src={src}
                    aria-hidden="true"
                    alt=""
                />
                <div className="become-loyalty-club-member-poster__content">
                    {title ? (
                        <div className="become-loyalty-club-member-poster__title">
                            {title}
                        </div>
                    ) : null}
                    {text ? (
                        <div className="become-loyalty-club-member-poster__message">
                            {text}
                        </div>
                    ) : null}
                    <LoginButton />
                </div>
            </div>
        </Container>
    )
}

export default BecomeLoyaltyClubMemberPoster
