import "./style/app-header.scss"

import React, { ReactNode } from "react"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { showCategoryFilterPopup, showQrPopup, showInsightAboutPopup, showDietGroupPopup, showLoyaltyClubInfoPopup } from "@app/store/app/appActions"
import PathNames from "@app/const/PathNames"
import * as tracking from "@app/tracking"
import { searchActions } from "@ng-mw/shared-react-components/search"
import { userActions } from "@ng-mw/shared-react-components/user"
import { RootState } from "@app/types"
import { compareVersions } from "@app/helpers"
import { NativeEventBridge } from "@ng-mw/shared-react-components"
import MenyNativeEvent from "@app/events/MenyNativeEvent"

// Icons
import chevronLeftIcon from "@ng-mw/reol/icons/chevronLeft"
import closeIcon from "@ng-mw/reol/icons/close"
import userIcon from "@ng-mw/reol/icons/user"
import searchIcon from "@ng-mw/reol/icons/search"
import filteringIcon from "@ng-mw/reol/icons/filtering"
import qrCodeIcon from "@resources/icons/qrCode"

// Components
import Icon from "@ng-mw/reol/components/Icon"
import IconButton from "@modules/shared/IconButton"
import InfoButton from "@modules/insight/common/InfoButton"

export enum AppTitleSizeType {
    //Groups
    SMALL = "small",
    LARGE = "large",
}
export interface AppHeaderProps {
    backTitle?: string
    children?: ReactNode | React.JSX.Element
    className?: string
    fallbackRoute?: any
    goBack?: any
    isBackButtonHidden?: boolean
    isDietGroupInfoButtonVisible?: boolean
    isFilterVisible?: boolean
    isInsightInfoButtonVisible?: boolean
    isLoyaltyClubInfoButtonVisible?: boolean
    isProfileHidden?: boolean
    isSearching?: boolean
    isSearchVisible?: boolean
    isTrumfCardVisible?: boolean
    onClose?: any
    productViewId?: string
    scrollChildren?: ReactNode | React.JSX.Element
    title?: string
    titleSize?: AppTitleSizeType
}

const AppHeader = ({
    backTitle = "Tilbake",
    children,
    className,
    fallbackRoute,
    goBack,
    isBackButtonHidden = false,
    isDietGroupInfoButtonVisible = false,
    isFilterVisible = false,
    isInsightInfoButtonVisible = false,
    isLoyaltyClubInfoButtonVisible = false,
    isProfileHidden = false,
    isSearching = false,
    isSearchVisible = false,
    isTrumfCardVisible = false,
    onClose,
    productViewId,
    scrollChildren,
    title,
    titleSize = AppTitleSizeType.SMALL,
}: AppHeaderProps) => {
    const dispatch = useDispatch<any>()
    const history = useHistory()
    const scrollPosition = useSelector((state: RootState) => state.app.scrollPosition)
    const userLoginLevel = useSelector((state: RootState) => state.user.loginLevel)
    const userIsCorporate = useSelector((state: RootState) => state.user.data.household.isOrganization)
    const appConfig = useSelector((state: RootState) => state.appEnvConfig)

    const handleGoBack = (e: React.MouseEvent) => {
        e.preventDefault()

        if (goBack) {
            goBack()
        } else {
            try {
                history.goBack()
            } catch (error) {
                history.push(fallbackRoute)
            }
        }
    }

    const headerClass = classNames(
        "app-header",
        className,
        {
            "app-header--is-top": scrollPosition.top,
            "app-header--is-going-down": scrollPosition.goingDown,
            "app-header--is-searching": isSearching,
        },
    )

    const handlefilterButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(showCategoryFilterPopup(productViewId))
    }

    const handleGoToProfilePage = (e: React.MouseEvent) => {
        e.stopPropagation()
        history.push(PathNames.UserProfile)
    }

    const handleSearchClick = () => {
        tracking.searchIconClick()
        dispatch(searchActions.showSuggestions())
    }

    const handleTrumfCardClick = () => {
        const requiredVersion = appConfig?.os === "ios" ? "27" : "38"

        const isNativeTrumfCardActive = appConfig?.version && compareVersions(appConfig?.version, requiredVersion) >= 0
        if (isNativeTrumfCardActive) {
            return NativeEventBridge.broadcastNative(MenyNativeEvent.OpenTrumfCard)
        } else {
            dispatch(showQrPopup())
        }
    }

    const handleInsightInfoButtonClick = () => {
        dispatch(showInsightAboutPopup())
    }

    const handleDietGroupInfoButtonClick = () => {
        dispatch(showDietGroupPopup())
    }

    const handleLoyaltyClubInfoButtonClick = () => {
        dispatch(showLoyaltyClubInfoPopup())
    }
    const handleShowTrumfIdLoginDrawer = () => {
        dispatch(userActions.showTrumfIdLoginDrawer())
    }

    return (
        <header
            className={headerClass}
            role="banner"
        >
            <div className="app-header__grid">
                <div className="app-header__grid-left">
                    {!isBackButtonHidden ? (
                        <button
                            className="app-header__back-button"
                            type="button"
                            onClick={handleGoBack}
                            aria-label={backTitle}
                        >
                            <Icon
                                icon={chevronLeftIcon}
                                size={{ width: "28px", height: "28px" }}
                            />
                        </button>
                    ) : null}
                </div>
                <div className="app-header__grid-center">
                    <h1
                        className={classNames(
                            "app-header__title",
                            `app-header__title--size-${titleSize}`,
                            {
                                "app-header__title--center": !isBackButtonHidden,
                                "app-header__title--is-going-down": scrollPosition.goingDown,
                                "app-header__title--is-top": scrollPosition.top || isBackButtonHidden,
                                "app-header__title--is-primary": isBackButtonHidden,
                            },
                        )}
                        aria-live="polite"
                    >
                        {title}
                    </h1>
                    {scrollChildren}
                </div>
                <div className="app-header__grid-right">
                    {isSearchVisible ? (
                        <div
                            className={classNames(
                                "app-header__search-button",
                                {
                                    "app-header__search-button--going-down": scrollPosition.goingDown,
                                    "app-header__search-button--top": scrollPosition.top,
                                },
                            )}
                        >
                            <IconButton
                                onClick={handleSearchClick}
                                icon={searchIcon}
                                title="Søk"
                                ariaLabel="Søk"
                                gtmData={{ name: "header søk", value: "søk-icon-click" }}
                                isCircle
                                isSecondary
                            />
                        </div>
                    ) : null}
                    {isInsightInfoButtonVisible ? (
                        <InfoButton
                            onClick={handleInsightInfoButtonClick}
                        />
                    ) : null}
                    {isDietGroupInfoButtonVisible ? (
                        <InfoButton
                            onClick={handleDietGroupInfoButtonClick}
                        />
                    ) : null}
                    {isLoyaltyClubInfoButtonVisible ? (
                        <InfoButton
                            onClick={handleLoyaltyClubInfoButtonClick}
                        />
                    ) : null}
                    {isFilterVisible ? (
                        <IconButton
                            onClick={handlefilterButtonClick}
                            icon={filteringIcon}
                            title="filter"
                            ariaLabel="filter"
                            gtmData={{ name: "filter", value: "filter-click" }}
                            isCircle
                            isSecondary
                        />
                    ) : null}
                    {!isProfileHidden ? (
                        <IconButton
                            onClick={userLoginLevel > 0 ? handleGoToProfilePage : handleShowTrumfIdLoginDrawer}
                            icon={userIcon}
                            title="Gå til profil"
                            ariaLabel="Gå til profil"
                            gtmData={{ name: "header profil", value: "profil-icon-click" }}
                            isCircle
                        />
                    ) : null}
                    {isTrumfCardVisible && userLoginLevel > 0 && !userIsCorporate ? (
                        <IconButton
                            onClick={handleTrumfCardClick}
                            icon={qrCodeIcon}
                            title="Trumf-kort"
                            ariaLabel="Trumf-kort"
                            gtmData={{ name: "Trumf-kort", value: "Trumf-kort-click" }}
                            isCircle
                            isSecondary
                        />
                    ) : null}
                    {onClose ? (
                        <button
                            type="button"
                            onClick={onClose}
                        >
                            <Icon
                                icon={closeIcon}
                                size={{ width: "28px", height: "16px" }}
                            />
                            <span className="ws-visually-hidden">Lukk</span>
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="app-header__content">
                {children}
            </div>
        </header>
    )
}

export const Padding = {
    None: "none",
    Normal: "normal",
} as const

export type PaddingEnum = typeof Padding[keyof typeof Padding];
export interface SubHeaderProps {
    title?: string
    children?: ReactNode
    padding?: PaddingEnum
    className?: string
}

export const SubHeader = ({
    children,
    title,
    padding = Padding.Normal,
    className,
}: SubHeaderProps) => {

    const subHeaderClassName = classNames(
        "app-header__sub",
        className,
        {
            "app-header__sub--padding-none": padding === Padding.None,
        })

    return (
        <div className={subHeaderClassName}>
            <h1
                className="app-header__sub-title"
                aria-live="polite"
            >
                {title}
            </h1>
            {children}
        </div>
    )
}

export default AppHeader
