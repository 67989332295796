import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideRecipeRatingPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import { RecipeRatingButtons } from "@ng-mw/shared-meny-components/src/modules/recipes/loadables"
import { recipeRatingActions, trackingRecipeRating } from "@ng-mw/shared-meny-components/recipes"

// Components
import Popup, { PopupButtons, PopupContent } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Button from "@ng-mw/reol/components/Button"

const RecipeRatingPopup = () => {
    const dispatch = useDispatch<any>()
    const [rating, setRating] = useState<number>()
    const [isRatingSubmitted, setIsRatingSubmitted] = useState(false)
    const { visible, recipeItem } = useSelector((state: RootState) => state.app.recipeRatingPopup)

    const handleClose = () => {
        setIsRatingSubmitted(false)
        dispatch(hideRecipeRatingPopup())
    }

    const handleRatingClick = (rating: number) => {
        setRating(rating)
    }

    const handleSendRating = () => {
        trackingRecipeRating(rating, recipeItem?.rawData.name)
        dispatch(recipeRatingActions.sendRecipeRating(rating, recipeItem?.rawData.id))
        setIsRatingSubmitted(true)

        setTimeout(() => {
            handleClose()
        }, 2000)
    }

    return (
        <Popup
            isOpen={visible}
            onClose={handleClose}
            title={isRatingSubmitted ? "Takk for din vurdering!" : "Hvor mange stjerner vil du gi denne oppskriften?"}
        >
            <PopupContent>
                <LazyRender
                    isVisible={visible}
                    hiddenClassName=""
                >
                    {recipeItem?.rawData?.id ?
                        <RecipeRatingButtons
                            recipeItem={recipeItem}
                            isNative
                            onRatingClick={handleRatingClick}
                        />
                        :
                        <p>Det er skjedd en feil</p>
                    }
                </LazyRender>
            </PopupContent>
            <PopupButtons isSimple>
                <Button
                    onClick={handleSendRating}
                    text="Bekreft"
                    isDisabled={isRatingSubmitted}
                />
                <Button
                    onClick={handleClose}
                    text="Avbryt"
                    isSecondary
                />
            </PopupButtons>
        </Popup>
    )
}

export default RecipeRatingPopup
