import { DebugLogger } from "@ng-mw/shared-react-components"
import { couponsCreator } from "@ng-mw/shared-react-components/coupons"
import { bonusCreator } from "@ng-mw/shared-react-components/use-bonus"
import { AppRatingAction } from "./appRatingCreator"
import { favoriteRecipesActionCreators } from "@ng-mw/shared-meny-components/recipes"

const init = {
    hasActivatedCoupons: false,
    numRecipesSavedToWeekMenu: 0,
    bonusUpdating: false,
    hasActivatedBonus: false,
    isOrderIsConfirmed: false,

    ratingPopup: {
        visible: false,
        force: false,
    },
}

export default (state = init, action) => {

    switch (action.type) {
        case couponsCreator.CouponsAction.UpdateCoupon: {
            const nextState = {
                ...state,
                hasActivatedCoupons: state.hasActivatedCoupons || action.payload?.props?.status === "AKTIV",
            }

            return nextState
        }
        case AppRatingAction.UserNavigated: {

            if (state.hasActivatedCoupons) {
                DebugLogger.log("RATING DEBUG: trigger because navigated after activating coupon")
            }
            if (state.isOrderIsConfirmed) {
                DebugLogger.log("RATING DEBUG: trigger because navigated after order confirmation")
            }
            const nextState = {
                ...state,
                hasActivatedCoupons: false, // reset
                isOrderIsConfirmed: false, // reset

                ratingPopup: {
                    ...state.ratingPopup,
                    visible: state.hasActivatedCoupons || state.isOrderIsConfirmed,
                },
            }

            return nextState
        }
        case favoriteRecipesActionCreators.FavoriteRecipesActionTypes.CreateFavoriteRecipeSuccess: {
            const nextState = {
                ...state,
                numRecipesSavedToWeekMenu: state.numRecipesSavedToWeekMenu + 1,
                ratingPopup: {
                    ...state.ratingPopup,
                    visible: state.numRecipesSavedToWeekMenu === 1,
                },
            }

            if (nextState.numRecipesSavedToWeekMenu === 2) {
                DebugLogger.log("RATING DEBUG: trigger because 2 recipes saved")
                // NativeEventBridge.broadcastNative(MenyNativeEvent.RequestAppRating)
            }

            return nextState
        }
        case bonusCreator.BonusAction.BonusUpdating: {
            const nextState = {
                ...state,
                bonusUpdating: true,
            }
            return nextState
        }
        case bonusCreator.BonusAction.BonusSuccess: {
            const hasActivatedBonus = state.bonusUpdating && action.payload?.status === "AKTIV"
            const nextState = {
                ...state,
                hasActivatedBonus,
                bonusUpdating: false,
                ratingPopup: {
                    ...state.ratingPopup,
                    visible: hasActivatedBonus,
                },
            }

            if (nextState.hasActivatedBonus) {
                DebugLogger.log("RATING DEBUG: trigger because bonus success", bonusCreator.BonusAction.BonusSuccess, action.payload)
                // NativeEventBridge.broadcastNative(MenyNativeEvent.RequestAppRating)
            }

            return nextState
        }
        case AppRatingAction.OrderIsConfirmed: {
            const nextState = {
                ...state,
                isOrderIsConfirmed: true,
            }
            return nextState
        }
        case AppRatingAction.ShowRatingPopup: {
            const nextState = {
                ...state,
                ratingPopup: {
                    ...state.ratingPopup,
                    visible: true,
                    force: true,
                },
            }
            return nextState
        }

        case AppRatingAction.HideRatingPopup: {
            const nextState = {
                ...state,
                ratingPopup: {
                    ...state.ratingPopup,
                    visible: false,
                    force: false,
                },
            }
            return nextState
        }
        default:
            return state
    }
}
