import "./style/recipe-item.scss"
import "./style/recipe-cta.scss"

import React from "react"
import { LifecycleEmitter } from "@ng-mw/shared-react-components"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import { mapPreparationTime } from "./helpers"
import { getViewportScaledImageConf, dprMode } from "@ng-mw/shared-meny-components/src/data/MenyImageConfig"

// Icons
import arrowRight from "@ng-mw/reol/icons/arrowRight"

// Components
import { Image } from "@ng-mw/shared-react-components/shared"
import Button from "@ng-mw/reol/components/Button"
import RecipeTime from "@ng-mw/shared-meny-components/src/modules/recipes/RecipeTime"
import { FavoriteRecipeButton } from "@ng-mw/shared-meny-components/src/modules/recipes/loadables"
import { FavoriteRecipe } from "@app/types"

export interface RecipeItemProps {
    recipe: FavoriteRecipe
}

const RecipeItem = ({
    recipe,
}: RecipeItemProps) => {

    const onRecipeClicked = () => {
        LifecycleEmitter.broadcast(MenyLifecycleEvent.GoToRecipe, {
            recipeId: recipe.id,
            source: "purchased",
        })
    }

    if (!recipe) return null

    return (
        <div className="recipe-item">
            <div className="recipe-item__image">
                <FavoriteRecipeButton
                    favorite
                    purchased={false}
                    recipeId={recipe.id}
                    loadFavoritesOnCreate
                />
                <button
                    onClick={() => onRecipeClicked()}
                    aria-label="Se oppskrift"
                    title="Se oppskrift"
                >
                    <Image
                        filename={recipe.media.imageId}
                        config={getViewportScaledImageConf({
                            maxWidth: 800,
                            aspect: 2 / 1,
                            ratio: 1.6,
                            dprMode: dprMode.NONE,
                        })}
                        objectFit="cover"
                    />
                </button>
            </div>
            <div className="recipe-item__content">
                <h3 className="recipe-item__title">{recipe.name}</h3>
                <div className="recipe-item__footer">
                    <RecipeTime time={mapPreparationTime(recipe.timeEstimate)} />
                    <Button
                        isInlineLink
                        onClick={() => onRecipeClicked()}
                        iconRight={arrowRight}
                        iconLeftSize={{ width: "15px", height: "15px" }}
                        text="Se oppskriften"
                    />
                </div>
            </div>
        </div>
    )
}

export default RecipeItem
