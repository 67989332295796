import { Dispatch } from "redux"
import { SafeArea } from "@app/types"
import * as nativeActions from "./nativeCreator"

export const setSafeArea = (safeArea: SafeArea) => {
    return (dispatch: Dispatch) => {
        dispatch(nativeActions.setSafeArea(safeArea))
    }
}

export const setNetworkStatus = (online: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(nativeActions.setNetworkStatus(online))
    }
}
