import { App, DebugLogger } from "@ng-mw/shared-react-components"
import { Dispatch } from "redux"
import * as appAction from "@app/store/app/appActions"
import * as appEnvConfigAction from "./appEnvConfigCreator"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { } from "@modules/onboarding/FirstTrumfId"
import { compareVersions } from "@app/helpers"

export const setAppConfigData = (config: { version: any; osVersion?: any }) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(appEnvConfigAction.setAppConfigData(config))

        const wrapperVersion = `${config.version}`
        const wrapperOSVersion = `${config.osVersion}`

        const isLegacy = wrapperVersion === "legacy"

        const requiredOSVersion = App.Context.Android
            ? AppGlobalSettings.requiredAndroidVersion
            : AppGlobalSettings.requiredIOSVersion

        const requiredVersion = App.Context.Android
            ? AppGlobalSettings.requiredAndroidAppVersion
            : AppGlobalSettings.requiredIOSAppVersion

        const recommendedVersion = App.Context.Android
            ? AppGlobalSettings.recommendedAndroidAppVersion
            : AppGlobalSettings.recommendedIOSAppVersion

        if (isLegacy) {
            DebugLogger.log("UpgradeNotice: SHOW UPGRADE POPUP, because it's a legacy event")
            dispatch(appAction.showForcedUpgradePopup())
        } else if (wrapperOSVersion && requiredOSVersion && compareVersions(wrapperOSVersion, requiredOSVersion) < 0) {
            DebugLogger.log(`UpgradeNotice: SHOW forced OS upgrade screen, because wrapper OS version ${wrapperOSVersion} is lower than ${requiredOSVersion}`)
            dispatch(appAction.showOsUpgradePopup())

        } else if (requiredVersion && compareVersions(wrapperVersion, requiredVersion) < 0) {
            DebugLogger.log(`UpgradeNotice: SHOW forced app upgrade screen, because wrapper version ${wrapperVersion} is lower than ${requiredVersion}`)
            dispatch(appAction.showForcedUpgradePopup())

        } else if (recommendedVersion && compareVersions(wrapperVersion, recommendedVersion) < 0) {
            DebugLogger.log(`UpgradeNotice: SHOW optional upgrade popup, because wrapper version ${wrapperVersion} is lower than ${recommendedVersion}`)
            dispatch(appAction.showAppUpgradePopup())
        }
    }
}
