import "../style/welcome-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideWelcomePopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import { useLoyaltyClubStatus } from "../helpers"

// Components
import Popup, { PopupText, PopupTitle } from "@modules/popup/Popup"
import LazyRender from "@ng-mw/reol/components/LazyRender"
import Button from "@ng-mw/reol/components/Button"
import PopupContent from "@modules/popup/PopupContent"
import { LoginButton } from "@ng-mw/shared-react-components/shared"
import Spinner from "@ng-mw/reol/components/Spinner"

const WelcomePopup = () => {
    const dispatch = useDispatch<any>()
    const { visible, data } = useSelector((state: RootState) => state.app.welcomePopup)
    const { loading } = useSelector((state: RootState) => state.user)
    const { isLoyaltyClubMember, isConsentsLoading } = useLoyaltyClubStatus()

    const handleClose = () => {
        dispatch(hideWelcomePopup())
    }

    return (
        <Popup
            isOpen={visible}
            isSimple
            isForcedFullscreen
            popupAnimation="slide-left"
            buttons={
                isLoyaltyClubMember && !data?.email ?
                    <Button
                        onClick={handleClose}
                        text="Kom i gang"
                    />
                    :
                    <LoginButton
                        caption="Jeg har opprettet passord"
                        className="welcome-popup__login-button"
                        onSuccess={handleClose}
                    />
            }
        >
            <LazyRender
                isVisible={visible}
                className="welcome-popup"
            >
                <img
                    loading="lazy"
                    className="welcome-popup__image"
                    src="https://res.cloudinary.com/norgesgruppen/image/upload/c_fill,dpr_auto,f_auto,q_auto:eco,w_600/iceqher14wqflw061rhp"
                    aria-hidden="true"
                    alt=""
                />
                <PopupContent isSimple>
                    {data?.email && !isLoyaltyClubMember ?
                        <PopupTitle>Åpne lenken i e-posten din</PopupTitle>
                        :
                        <PopupTitle>Velkommen i klubben!</PopupTitle>
                    }
                    {!data?.email && isLoyaltyClubMember ?
                        <PopupText>
                            Nå kan du la deg inspirere av spennende oppskrifter, handle direkte i appen, spare Trumf-bonus og mye MER.
                        </PopupText>
                        :
                        null
                    }
                    {loading || isConsentsLoading ?
                        <div className="welcome-popup__login-content">
                            <Spinner />
                        </div>
                        :
                        data?.email && !isLoyaltyClubMember ?
                            <div className="welcome-popup__login-content">
                                <PopupText>
                                    <p>{data?.email ? <strong>Vi har sendt deg en passordlenke til {data?.email}.</strong> : null}</p>
                                    <p>Følg lenken i e-posten for å lage nytt passord, og så er du klar til å logge inn!</p>
                                </PopupText>
                            </div>
                            :
                            null
                    }
                </PopupContent>
            </LazyRender>
        </Popup>
    )
}

export default WelcomePopup
